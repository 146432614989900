import { takeLatest, all } from 'redux-saga/effects'
import { SlaughterhouseTypes } from './types'
import { workerGetSlaughterhouses, workerSetSelectedSlaughterhouse, workerSaveSlaughterhouse } from './sagas'

export function* watcherGetSlaughterhouses() {
  yield takeLatest(SlaughterhouseTypes.ACTION_GET_SLAUGHTERHOUSES, workerGetSlaughterhouses)
}

export function* watcherSetSelectedSlaughterhouse() {
  yield takeLatest(SlaughterhouseTypes.ACTION_SET_SELECTED_SLAUGHTERHOUSE, workerSetSelectedSlaughterhouse)
}

export function* watcherSaveSlaughterhouse() {
  yield takeLatest(SlaughterhouseTypes.ACTION_SAVE_SLAUGHTERHOUSE, workerSaveSlaughterhouse)
}

export default function* authRootSaga() {
  yield all([watcherGetSlaughterhouses(), watcherSetSelectedSlaughterhouse(), watcherSaveSlaughterhouse()])
}
