import { Props } from './types'
import Loading from 'components/widgets/Loading'
import React, { useRef } from 'react'
import { Form } from '@unform/web'
import Input from 'components/widgets/Input'
import DatePicker from 'components/widgets/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'
import Button from 'components/widgets/Button'
import { SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import _ from 'lodash'
import { Lot } from 'store/feedlot/types'
import { saveLot } from 'store/feedlot/actions'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const LotForm: React.FC<Props> = ({ feedlotId, penId }) => {
  const { t } = useTranslation()
  const formRef: any = useRef(null)
  const dispatch = useDispatch()
  const { selectedLot, lotLoading } = useSelector((state: ApplicationState) => state.feedlot)

  const buttonLabel = selectedLot?.id ? 'save' : 'add'

  const clearError = (e: any) => formRef.current?.setFieldError(e.target?.id, '')

  const handleSubmit: SubmitHandler<Lot> = async data => {
    const lot: any = data
    lot.id = selectedLot?.id
    lot.penId = penId
    lot.feedlotId = feedlotId
    lot.initDate = lot.initDate ? new Date(moment(lot.initDate).format()) : null
    lot.endDate = lot.endDate ? new Date(moment(lot.endDate).format()) : null

    _.map(Object.entries(lot), ([key, value]) => (lot[key] = value === '' ? null : value))

    try {
      if (!penId) return
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        description: Yup.string()
          .nullable()
          .required(() => t('field.required'))
        // start: Yup.string().required(() => t('field.required'))
      })
      await schema.validate(lot, { abortEarly: false })
      dispatch(saveLot({ lot }))
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }
  return (
    <div className='container'>
      {lotLoading ? (
        <Loading />
      ) : (
        <Form ref={formRef} initialData={selectedLot} onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-3'>
              <Input name='description' label={t('field.description')} onFocus={clearError} />
            </div>
          </div>
          <div className='row'>
            <div className='col-3'>
              <Input name='code' label={t('field.code')} onFocus={clearError} />
            </div>
          </div>
          <div className='row'>
            <div className='col-3'>
              <DatePicker name='initDate' label={t('field.start')} onFocus={clearError} />
            </div>
          </div>
          <div className='row'>
            <div className='col-3'>
              <DatePicker name='endDate' label={t('field.end')} onFocus={clearError} />
            </div>
          </div>
          <div className='row'>
            <div className='col-3 mtop-2'>
              <Button label={buttonLabel} />
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}

export default LotForm
