import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ApplicationState } from 'store/types'
import { Column } from 'components/widgets/Table/types'
import { getEquipment, setSelectedEquipment } from 'store/feedlot/actions'
import { Props } from './types'
import { Dispatch } from 'redux'
import _ from 'lodash'
import { generalRender, getTitle } from './helper'
// import EquipmentModal from 'components/modals/EquipmentModal'
import { useTranslation } from 'react-i18next'
import EquipmentModal from 'components/modals/EquipmentModal'
import MaterialTable from 'material-table'

const EquipmentList: React.FC<Props> = ({ feedlotId, penId }) => {
  const dispatch: Dispatch = useDispatch()

  const [isEquipmentModalOpen, setEquipmentModalStatus] = useState<boolean>(false)
  const [isNew, setIsNew] = useState<boolean>(true)

  const { equipmentLoading, equipments } = useSelector((state: ApplicationState) => state.feedlot)

  useEffect(() => {
    dispatch(
      getEquipment({
        args: { penId }
      })
    )
  }, [dispatch, penId])

  const editAction = (selectedEquipment: any) => {
    if (selectedEquipment.id) setIsNew(false)
    dispatch(setSelectedEquipment(selectedEquipment.id))
    setEquipmentModalStatus(true)
  }

  const columnNames = [
    'serialNumber',
    'description',
    'active',
    'equipmentModel.description',
    // 'equipmentModel.model',
    'equipmentModel.equipmentTypeId',
    'equipmentModel.manufacturer.name'
  ]
  const columns: Column[] = []
  if (equipments.length) {
    _.map(columnNames, (column: string) => {
      columns.push({
        title: getTitle(column),
        field: column,
        render: (rowData: Record<string, any>) => generalRender(rowData, column),
        cellStyle: { width: 10 }
      })
    })
  }
  const { t } = useTranslation()
  return (
    <div>
      <EquipmentModal
        isOpen={isEquipmentModalOpen}
        handleClose={() => setEquipmentModalStatus(false)}
        feedlotId={feedlotId}
        penId={penId}
        isNew={isNew}
      />
      <MaterialTable
        isLoading={equipmentLoading}
        columns={columns}
        data={equipments}
        style={{ width: 'auto' }}
        options={{
          header: !!equipments.length,
          toolbar: true,
          // paging: !!equipments.length,
          toolbarButtonAlignment: 'left',
          searchFieldAlignment: 'left',
          showTitle: false,
          emptyRowsWhenPaging: false,
          addRowPosition: 'first',
          headerStyle: { fontSize: 16, fontWeight: 'bold' },
          pageSizeOptions: equipments.length < 5 ? [] : equipments.length < 10 ? [5, 10] : [5, 10, 20]
        }}
        actions={[
          { icon: 'edit', tooltip: t('table.edit'), onClick: (_: any, data: any) => editAction(data) },
          {
            icon: 'add',
            tooltip: t('equipment.new'),
            onClick: (_: any, data: any) => editAction(data),
            isFreeAction: true
          }
        ]}
        components={{
          Container: (props: {
            children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
          }) => <div style={{ width: 'auto' }}>{props.children}</div>
        }}
      />
    </div>
  )
}

export default withRouter(EquipmentList)
