import React, { useState, useEffect } from 'react'
import { Props } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'
import BreadCrumb from 'components/layout/Breadcrumb'
import ExpandableCard from 'components/widgets/ExpandableCard'
import { setSelectedFeedlot, setSelectedPen } from 'store/feedlot/actions'
import PenForm from 'components/forms/PenForm'
import { useParams, withRouter } from 'react-router'
import LotList from 'components/lists/LotList'
import { PenQueryFields } from 'services/pen/types'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import EquipmentList from 'components/lists/EquipmentList'
import { useTranslation } from 'react-i18next'

const PenScreen: React.FC<Props> = ({ match }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { selectedPen, selectedFeedlot } = useSelector((state: ApplicationState) => state.feedlot)

  const [panelExpanded, setPanelExpanded] = useState<boolean | string>(false)
  const [clickedPanel, setClickedPanel] = useState<boolean | string>(false)
  const [isModalOpened, setModalOpened] = useState<boolean>(false)
  const [isEditingGeneral, setEditingGeneral] = useState<boolean>(false)

  const { id } = useParams()
  const urlId = Number(id) || undefined
  const isRegistration = urlId ? false : true
  const feedlotId = Number(match.url.substring('/feedlots/'.length, match.url.indexOf('/pens/')))
  const penId = Number(match.params.id)

  useEffect(() => {
    const id = Number(match.params.id)

    const fields: PenQueryFields = {
      id: true,
      code: true,
      status: true,
      feedlot: { id: true }
      // equipment: {
      //   id: true,
      //   serialNumber: true,
      //   description: true,
      //   active: true
      // }
    }

    if (!id) {
      setPanelExpanded('general')
      setClickedPanel('general')
    }
    if (!selectedFeedlot) dispatch(setSelectedFeedlot(feedlotId))
    if (!selectedPen) dispatch(setSelectedPen({ args: { id: id }, fields }))
  }, [dispatch, match.params.id, selectedFeedlot, selectedPen, feedlotId])

  const handleExpanded = (panel: string) => (e: React.ChangeEvent<Record<string, any>>, isExpanded: boolean) => {
    setPanelExpanded(panel)
    isEditingGeneral ? setModalOpened(true) : isExpanded ? setPanelExpanded(panel) : setPanelExpanded(false)
    isExpanded ? setPanelExpanded(panel) : setPanelExpanded(false)
  }

  // const handleEditing = (active: boolean) => setEditingGeneral(active)
  const handleConfirm = () => {
    let panel: boolean | string = false
    if (clickedPanel !== panelExpanded) panel = clickedPanel
    setModalOpened(false)
    setPanelExpanded(panel)
    setEditingGeneral(false)
  }
  const handleCancel = () => setModalOpened(false)

  return (
    <>
      <BreadCrumb
        items={[
          {
            label: t('feedlot.feedlot', { count: 0 }) + ' / ' + selectedFeedlot?.description,
            path: '/feedlots',
            active: false
          },
          { label: t('pen.pen', { count: 0 }), path: '/feedlots/' + feedlotId, active: false },
          { label: selectedPen?.code || '', path: '/pens' + penId, active: true }
        ]}
      />
      <ExpandableCard
        id='general'
        title={isRegistration ? t('pen.registration') : t('default.general_information')}
        defaultExpanded={isRegistration}
        expanded={panelExpanded === 'general'}
        onChange={handleExpanded('general')}
      >
        <PenForm feedlotId={feedlotId} />
      </ExpandableCard>
      {!isRegistration && urlId && (
        <>
          <ExpandableCard
            id='lots'
            title={t('lot.lot', { count: 0 })}
            expanded={panelExpanded === 'lotList'}
            onChange={handleExpanded('lotList')}
          >
            <LotList feedlotId={feedlotId} penId={urlId} />
          </ExpandableCard>
          <ExpandableCard
            id='equipments'
            title={t('equipment.equipment', { count: 0 })}
            expanded={panelExpanded === 'equipmentList'}
            onChange={handleExpanded('equipmentList')}
          >
            <EquipmentList feedlotId={feedlotId} penId={urlId} />
          </ExpandableCard>
        </>
      )}
      <ConfirmationModal
        open={isModalOpened}
        title={t('confirmation.general_close_confirm_title')}
        text={t('confirmation.general_close_confirm_text')}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    </>
  )
}
export default withRouter(PenScreen)
