import { Credentials } from 'services/auth/types'

const CREDENTIALS = 'techagr@credentials'
export const saveAuthToStorage = (credentials: Record<string, any> | null) => {
  localStorage.setItem(CREDENTIALS, JSON.stringify(credentials))
}

export const getAuthFromStorage = (): Credentials | null => {
  const jsonStr = localStorage.getItem(CREDENTIALS)
  if (jsonStr) {
    return JSON.parse(jsonStr)
  }
  saveAuthToStorage(null)
  return null
}

export const removeAuthFromStorage = () => {
  localStorage.removeItem(CREDENTIALS)
}
