import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Props } from './types'
import { ApplicationState } from 'store/types'
import { Coat } from 'store/animal/types'

import { Option } from 'data/types'
import { getCoat, setSelectedCoat } from 'store/animal/actions'
import { CoatQueryFields } from 'services/animal/types'
import Select from 'components/widgets/Select'
import { useTranslation } from 'react-i18next'

const CoatSelector: React.FC<Props> = ({ selected, cleanError }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { coats, coatLoading } = useSelector((state: ApplicationState) => state.animal)

  const [options, setOptions] = useState<Option[]>([])

  const formatCoats = (coats: Coat[]) =>
    coats.map((e: Coat) => ({ label: e.description + ' - ' + e.code || '', value: e.id || -1 }))

  const setSelected = (id: number) => {
    dispatch(setSelectedCoat(id))
  }

  useEffect(() => {
    if (!coats?.length) {
      const fields: CoatQueryFields = {
        id: true,
        code: true,
        description: true
      }
      dispatch(getCoat({ fields }))
    } else setOptions(formatCoats(coats))
  }, [coats, dispatch])

  return (
    <Select
      name='coat.id'
      label={t('field.coat')}
      loading={coatLoading}
      defaultOptions={options}
      selectedValue={selected}
      handleSelect={(id: number) => setSelected(id)}
      onFocus={cleanError}
    />
  )
}
export default CoatSelector
