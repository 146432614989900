import React from 'react'
import { Props } from './types'

import Modal from 'components/widgets/Modal'
import AnimalForm from 'components/forms/AnimalForm'
import i18next from 'i18next'

const AnimalModal: React.FC<Props> = ({ handleClose, isOpen, feedlotId, lotId, additionalInfoKeys, isNew }) => {
  return (
    <Modal
      handleClose={handleClose}
      open={isOpen}
      title={isNew ? i18next.t('animal.registration') : i18next.t('default.general_information')}
      id={'animalModal'}
    >
      <AnimalForm
        feedlotId={feedlotId || 0}
        lotId={lotId || 0}
        additionalInfoKeys={additionalInfoKeys}
        handleClose={handleClose}
      />
    </Modal>
  )
}

export default AnimalModal
