import gql from 'graphql-tag'
import {
  PriceQueryParams,
  PriceCreateArgs,
  PriceUpdateArgs,
  CurrencyQueryParams,
  WeightUnityQueryParams
} from './types'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import graphql from 'services'
import { Price, Currency, WeightUnity } from 'store/economic/types'
import getFormattedQuery from 'helpers/getFormattedQuery'

export const priceQuery = async (token: string, params: PriceQueryParams): Promise<Price[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      mi_nel_vista: true,
      mc_nel_prazo: true,
      mi_nel_prazo: true,
      mi_cruz_vista: true,
      mc_cruz_vista: true,
      mi_cruz_prazo: true,
      mc_cruz_prazo: true,
      nv_nel_vista: true,
      nv_nel_prazo: true,
      vc_nel_vista: true,
      vc_nel_prazo: true,
      nv_cruz_vista: true,
      nv_cruz_prazo: true,
      mc_nel_vista: true,
      slaughterhouse: {
        id: true,
        description: true,
        status: true,
        latitude: true,
        longitude: true,
        slaughterday: true,
        slaughteryr: true,
        class: true,
        address: {
          cityId: true,
          cityDescription: true,
          cityStateDescription: true,
          stateId: true,
          stateDescription: true,
          countryId: true,
          countryDescription: true
        }
      },
      currency: {
        id: true,
        code: true,
        description: true,
        symbol: true
      },
      weightUnity: {
        id: true,
        code: true,
        symbol: true
      }
    }
  const QUERY = gql`
    query QUERY($id: Int, $slaughterhouseId: Int) {
      price(id: $id, slaughterhouseId: $slaughterhouseId) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/price/priceQuery', errors)
    }
    return data.price
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/price/priceQuery', error)
  }
}

export const priceCreate = async (token: string, params: PriceCreateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($price: CreatePrice!) {
      createPrice(price: $price)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/price/priceCreate', errors)
    }
    return data.createPrice
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/price/priceCreate', error)
  }
}

export const priceUpdate = async (token: string, params: PriceUpdateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($price: UpdatePrice!) {
      updatePrice(price: $price)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/price/priceUpdate', errors)
    }
    return data.updatePrice
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/price/priceUpdate', error)
  }
}

export const currencyQuery = async (token: string, params: CurrencyQueryParams): Promise<Currency[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      code: true,
      symbol: true,
      description: true
    }
  const QUERY = gql`
    query QUERY($id: Int) {
      currency(id: $id) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/currency/currencyQuery', errors)
    }
    return data.currency
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/currency/currencyQuery', error)
  }
}

export const weightUnityQuery = async (token: string, params: WeightUnityQueryParams): Promise<WeightUnity[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      code: true,
      symbol: true
    }
  const QUERY = gql`
    query QUERY($id: Int) {
      weightUnity(id: $id) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/weightUnity/weightUnityQuery', errors)
    }
    return data.weightUnity
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/weightUnity/weightUnityQuery', error)
  }
}
