import gql from 'graphql-tag'
import { FeedlotQueryParams, FeedlotCreateArgs, FeedlotUpdateArgs } from './types'
import { UNEXPECTED_ERROR_CODE } from '../../helpers/EventCodes'
import Exception from '../../helpers/exception'
import graphql from 'services'
import { Feedlot } from '../../store/feedlot/types'
import getFormattedQuery from 'helpers/getFormattedQuery'

export const feedlotQuery = async (token: string, params: FeedlotQueryParams): Promise<Feedlot[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      description: true,
      isThirdPart: true,
      status: true,
      neighborhood: true,
      street: true,
      number: true,
      cityId: true,
      cityDescription: true,
      stateId: true,
      stateDescription: true,
      stateCode: true,
      countryId: true,
      countryDescription: true,
      customer: { id: true, name: true, email: true }
    }
  const QUERY = gql`
    query QUERY($id: Int, $customerId: Int) {
      feedlot(id: $id, customerId: $customerId) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/feedlot/feedlotQuery', errors)
    }
    return data.feedlot
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/feedlot/feedlotQuery', error)
  }
}

export const feedlotCreate = async (token: string, params: FeedlotCreateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($customerOwner: Float!, $feedlot: CreateFeedlot!) {
      createFeedlot(customerOwner: $customerOwner, feedlot: $feedlot)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/feedlot/feedlotCreate', errors)
    }
    return data.createFeedlot
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/feedlot/feedlotCreate', error)
  }
}

export const feedlotUpdate = async (token: string, params: FeedlotUpdateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($customerOwner: Float, $feedlot: UpdateFeedlot!) {
      updateFeedlot(customerOwner: $customerOwner, feedlot: $feedlot)
    }
  `
  try {
    console.log('params')
    console.log(params)
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/feedlot/feedlotUpdate', errors)
    }
    return data.updateFeedlot
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/feedlot/feedlotUpdate', error)
  }
}
