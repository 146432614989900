import { Customer } from 'store/customer/types'
import { Animal } from 'store/animal/types'
import { User, LotAllowedUsers } from 'store/user/types'

export type FeedlotMonitoring = {
  feedlotId: number
  feedlotDescription: string
  lotsToRun: number
  lotsToValidate: number
  pens: PenMonitoring[]
}

export type PenMonitoring = {
  id: number
  code: string
  lots: LotMonitoring[]
}

export type LotMonitoring = {
  id: number
  description: string
  processed: boolean
  animalsCount: number
  animalsDelayedWeighingCount: number
  animalsNotProcessedCount: number
  registerStatus: boolean
  animals: AnimalMonitoring[]
  initDate: Date
  lastDateProcessed: Date
}

export type AnimalMonitoring = {
  id: number
  earring: string
  active?: boolean
  tag?: string
  delayedWeighingDays: number
  lastWeighingDate: Date
  processed?: boolean
  lastProcessedDay?: Date
  error?: string
}

export type Feedlot = {
  id: number
  description: string
  isThirdPart: boolean
  status: FeedlotStatus
  neighborhood?: string
  street?: string
  number?: number
  cityId?: number
  cityDescription?: string
  stateId?: number
  stateDescription?: string
  stateCode?: string
  countryId?: number
  countryDescription?: string
  customer: Customer
  users?: User[]
  pens?: Pen[]
  lots?: FeedlotLot[]
  equipment?: Equipment[]
}

export type LotFeedlot = {
  id: number
  description: string
}

export type PenFeedlot = {
  id?: number
  description: string
  isThirdPart?: boolean
  status: number
}

export enum FeedlotStatus {
  inactive = 0,
  active = 1
}

export enum PenStatus {
  inactive = 0,
  active = 1
}

export type Pen = {
  id: number
  code: string
  status: PenStatus
  feedlot?: PenFeedlot
  animals?: Animal[]
  equipments?: Equipment[]
}

export type Equipment = {
  id: number
  penId: number
  serialNumber: string
  description?: string
  active?: boolean
  equipmentModel?: EquipmentModel
}

export enum EquipmentType {
  scale = 1,
  camera = 2
}

export type EquipmentModel = {
  id: number
  description?: string
  model?: string
  equipmentTypeId: EquipmentType
  manufacturer: Manufacturer
}

export type Manufacturer = {
  id: number
  name: string
}

export type CustomerLot = {
  lotId: number
  customerId: number
  owner: boolean
}

export type Processing = {
  id?: boolean
  startDate?: boolean
  endDate?: boolean
  processed?: boolean
}

export type Lot = {
  id: number
  description: string
  code?: string
  initDate?: Date
  endDate?: Date
  animalsCount?: number
  animalsDelayedWeighingCount?: number
  registerStatus?: boolean
  feedlot?: LotFeedlot
  pen?: Pen
  processing?: Processing
  allowedUsers?: LotAllowedUsers[]
  customers?: CustomerLot
  processedDate?: Date
  processed?: boolean
}

export type FeedlotLot = {
  id: number
  description: string
  initDate: Date
  endData?: Date
  code?: string
}

// export type EquipmentPen = {
//   penId: number
//   equipment: Equipment[]
// }

export type FeedlotState = {
  readonly selectedFeedlot?: Feedlot
  readonly feedlots: Feedlot[]
  readonly feedlotLoading: boolean
  readonly feedlotsMonitoring: FeedlotMonitoring[]
  readonly feedlotMonitoringLoading: boolean
  readonly selectedUser?: User
  readonly userLoading: boolean
  readonly pens: Pen[]
  readonly selectedPen?: Pen
  readonly penLoading: boolean
  readonly equipments: Equipment[]
  readonly selectedEquipment?: Equipment
  readonly equipmentLoading: boolean
  readonly equipmentModels: EquipmentModel[]
  readonly selectedEquipmentModel?: EquipmentModel
  readonly equipmentModelLoading: boolean
  readonly lots: Lot[]
  readonly selectedLot?: Lot
  readonly lotLoading: boolean
}

export enum FeedlotTypes {
  REDUCER_FETCH_FEEDLOT_DATA = 'REDUCER_FETCH_FEEDLOT_DATA',
  REDUCER_CLEAR_FEEDLOT_DATA = 'REDUCER_CLEAR_FEEDLOT_DATA',
  ACTION_GET_FEEDLOT = 'ACTION_GET_FEEDLOT',
  ACTION_SAVE_FEEDLOT = 'ACTION_SAVE_FEEDLOT',
  ACTION_GET_FEEDLOT_MONITORING = 'ACTION_GET_FEEDLOT_MONITORING',
  ACTION_SAVE_FEEDLOT_MONITORING = 'ACTION_SAVE_FEEDLOT_MONITORING',
  ACTION_SET_SELECTED_FEEDLOT = 'ACTION_SET_SELECTED_FEEDLOT',
  ACTION_GET_PEN = 'ACTION_GET_PEN',
  ACTION_SET_SELECTED_PEN = 'ACTION_SET_SELECTED_PEN',
  ACTION_SAVE_PEN = 'ACTION_SAVE_PEN',
  ACTION_GET_EQUIPMENT = 'ACTION_GET_EQUIPMENT',
  ACTION_SET_SELECTED_EQUIPMENT = 'ACTION_SET_SELECTED_EQUIPMENT',
  ACTION_SAVE_EQUIPMENT = 'ACTION_SAVE_EQUIPMENT',
  ACTION_GET_EQUIPMENT_MODEL = 'ACTION_GET_EQUIPMENT_MODEL',
  ACTION_SET_SELECTED_EQUIPMENT_MODEL = 'ACTION_SET_SELECTED_EQUIPMENT_MODEL',
  ACTION_GET_LOT = 'ACTION_GET_LOT',
  ACTION_SAVE_LOT = 'ACTION_SAVE_LOT',
  ACTION_SET_SELECTED_LOT = 'ACTION_SET_SELECTED_LOT'
}
