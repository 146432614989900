import gql from 'graphql-tag'
import { SlaughterhouseQueryParams, SlaughterhouseCreateArgs, SlaughterhouseUpdateArgs } from './types'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import graphql from 'services'
import getFormattedQuery from 'helpers/getFormattedQuery'
import { Slaughterhouse } from 'store/slaughterhouse/types'

export const slaughterhouseQuery = async (
  token: string,
  params: SlaughterhouseQueryParams
): Promise<Slaughterhouse[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      description: true,
      status: true,
      latitude: true,
      longitude: true,
      slaughterday: true,
      slaughteryr: true,
      class: true,
      address: {
        cityId: true,
        cityDescription: true,
        cityStateDescription: true,
        stateId: true,
        stateDescription: true,
        countryId: true,
        countryDescription: true
      }
    }
  const QUERY = gql`
    query QUERY($id: Int) {
      slaughterhouse(id: $id) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/Slaughterhouse/SlaughterhouseQuery', errors)
    }
    return data.slaughterhouse
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/Slaughterhouse/SlaughterhouseQuery', error)
  }
}

export const slaughterhouseCreate = async (token: string, params: SlaughterhouseCreateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($slaughterhouse: CreateSlaughterhouse!) {
      createSlaughterhouse(slaughterhouse: $slaughterhouse)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/Slaughterhouse/SlaughterhouseCreate', errors)
    }
    return data.createSlaughterhouse
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/Slaughterhouse/SlaughterhouseCreate', error)
  }
}

export const slaughterhouseUpdate = async (token: string, params: SlaughterhouseUpdateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($slaughterhouse: UpdateSlaughterhouse!) {
      updateSlaughterhouse(slaughterhouse: $slaughterhouse)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/Slaughterhouse/SlaughterhouseUpdate', errors)
    }
    return data.updateSlaughterhouse
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/Slaughterhouse/SlaughterhouseUpdate', error)
  }
}
