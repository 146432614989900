import { Props } from './types'
import Loading from 'components/widgets/Loading'
import React, { useRef } from 'react'
import Input from 'components/widgets/Input'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'
import Select from 'components/widgets/Select'
import { equipmenModelType } from 'data'
import EquipmentModelSelector from 'components/selectors/EquipmentModel'
import { useTranslation } from 'react-i18next'

const EquipmentModelForm: React.FC<Props> = () => {
  const { t } = useTranslation()
  const formRef: any = useRef(null)
  const { equipmentModelLoading, selectedEquipmentModel } = useSelector((state: ApplicationState) => state.feedlot)

  const clearError = (e: any) => formRef.current?.setFieldError(e.target?.id, '')

  return (
    <div>
      <div ref={formRef}>
        {equipmentModelLoading ? (
          <Loading />
        ) : (
          <div className='row'>
            <div className='col-3'>
              <EquipmentModelSelector selected={selectedEquipmentModel?.id} />
            </div>
            <div className='col-3'>
              <Select
                name='equipmentModel.equipmentTypeId'
                label={t('field.type')}
                defaultOptions={equipmenModelType}
                selectedValue={selectedEquipmentModel?.equipmentTypeId}
                disabled={true}
              />
            </div>
            <div className='col-3'>
              <Input
                name='equipmentModel.manufacturer.name'
                value={selectedEquipmentModel?.manufacturer?.name}
                label={t('field.manufacturer')}
                onFocus={clearError}
                disabled
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default EquipmentModelForm
