import { withRouter } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

// Types
import { Props } from './types'
import { ApplicationState } from 'store/types'

// Methods
import { formatData, generalRender, getTitle } from './helper'
import { disableAnimals, getAnimal } from 'store/animal/actions'
import { ExportToCsv } from 'export-to-csv'

// Components
import Loading from 'components/widgets/Loading'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import MaterialTable from 'material-table'

// Resources
import './styles.scss'
import { AnimalTypes } from 'store/animal/types'
import i18next from 'i18next'

const DisableAnimalFileList: React.FC<Props> = ({ feedlotId, setModalStatus, selectedDisableAnimalsFile }) => {
  const [isFileModalOpen, setFileModalStatus] = useState<boolean>(false)
  const { animals, disableAnimalsNumber, animalLoading, disableAnimalLoading } = useSelector(
    (state: ApplicationState) => state.animal
  )
  const [animalsData, setAnimalsData] = useState<Array<any> | undefined>(undefined)
  const [completeAnimalsData, setCompleteAnimalsData] = useState<Array<any> | undefined>(undefined)
  const [notFoundAnimalsData, setNotFoundAnimalsData] = useState<Array<any> | undefined>(undefined)
  const [earringList, setEarringList] = useState<Array<string>>([])
  const [tagList, setTagList] = useState<Array<string>>([])
  const [sisbovList, setSisbovList] = useState<Array<string>>([])
  const [columns, setColumns] = useState<Array<any>>([])
  const [showDisableAnimalsNumber, setShowDisableAnimalsNumber] = useState<boolean>(false)
  const [showNotFoundAnimalsMessage, setShowNotFoundAnimalsMessage] = useState<boolean>(false)
  const [animalsFileNotFoundData, setAnimalsFileNotFoundData] = useState<Record<string, any>[]>([])
  const [disableBySisbov, setDisableBySisbov] = useState<boolean>(false)

  const fields = {
    id: true,
    earring: true,
    tag: true,
    active: true,
    sisbov: true,
    lot: {
      id: true,
      description: true,
      initDate: true
    }
  }

  const animalsNotFoundFileOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    filename: i18next.t('file.not_found_animals_file'),
    headers: ['earring', 'tag'] // Won't work with useKeysAsHeaders present!
  }

  const dispatch: Dispatch = useDispatch()
  useEffect(() => {
    setShowNotFoundAnimalsMessage(false)
  }, [selectedDisableAnimalsFile])

  // Extract earring or tags from file uploaded to get animals based on this param
  useEffect(() => {
    if (selectedDisableAnimalsFile.length && !animalsData) {
      dispatch({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { animals: [] } })
      let earrings: string[] = []
      let tags: string[] = []
      let sisbov: string[] = []
      _.map(selectedDisableAnimalsFile, s => {
        earrings.push(s[0])
        tags.push(s[1])
        sisbov.push(s[2])
      })
      earrings.shift()
      tags.shift()
      sisbov.shift()
      earrings = earrings.filter(e => e !== undefined && e !== '' && e !== null)
      tags = tags.filter(e => e !== undefined && e !== '' && e !== null)
      sisbov = sisbov.filter(e => e !== undefined && e !== '' && e !== null)
      if (!earringList.length && earrings.length) setEarringList(earrings)
      if (!tagList.length && tags.length) setTagList(tags)
      if (!sisbovList.length && sisbov.length) setSisbovList(sisbov)
    }
  }, [animalsData])

  // Get animals based on file uploaded
  useEffect(() => {
    if (!animals.length && earringList.length && !animalsData)
      dispatch(getAnimal({ args: { feedlotId, active: true, earring: earringList }, fields }))
    else if (!animals.length && tagList.length && !animalsData)
      dispatch(getAnimal({ args: { feedlotId, active: true, tag: tagList }, fields }))
    else if (!animals.length && sisbovList.length && !animalsData)
      dispatch(getAnimal({ args: { feedlotId, active: true, sisbov: sisbovList }, fields }))
  }, [earringList, tagList, sisbovList])

  // Set table data
  useEffect(() => {
    if (animals.length && (!animalsData || !animalsData.length)) setAnimalsData(animals)
    if (selectedDisableAnimalsFile.length && !animals.length && notFoundAnimalsData) {
      setShowNotFoundAnimalsMessage(true)
      // setAnimalsData([])
    } else {
      setShowNotFoundAnimalsMessage(false)
    }
  }, [animals])

  // Identifies the animals from uploaded file not found
  useEffect(() => {
    let difference: Array<any> = []
    if (animalsData) {
      const animalsNotFound: Array<Record<string, any>> = []
      if (earringList.length)
        difference = earringList.map((x: any) => {
          const findedValue = _.filter(animalsData, ['earring', x])
          if (!findedValue.length) return x
        })
      else if (tagList.length)
        difference = tagList.map((x: any) => {
          const findedValue = _.filter(animalsData, ['tag', x])
          if (!findedValue.length) return x
        })

      difference = _.filter(difference, d => d !== undefined)

      const allData = animalsData

      difference.map((d: any, index: number) => {
        const notFoundAnimalFile = {
          earring: earringList.length ? d : '',
          tag: tagList.length ? tagList[index] : ''
        }
        animalsNotFound.push(notFoundAnimalFile)
        const notFoundAnimal = {
          earring: earringList.length ? d : null,
          tag: tagList.length ? tagList[index] : null,
          active: null,
          lot: {
            description: null,
            initDate: null
          }
        }
        if (allData?.length) allData.push(notFoundAnimal)
      })
      setCompleteAnimalsData(allData)
      setAnimalsFileNotFoundData(animalsNotFound)
    } else {
      const animalsNotFound: Array<Record<string, any>> = []
      const allData: Array<any> = []
      if (selectedDisableAnimalsFile.length && !animals.length) {
        selectedDisableAnimalsFile.map((d: any, index: number) => {
          if (index !== 0) {
            const notFoundAnimalFile = {
              earring: d[0] ?? '',
              tag: d[1] ?? ''
            }
            animalsNotFound.push(notFoundAnimalFile)
            const notFoundAnimal = {
              earring: d[0] ?? null,
              tag: d[1] ?? null,
              active: null,
              lot: {
                description: null,
                initDate: null
              }
            }
            allData.push(notFoundAnimal)
          }
        })
        setNotFoundAnimalsData(allData)
        setAnimalsFileNotFoundData(animalsNotFound)
      }
    }
  }, [animalsData])

  const columnNames = ['earring', 'tag', 'sisbov', 'active', 'lot.description', 'lot.initDate']

  useEffect(() => {
    const cols: Array<any> = []
    if (!columns.length) {
      _.map(columnNames, (column: string) => {
        cols.push({
          title: getTitle(column),
          field: column,
          render: (rowData: Record<string, any>) => generalRender(rowData, column),
          cellStyle: { width: 10 }
        })
      })
      setColumns(cols)
    }
  }, [animalsData])

  const disableAction = () => setFileModalStatus(true)

  useEffect(() => {
    if (selectedDisableAnimalsFile[1][2] !== '') {
      setDisableBySisbov(true)
    }
  }, [selectedDisableAnimalsFile])

  const disableAnimalData = () => {
    const animalFiltered = _.filter(animalsData, a => a?.lot?.description !== null)
    if (disableBySisbov) {
      const ids: any[] = selectedDisableAnimalsFile
        .map((animalWithEndDate: any) => {
          const animal = animalFiltered.find(a => a.sisbov === animalWithEndDate[2])
          return animal ? { id: animal.id, endDate: animalWithEndDate[3] } : null
        })
        .filter((animal: any) => animal !== null)
      if (feedlotId) dispatch(disableAnimals(ids))
      setFileModalStatus(false)
    } else {
      const ids: any[] = selectedDisableAnimalsFile
        .map((animalWithEndDate: any) => {
          const animal = animalFiltered.find(a => a.earring === animalWithEndDate[0])
          return animal ? { id: animal.id, endDate: animalWithEndDate[3] } : null
        })
        .filter((animal: any) => animal !== null)

      if (feedlotId) dispatch(disableAnimals(ids))
      setFileModalStatus(false)
    }
  }
  const { t } = useTranslation()

  useEffect(() => {
    if (disableAnimalsNumber) {
      setShowDisableAnimalsNumber(true)
      setCompleteAnimalsData(
        completeAnimalsData?.map(c => {
          c.active = false
          return c
        })
      )
    }
  }, [disableAnimalsNumber])

  const downloadAnimalsNotFoundFile = () => {
    const csvExporter = new ExportToCsv(animalsNotFoundFileOptions)
    csvExporter.generateCsv(animalsFileNotFoundData)
  }

  return (
    <div>
      <ConfirmationModal
        text={t('confirmation.disable_animals_file')}
        handleConfirm={() => disableAnimalData()}
        handleCancel={() => setFileModalStatus(false)}
        open={isFileModalOpen}
        title={t('notice.attention')}
      ></ConfirmationModal>
      {animalLoading || disableAnimalLoading ? (
        <Loading />
      ) : (
        <>
          {showDisableAnimalsNumber ? (
            <h4 className='noticeMessage'>{t('animal.disable_animals_number') + ': ' + disableAnimalsNumber ?? 0}</h4>
          ) : (
            <></>
          )}
          {showNotFoundAnimalsMessage ? (
            <h4 className='noticeMessage'>{t('notice.not_found_active_animals')}</h4>
          ) : (
            <></>
          )}
          <MaterialTable
            columns={columns}
            data={completeAnimalsData ?? notFoundAnimalsData ?? []}
            style={{ width: 'auto' }}
            options={{
              header: completeAnimalsData ? !!completeAnimalsData?.length : !!notFoundAnimalsData?.length,
              toolbar: completeAnimalsData ? !!completeAnimalsData?.length : !!notFoundAnimalsData?.length,
              paging: true,
              toolbarButtonAlignment: 'left',
              searchFieldAlignment: 'left',
              showTitle: false,
              emptyRowsWhenPaging: false,
              addRowPosition: 'first',
              headerStyle: { fontSize: 16, fontWeight: 'bold' },
              pageSizeOptions: [20, 30, 40],
              rowStyle: (rowData: Record<string, any>) => {
                if (rowData?.lot?.description === null) return { backgroundColor: '#FFF' }
                else return { backgroundColor: '#FFF' }
              }
            }}
            actions={
              animalsFileNotFoundData.length
                ? [
                    {
                      icon: 'text_snippet',
                      tooltip: t('file.not_found_animals_file'),
                      onClick: (_: any, data: any) => downloadAnimalsNotFoundFile(),
                      isFreeAction: true
                    },
                    {
                      icon: 'delete',
                      tooltip: t('default.disable_animals'),
                      onClick: (_: any, data: any) => disableAction(),
                      isFreeAction: true
                    }
                  ]
                : [
                    {
                      icon: 'delete',
                      tooltip: t('default.disable_animals'),
                      onClick: (_: any, data: any) => disableAction(),
                      isFreeAction: true
                    }
                  ]
            }
            components={{
              Container: (props: {
                children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
              }) => <div style={{ width: 'auto' }}>{props?.children}</div>
            }}
          />
        </>
      )}
    </div>
  )
}

export default withRouter(DisableAnimalFileList)
