import { Grid } from '@material-ui/core'
import React from 'react'
import i18next from 'i18next'
import moment from 'moment'
import * as _ from 'lodash'

export const generalRender = (rowData: any, type: string) => {
  if (type === 'active') {
    return (
      <Grid className={`field ${rowData?.active ? ' active' : 'inactive'}`} style={{ width: '130px' }}>
        {rowData?.active === null
          ? i18next.t('default.not_found')
          : rowData?.active
          ? i18next.t('default.active')
          : i18next.t('default.inactive')}
      </Grid>
    )
  }
  if (type === 'lot.description') {
    return <Grid>{rowData?.lot?.description}</Grid>
  }
  if (type === 'lot.initDate') {
    return <Grid>{rowData?.lot?.initDate ? moment(rowData?.lot?.initDate).format('DD/MM/YYYY') : null}</Grid>
  }
  if (rowData[type]) {
    return <Grid>{rowData[type]}</Grid>
  }
}

export const getTitle = (column: string) => {
  if (column === 'lot.description') return i18next.t('lot.lot')
  if (column === 'lot.initDate') return i18next.t('field.start')
  return i18next.t('field.' + column)
}

export const formatData = (data: Array<any>): Array<number> => {
  const disableAnimals: Array<number> = []
  _.map(data, d => {
    if (d?.lot?.description) {
      disableAnimals.push(d.id)
    }
  })
  return disableAnimals
}
