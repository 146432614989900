import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Props } from './types'
import { ApplicationState } from 'store/types'
import { Currency } from 'store/economic/types'

import { Option } from 'data/types'
import { setSelectedCurrency, getCurrency } from 'store/economic/actions'
import { CurrencyQueryFields } from 'services/economic/types'
import Select from 'components/widgets/Select'
import { useTranslation } from 'react-i18next'

const CurrencySelector: React.FC<Props> = ({ selected, cleanError }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { currencies, currencyLoading } = useSelector((state: ApplicationState) => state.economic)

  const [options, setOptions] = useState<Option[]>([])

  const formatCurrencys = (currencies: Currency[]) =>
    currencies.map((e: Currency) => ({ label: e.symbol || '', value: e.id || -1 }))

  const setSelected = (id: number) => {
    dispatch(setSelectedCurrency(id))
  }

  useEffect(() => {
    if (!currencies?.length) {
      const fields: CurrencyQueryFields = {
        id: true,
        code: true,
        symbol: true,
        description: true
      }
      dispatch(getCurrency({ fields }))
    } else setOptions(formatCurrencys(currencies))
  }, [currencies, dispatch])

  return (
    <Select
      name='currency.id'
      label={t('currency')}
      loading={currencyLoading}
      defaultOptions={options}
      selectedValue={selected}
      handleSelect={(id: number) => setSelected(id)}
      onFocus={cleanError}
    />
  )
}
export default CurrencySelector
