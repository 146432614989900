import { createTheme } from '@material-ui/core/styles'

export default createTheme({
  palette: {
    primary: {
      light: '#FF4400',
      main: '#FF4400',
      dark: '#FF7100',
      contrastText: '#fff'
    },
    secondary: {
      light: '#E8E3E3',
      main: '#D3D3D3',
      dark: '#878787',
      contrastText: '#1C1C1C'
    }
  }
})
