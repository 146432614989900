export type ProcessedWeighing = {
  id: number
  prediction: string
  day: number
  weight: number
  lotId: number
}

export type WeighingState = {
  readonly selectedProcessedWeighing?: ProcessedWeighing
  readonly processedWeighings: ProcessedWeighing[]
  readonly weighingLoading: boolean
}

export enum WeighingTypes {
  REDUCER_FETCH_WEIGHING_DATA = 'REDUCER_FETCH_WEIGHING_DATA',
  REDUCER_CLEAR_WEIGHING_DATA = 'REDUCER_CLEAR_WEIGHING_DATA',
  ACTION_GET_PROCESSED_WEIGHING = 'ACTION_GET_PROCESSED_WEIGHING',
  ACTION_SET_SELECTED_PROCESSED_WEIGHING = 'ACTION_SET_SELECTED_WEIGHING',
  ACTION_DELETE_GROSS_WEIGHINGS = 'ACTION_DELETE_GROSS_WEIGHINGS'
}
