import React, { useState, useEffect } from 'react'
import { withRouter, useParams } from 'react-router'
import { Props } from './types'
import ExpandableCard from 'components/widgets/ExpandableCard'
import FeedlotForm from 'components/forms/FeedlotForm'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import FeedlotUserList from 'components/lists/FeedlotUserList'
import { setSelectedFeedlot } from 'store/feedlot/actions'
import { useSelector, useDispatch } from 'react-redux'
import PenList from 'components/lists/PenList'
import BreadCrumb from 'components/layout/Breadcrumb'
import { ApplicationState } from 'store/types'
import i18next from 'i18next'

const FeedlotScreen: React.FC<Props> = ({ match }) => {
  const dispatch = useDispatch()
  const { selectedFeedlot, feedlotLoading } = useSelector((state: ApplicationState) => state.feedlot)

  const [panelExpanded, setPanelExpanded] = useState<boolean | string>(false)
  const [isModalOpened, setModalOpened] = useState<boolean>(false)
  const [isEditingGeneral, setEditingGeneral] = useState<boolean>(false)
  const [clickedPanel, setClickedPanel] = useState<boolean | string>(false)

  useEffect(() => {
    const id = Number(match.params.id)
    if (!id) {
      setPanelExpanded('general')
      setClickedPanel('general')
    }
    dispatch(setSelectedFeedlot(id || undefined))
  }, [dispatch, match.params.id])

  const handleExpanded = (panel: string) => (e: React.ChangeEvent<Record<string, any>>, isExpanded: boolean) => {
    if (panel !== panelExpanded) setClickedPanel(panel)
    isEditingGeneral ? setModalOpened(true) : isExpanded ? setPanelExpanded(panel) : setPanelExpanded(false)
  }

  const handleEditing = (active: boolean) => setEditingGeneral(active)
  const handleConfirm = () => {
    let panel: boolean | string = false
    if (clickedPanel !== panelExpanded) panel = clickedPanel
    setModalOpened(false)
    setPanelExpanded(panel)
    setEditingGeneral(false)
  }
  const handleCancel = () => setModalOpened(false)

  const { id } = useParams()
  const urlId = Number(id) || undefined
  const isRegistration = urlId ? false : true
  return (
    <div>
      <BreadCrumb
        items={[
          { label: i18next.t('feedlot.feedlot', { count: 0 }), path: '/feedlots', active: false },
          {
            label: selectedFeedlot?.description || i18next.t('feedlot.registration'),
            path: '/feedlots',
            active: true,
            loading: feedlotLoading
          }
        ]}
      />
      <ExpandableCard
        id='general'
        title={isRegistration ? i18next.t('feedlot.registration') : i18next.t('default.general_information')}
        defaultExpanded={isRegistration}
        expanded={panelExpanded === 'general'}
        onChange={handleExpanded('general')}
      >
        <FeedlotForm onChangeForm={handleEditing} />
      </ExpandableCard>
      {!isRegistration && urlId && (
        <>
          <ExpandableCard
            id='users'
            title={i18next.t('user.user', { count: 0 })}
            expanded={panelExpanded === 'userList'}
            onChange={handleExpanded('userList')}
          >
            <FeedlotUserList feedlotId={urlId} />
          </ExpandableCard>
          <ExpandableCard
            id='pens'
            title={i18next.t('pen.pen', { count: 0 })}
            expanded={panelExpanded === 'penList'}
            onChange={handleExpanded('penList')}
          >
            <PenList feedlotId={urlId} />
          </ExpandableCard>
        </>
      )}
      <ConfirmationModal
        open={isModalOpened}
        title={i18next.t('confirmation.general_close_confirm_title')}
        text={i18next.t('confirmation.general_close_confirm_text')}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    </div>
  )
}
export default withRouter(FeedlotScreen)
