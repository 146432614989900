import React, { useRef, useState, useEffect } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { Props } from './types'
import { useField } from '@unform/core'
import { getLocale, getDateFormat } from 'helpers/i18n'
import './styles.scss'
import { useTranslation } from 'react-i18next'

const DatePicker: React.FC<Props> = ({ name, label, ...rest }) => {
  const { t } = useTranslation()
  const datepickerRef = useRef(null)
  const { fieldName, registerField, defaultValue, error } = useField(name)
  const [date, setDate] = useState(defaultValue || null)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref: any) => ref.clear()
    })
  }, [fieldName, registerField])
  return (
    <div className={!error ? 'div' : 'div-error'}>
      {error && <span>{error}</span>}
      <ReactDatePicker
        className='date-picker'
        id={fieldName}
        ref={datepickerRef}
        selected={date ? new Date(date) : null}
        onChange={setDate}
        locale={getLocale()}
        dateFormat={getDateFormat()}
        {...rest}
      />
      {label && <label htmlFor={fieldName}>{t(label || '')}</label>}
    </div>
  )
}
export default DatePicker
