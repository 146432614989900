import { all, takeLatest } from 'redux-saga/effects'
import {
  workerGetPrice,
  workerSetSelectedPrice,
  workerSavePrice,
  workerGetCurrency,
  workerGetWeightUnity
} from './sagas'
import { EconomicTypes } from './types'

export function* watcherGetPrice() {
  yield takeLatest(EconomicTypes.ACTION_GET_PRICE, workerGetPrice)
}

export function* watcherSetSelectedPrice() {
  yield takeLatest(EconomicTypes.ACTION_SET_SELECTED_PRICE, workerSetSelectedPrice)
}

export function* watcherSavePrice() {
  yield takeLatest(EconomicTypes.ACTION_SAVE_PRICE, workerSavePrice)
}

export function* watcherGetCurrency() {
  yield takeLatest(EconomicTypes.ACTION_GET_CURRENCY, workerGetCurrency)
}

export function* watcherGetWeightUnity() {
  yield takeLatest(EconomicTypes.ACTION_GET_WEIGHTUNITY, workerGetWeightUnity)
}

export default function* authRootSaga() {
  yield all([
    watcherGetPrice(),
    watcherSetSelectedPrice(),
    watcherSavePrice(),
    watcherGetCurrency(),
    watcherGetWeightUnity()
  ])
}
