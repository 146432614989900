import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory, withRouter } from 'react-router-dom'
import { Box, Grid } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import i18next from 'i18next'
import { ApplicationState } from 'store/types'
import { Props, State } from './types'
import { getFeedlot, setSelectedFeedlot } from 'store/feedlot/actions'
import { generalRender } from './helper'
import { FeedlotQueryFields } from 'services/feedlot/types'
import BreadCrumb from 'components/layout/Breadcrumb'
import MaterialTable from 'material-table'

const FeedlotListScreen = () => {
  const { feedlotLoading, feedlots } = useSelector((state: ApplicationState) => state.feedlot)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const fields: FeedlotQueryFields = {
      id: true,
      description: true,
      cityDescription: true,
      status: true,
      customer: { name: true }
    }
    if (!feedlots.length) dispatch(getFeedlot({ fields }))
  }, [feedlots])

  const handleOption = (to: string) => history.push(`${location.pathname}/${to}`)
  const onRowClick = (e: any, rowData: any) => {
    const feedlotId = rowData.id
    setSelectedFeedlot(feedlotId)
    handleOption(feedlotId)
  }

  return (
    <Grid>
      <BreadCrumb items={[{ label: i18next.t('feedlot.feedlot', { count: 0 }), path: '/feedlots', active: true }]} />
      <MaterialTable
        isLoading={feedlotLoading}
        title={<Box className='title'>{i18next.t('feedlot.list_title')}</Box>}
        columns={[
          {
            field: 'description',
            title: i18next.t('field.description'),
            render: (rowData: any) => generalRender(rowData, 'description')
          },
          {
            field: 'customer',
            title: i18next.t('customer.customer'),
            render: (rowData: any) => generalRender(rowData, 'customer')
          },
          {
            field: 'cityDescription',
            title: i18next.t('address.city'),
            render: (rowData: any) => generalRender(rowData, 'cityDescription')
          },
          {
            field: 'status',
            title: i18next.t('field.status'),
            render: (rowData: any) => generalRender(rowData, 'status')
          }
        ]}
        data={feedlots}
        options={{
          emptyRowsWhenPaging: false,
          addRowPosition: 'first',
          headerStyle: { fontSize: 16, fontWeight: 'bold' },
          toolbarButtonAlignment: 'right'
        }}
        onRowClick={onRowClick}
        actions={[
          {
            icon: () => <AddBoxIcon />,
            tooltip: i18next.t('feedlot.new'),
            isFreeAction: true,
            onClick: () => handleOption('new')
          }
        ]}
        style={{ padding: '30px 50px 50px' }}
        localization={{
          body: { emptyDataSourceMessage: i18next.t('feedlot.empty') },
          pagination: {
            firstTooltip: i18next.t('table.first_page'),
            lastTooltip: i18next.t('table.last_page'),
            previousTooltip: i18next.t('table.previous_page'),
            nextTooltip: i18next.t('table.next_page'),
            labelRowsSelect: i18next.t('table.rows')
          },
          toolbar: {
            searchTooltip: i18next.t('table.search'),
            searchPlaceholder: i18next.t('table.search')
          }
        }}
      />
    </Grid>
  )
}

export default FeedlotListScreen
