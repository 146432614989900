import React from 'react'

import './styles.scss'
import { Props } from './types'

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'

import './types'
import { useTranslation } from 'react-i18next'

const ConfirmationDialog: React.FC<Props> = ({ open, handleClose, handleYes, title, body }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {t('default.no')}
          </Button>
          <Button onClick={handleYes} color='primary' autoFocus>
            {t('default.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default ConfirmationDialog
