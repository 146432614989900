import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'

import { MuiThemeProvider } from '@material-ui/core/styles'

import store from './store'

import * as serviceWorker from './serviceWorker' // eslint-disable-line
import Toast from './components/widgets/Toast'
import Auth from './routers/Auth'
import lightTheme from './styles/themes/light'
import './helpers/i18n'
import './styles/scss/main.scss'

const Container = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={lightTheme}>
      <Toast />
      <Suspense fallback={<Loader />}>
        <Auth />
      </Suspense>
    </MuiThemeProvider>
  </Provider>
)

const Loader = () => (
  <div className='App'>
    <div>Loading...</div>
  </div>
)

ReactDOM.render(<Container />, document.getElementById('root'))
serviceWorker.unregister()
