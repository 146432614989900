import { AuthStore, AuthTypes } from './types'

const INITIAL_STATE: AuthStore = {
  attemptingSignIn: false,
  isAuthenticated: true,
  credentials: null,
  checkingAuthentication: false
}

export default (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case AuthTypes.REDUCER_CLEAR_AUTH_DATA: {
      return {
        ...state,
        ...INITIAL_STATE,
        checkingAuthentication: false
      }
    }
    case AuthTypes.REDUCER_FETCH_AUTH_DATA: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}
