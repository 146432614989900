import React from 'react'

import { Props } from './types'
import './styles.scss'

import Dialog from '@material-ui/core/Modal'
import { Fade, Backdrop, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CloseOutlined from '@material-ui/icons/CloseOutlined'

const Modal: React.FC<Props> = ({ open, handleClose, children, title, id }) => {
  const { t } = useTranslation()
  return (
    <Dialog
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className='modal'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div id={id} className='modal-paper'>
          <div className='title'>
            {t(title || '')}
            <IconButton aria-label='close' className='close' onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </div>
          <div className='modal-content'>{children}</div>
        </div>
      </Fade>
    </Dialog>
  )
}
export default Modal
