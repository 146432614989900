import { CustomerTypes, CustomerState } from './types'

const INITIAL_STATE: CustomerState = {
  selectedCustomer: undefined,
  customers: [],
  customerLoading: false
}

export default (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case CustomerTypes.REDUCER_CLEAR_CUSTOMER_DATA:
      return { ...state, ...INITIAL_STATE, checkingAuthentication: false }
    case CustomerTypes.REDUCER_FETCH_CUSTOMER_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
