export type Country = {
  id: number
  description: string
  state?: Region[]
}
export type Region = {
  id: number
  description: string
  code?: string
  cities?: City[]
}
export type City = {
  id: number
  description: string
}
export type Address = {
  countryId?: number
  countryDescription?: string
  stateId?: number
  stateDescription?: string
  cityId?: number
  cityDescription?: string
  cityStateDescription?: string
}

export type AddressState = {
  readonly addresses: Address[]
  readonly countries: Country[]
  readonly regions: Region[]
  readonly cities: City[]
  readonly selectedCountryId?: number
  readonly selectedRegionId?: number
  readonly selectedCityId?: number
  readonly loading: boolean
}

export enum AddressTypes {
  REDUCER_FETCH_ADDRESS_DATA = 'REDUCER_FETCH_ADDRESS_DATA',
  REDUCER_CLEAR_ADDRESS_DATA = 'REDUCER_CLEAR_ADDRESS_DATA',
  ACTION_GET_ADDRESS = 'ACTION_GET_ADDRESS',

  ACTION_SET_SELECTED_COUNTRY = 'ACTION_SET_SELECTED_COUNTRY',
  ACTION_SET_SELECTED_REGION = 'ACTION_SET_SELECTED_REGION',

  ACTION_ADD_COUNTRY = 'ACTION_ADD_COUNTRY',
  ACTION_UPDATE_COUNTRY = 'ACTION_UPDATE_COUNTRY',
  ACTION_DELETE_COUNTRY = 'ACTION_DELETE_COUNTRY'
}
