import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { showToast } from 'store/main/actions'
import { ApplicationState } from 'store/types'

import { Snackbar, SnackbarContent, Icon, IconButton } from '@material-ui/core'

const Toast = () => {
  const dispatch = useDispatch()
  const { isToastOpened, toastMessage } = useSelector((state: ApplicationState) => state.main)

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isToastOpened}
      autoHideDuration={10000}
      onClose={() => isToastOpened && dispatch(showToast())}
    >
      <SnackbarContent
        aria-describedby='client-snackbar'
        message={<div>{toastMessage}</div>}
        action={[
          <IconButton key='close' aria-label='close' color='primary' onClick={() => dispatch(showToast())}>
            <Icon>close</Icon>
          </IconButton>
        ]}
      />
    </Snackbar>
  )
}
export default Toast
