import { action } from 'typesafe-actions'
import { SlaughterhouseTypes } from './types'
import { SlaughterhouseQueryParams, SlaughterhouseMutationArgs } from 'services/slaughterhouse/types'

export const getSlaughterhouses = (params?: SlaughterhouseQueryParams) =>
  action(SlaughterhouseTypes.ACTION_GET_SLAUGHTERHOUSES, { params })
export const setSelectedSlaughterhouse = (slaughterhouseId?: number) =>
  action(SlaughterhouseTypes.ACTION_SET_SELECTED_SLAUGHTERHOUSE, { slaughterhouseId })
export const saveSlaughterhouse = (args: SlaughterhouseMutationArgs) =>
  action(SlaughterhouseTypes.ACTION_SAVE_SLAUGHTERHOUSE, { args })
