import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { Props } from './types'
import './styles.scss'
import { useTranslation } from 'react-i18next'

const Input: React.FC<Props> = ({ name, label, translation, ...rest }) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)

  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({ name: fieldName, path: 'value', ref: inputRef.current })
  }, [fieldName, registerField])

  return (
    <div className={!error ? 'div' : 'div-error'}>
      {error && <span>{t(error)}</span>}
      <input className='input' id={fieldName} ref={inputRef} defaultValue={defaultValue} {...rest} />
      {translation === false
        ? label && <label htmlFor={fieldName}>{label || ''}</label>
        : label && <label htmlFor={fieldName}>{t(label || '')}</label>}
    </div>
  )
}
export default Input
