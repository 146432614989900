import React from 'react'
import { Grid } from '@material-ui/core'
import i18next from 'i18next'
import { FeedlotStatus } from '../../store/feedlot/types'

export const generalRender = (rowData: any, type: string) => {
  switch (type) {
    case 'customer':
      return <Grid>{rowData[type]?.name || i18next.t('table.not_registered')}</Grid>
    case 'isThirdPart':
      return (
        <Grid className={`field is-third-part-${rowData.isThirdPart ? 'active' : 'inactive'}`}>
          {rowData.isThirdPart ? i18next.t('button.yes') : i18next.t('button.no')}
        </Grid>
      )
    case 'status':
      return (
        <Grid className={`field ${rowData.status === FeedlotStatus.active ? 'active' : 'inactive'}`}>
          {rowData.status === FeedlotStatus.active ? i18next.t('default.active') : i18next.t('default.inactive')}
        </Grid>
      )
    default:
      return <Grid>{rowData[type] || i18next.t('table.not_registered')}</Grid>
  }
}
