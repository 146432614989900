import React from 'react'
import i18next from 'i18next'
import { UserStatus } from 'store/user/types'
import { FormGroup, FormControlLabel, Switch, Grid, TextField, Box } from '@material-ui/core'

const cleanError = (e: any, _errors: any, setErrors: (errors: any) => void) => {
  const errors: any = _errors
  if (errors[e.target.id] !== '') {
    errors[e.target.id] = ''
    setErrors(errors)
  }
}

export const editComponent = (props: any, errors: any, setErrors: (errors: any) => void) => {
  const field: any = props.columnDef.field
  switch (field) {
    case 'status':
      const active = props.value !== undefined ? (props.value === UserStatus.active ? true : false) : true
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                disabled={false}
                color={'primary'}
                checked={active}
                onChange={(e: any) => props.onChange(e.target.checked ? UserStatus.active : UserStatus.inactive)}
              />
            }
            label={<Box style={{ fontSize: '14px' }}>{active ? i18next.t('active') : i18next.t('inactive')}</Box>}
          />
        </FormGroup>
      )
    default:
      return (
        <TextField
          id={field}
          InputLabelProps={{ style: { fontSize: '14px' } }}
          InputProps={{ style: { fontSize: '14px' } }}
          inputProps={{ maxLength: 100 }}
          value={props.value || ''}
          onChange={e => props.onChange(e.target.value)}
          variant='standard'
          fullWidth
          error={errors[field] ? (errors[field] !== '' ? true : false) : false}
          placeholder={errors[field] ? i18next.t(errors[field]) : undefined}
          onFocus={e => cleanError(e, errors, setErrors)}
        />
      )
  }
}

export const generalRender = (rowData: any, type: string) => {
  return <Grid>{rowData[type] || i18next.t('table.not_registered')}</Grid>
}

export const getTitle = (column: string) => {
  return i18next.t('field.' + column)
}

export const getCellStyle = (column: string) => {
  switch (column) {
    case 'admin':
      return { width: 5 }
    default:
      return undefined
  }
}
