import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Props } from './types'
import { ApplicationState } from 'store/types'
import { Animal } from 'store/animal/types'

import { Option } from 'data/types'
import { getAnimal } from 'store/animal/actions'
import { AnimalQueryFields } from 'services/animal/types'
import Select from 'components/widgets/Select'
import { useTranslation } from 'react-i18next'

const AnimalSelector: React.FC<Props> = ({ selected, cleanError, lotId, handleSelect }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { animals, animalLoading } = useSelector((state: ApplicationState) => state.animal)

  const [options, setOptions] = useState<Option[]>([])

  const formatAnimals = (animals: Animal[]) =>
    animals.map((e: Animal) => ({ label: e.earring || '', value: e.id || -1 }))

  useEffect(() => {
    if (!animals?.length) {
      const fields: AnimalQueryFields = {
        id: true,
        earring: true
      }
      dispatch(getAnimal({ args: { lotId }, fields }))
    } else setOptions(formatAnimals(animals))
  }, [animals, lotId, dispatch])

  return (
    <Select
      name='animal.id'
      label={t('field.earring')}
      loading={animalLoading}
      defaultOptions={options}
      selectedValue={selected}
      handleSelect={handleSelect}
      onFocus={cleanError}
    />
  )
}
export default AnimalSelector
