import React from 'react'
import { Props } from './types'

import Modal from 'components/widgets/Modal'
import i18next from 'i18next'
import AnimalMonitoringList from 'components/forms/AnimalMonitoringList'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'

const AnimalMonitoringModal: React.FC<Props> = ({ handleClose, isOpen }) => {
  const { selectedLot } = useSelector((state: ApplicationState) => state.feedlot)

  return (
    <Modal
      handleClose={handleClose}
      open={isOpen}
      title={i18next.t('lot.lot') + ': ' + selectedLot?.description}
      id={'animalMonitoringModal'}
    >
      <AnimalMonitoringList handleClose={handleClose} />
    </Modal>
  )
}

export default AnimalMonitoringModal
