import { all, takeLatest } from 'redux-saga/effects'
import {
  workerGetFeedlot,
  workerSaveFeedlot,
  workerGetFeedlotMonitoring,
  workerSaveFeedlotMonitoring,
  workerSetSelectedFeedlot,
  workerGetPen,
  workerSavePen,
  workerGetEquipment,
  workerSaveEquipment,
  workerGetEquipmentModel,
  workerGetLot,
  workerSaveLot,
  workerSetSelectedPen,
  workerSetSelectedLot,
  workerSetSelectedEquipment,
  workerSetSelectedEquipmentModel
} from './sagas'
import { FeedlotTypes } from './types'

export function* watcherGetFeedlot() {
  yield takeLatest(FeedlotTypes.ACTION_GET_FEEDLOT, workerGetFeedlot)
}
export function* watcherSaveFeedlot() {
  yield takeLatest(FeedlotTypes.ACTION_SAVE_FEEDLOT, workerSaveFeedlot)
}
export function* watcherGetFeedlotMonitoring() {
  yield takeLatest(FeedlotTypes.ACTION_GET_FEEDLOT_MONITORING, workerGetFeedlotMonitoring)
}
export function* watcherSaveFeedlotMonitoring() {
  yield takeLatest(FeedlotTypes.ACTION_SAVE_FEEDLOT_MONITORING, workerSaveFeedlotMonitoring)
}
export function* watcherSetSelectedFeedlot() {
  yield takeLatest(FeedlotTypes.ACTION_SET_SELECTED_FEEDLOT, workerSetSelectedFeedlot)
}

export function* watcherGetPen() {
  yield takeLatest(FeedlotTypes.ACTION_GET_PEN, workerGetPen)
}
export function* watcherSetSelectedPen() {
  yield takeLatest(FeedlotTypes.ACTION_SET_SELECTED_PEN, workerSetSelectedPen)
}
export function* watcherSavePen() {
  yield takeLatest(FeedlotTypes.ACTION_SAVE_PEN, workerSavePen)
}

export function* watcherGetEquipment() {
  yield takeLatest(FeedlotTypes.ACTION_GET_EQUIPMENT, workerGetEquipment)
}
export function* watcherSetSelectedEquipment() {
  yield takeLatest(FeedlotTypes.ACTION_SET_SELECTED_EQUIPMENT, workerSetSelectedEquipment)
}
export function* watcherSaveEquipment() {
  yield takeLatest(FeedlotTypes.ACTION_SAVE_EQUIPMENT, workerSaveEquipment)
}
export function* watcherGetEquipmentModel() {
  yield takeLatest(FeedlotTypes.ACTION_GET_EQUIPMENT_MODEL, workerGetEquipmentModel)
}
export function* watcherSetSelectedEquipmentModel() {
  yield takeLatest(FeedlotTypes.ACTION_SET_SELECTED_EQUIPMENT_MODEL, workerSetSelectedEquipmentModel)
}
export function* watcherGetLot() {
  yield takeLatest(FeedlotTypes.ACTION_GET_LOT, workerGetLot)
}
export function* watcherSaveLot() {
  yield takeLatest(FeedlotTypes.ACTION_SAVE_LOT, workerSaveLot)
}
export function* watcherSetSelectedLot() {
  yield takeLatest(FeedlotTypes.ACTION_SET_SELECTED_LOT, workerSetSelectedLot)
}

export default function* authRootSaga() {
  yield all([
    watcherGetFeedlot(),
    watcherSaveFeedlot(),
    watcherSetSelectedFeedlot(),
    watcherGetPen(),
    watcherSetSelectedPen(),
    watcherSavePen(),
    watcherGetEquipment(),
    watcherSetSelectedEquipment(),
    watcherSaveEquipment(),
    watcherGetEquipmentModel(),
    watcherSetSelectedEquipmentModel(),
    watcherGetLot(),
    watcherSetSelectedLot(),
    watcherSaveLot(),
    watcherGetFeedlotMonitoring(),
    watcherSaveFeedlotMonitoring()
  ])
}
