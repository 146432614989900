import { Customer } from 'store/customer/types'
import { Pen, Lot } from 'store/feedlot/types'

export type User = {
  id: number
  email: string
  status?: UserStatus
  name?: string
  category?: string
  document?: string
  dateOfBirth?: string
  phone?: string
  permissionSet?: PermissionSet[]
  customer?: Customer
}

export enum UserStatus {
  inactive = 0,
  active = 1
}

export type LotAllowedUsers = {
  id: number
  name: string
}

export type PermissionSet = {
  userId: number
  feedlotId: number
  permissions: Permissions
  pens: Pen[]
  lots: Lot[]
  removedPens?: number[]
  removedLots?: number[]
}

export type Permissions = {
  admin?: boolean
  allowEdit?: boolean
  allowEconomicInfo?: boolean
  allowAllLots?: boolean
  allowAllPens?: boolean
}

export type UserState = {
  readonly users: User[]
  readonly userEmails: User[]
  readonly selectedUser?: User
  readonly userLoading: boolean
  readonly userPermissionsLoading: boolean
  readonly userEmailsLoading: boolean
}

export enum UserTypes {
  REDUCER_FETCH_USER_DATA = 'REDUCER_FETCH_USER_DATA',
  REDUCER_CLEAR_USER_DATA = 'REDUCER_CLEAR_USER_DATA',
  ACTION_GET_USER = 'ACTION_GET_USER',
  ACTION_GET_USER_EMAILS = 'ACTION_GET_USER_EMAILS',
  ACTION_SET_SELECTED_USER = 'ACTION_SET_SELECTED_USER',
  ACTION_SET_USER = 'ACTION_SET_USER',
  ACTION_SAVE_USER = 'ACTION_SAVE_USER',
  ACTION_SAVE_USER_PERMISSIONS = 'ACTION_SAVE_USER_PERMISSIONS'
}
