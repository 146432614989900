import { put, call, select } from 'redux-saga/effects'
import { SlaughterhouseTypes, Slaughterhouse } from './types'
import { workerCheckAuthentication } from 'store/auth/sagas'
import Exception from 'helpers/exception'
import { UNEXPECTED_ERROR_CODE, SLAUGHTERHOUSE_NOT_FOUND_OR_INACTIVE_ERROR } from 'helpers/EventCodes'
import { MainTypes } from 'store/main/types'
import _ from 'lodash'
import { slaughterhouseQuery, slaughterhouseCreate, slaughterhouseUpdate } from 'services/slaughterhouse'
import { SlaughterhouseQueryParams } from 'services/slaughterhouse/types'
import { workerGetAddress } from 'store/address/sagas'
import { addressQuery } from 'services/address'

export function* workerGetSlaughterhouses(action: any) {
  const { params } = action.payload
  try {
    yield put({ type: SlaughterhouseTypes.REDUCER_FETCH_SLAUGHTERHOUSE_DATA, payload: { slaughterhouseLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    const slaughterhouses = yield call(slaughterhouseQuery, credentials.token, params)

    yield put({
      type: SlaughterhouseTypes.REDUCER_FETCH_SLAUGHTERHOUSE_DATA,
      payload: {
        slaughterhouseLoading: false,
        slaughterhouses: [...slaughterhouses],
        selectedSlaughterhouse: undefined
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) message = error.getMessage()
    else
      message = new Exception(
        UNEXPECTED_ERROR_CODE,
        'slaughterhouse/sagas/workerGetSlaughterhouses',
        error
      ).getMessage()
    yield put({
      type: SlaughterhouseTypes.REDUCER_FETCH_SLAUGHTERHOUSE_DATA,
      payload: { slaughterhouseLoading: false }
    })
    yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
  }
}

export function* workerSetSelectedSlaughterhouse(action: any) {
  const store = yield select(s => s)
  const { slaughterhouseId } = action.payload
  try {
    yield put({ type: SlaughterhouseTypes.REDUCER_FETCH_SLAUGHTERHOUSE_DATA, payload: { slaughterhouseLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    let selectedSlaughterhouse: Slaughterhouse | undefined

    if (slaughterhouseId) {
      const params: SlaughterhouseQueryParams = { args: { id: slaughterhouseId } }
      const slaughterhouseList = yield call(slaughterhouseQuery, credentials?.token, params)

      const mainStore = store.main
      const { history } = mainStore
      if (slaughterhouseList.length) selectedSlaughterhouse = slaughterhouseList[0]
      else {
        history.push('/slaughterhouses')
        throw new Exception(
          SLAUGHTERHOUSE_NOT_FOUND_OR_INACTIVE_ERROR,
          'slaughterhouse/sagas/workerSetSelectedSlaughterhouse'
        )
      }
    }
    yield put({
      type: SlaughterhouseTypes.REDUCER_FETCH_SLAUGHTERHOUSE_DATA,
      payload: {
        slaughterhouseLoading: false,
        selectedSlaughterhouse: selectedSlaughterhouse ? { ...selectedSlaughterhouse } : undefined
      }
    })
    if (selectedSlaughterhouse?.address?.cityId) {
      const payload = { params: { args: { cityId: selectedSlaughterhouse?.address?.cityId } } }
      yield call(workerGetAddress, { payload })
    }
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'slaughterhouse/sagas/workerSaveSlaughterhouse', error)
      console.log(ex.getMessage().getText())
    }
    yield put({
      type: SlaughterhouseTypes.REDUCER_FETCH_SLAUGHTERHOUSE_DATA,
      payload: { slaughterhouseLoading: false }
    })
  }
}

export function* workerSaveSlaughterhouse(action: any) {
  const { args } = action.payload
  const { slaughterhouse } = args
  try {
    yield put({ type: SlaughterhouseTypes.REDUCER_FETCH_SLAUGHTERHOUSE_DATA, payload: { slaughterhouseLoading: true } })
    yield call(workerCheckAuthentication)

    const store = yield select(s => s)
    const authStore = store.auth

    const mainStore = store.main
    const { history } = mainStore

    const slaughterhouseStore = store.slaughterhouse
    const { slaughterhouses } = slaughterhouseStore
    const { credentials } = authStore
    if (!slaughterhouse.id) {
      slaughterhouse.id = yield call(slaughterhouseCreate, credentials.token, args)
      slaughterhouse.address = (yield call(addressQuery, credentials.token, {
        args: { cityId: slaughterhouse.cityId }
      }))[0]
      slaughterhouses.push(slaughterhouse)
      history.push(`/slaughterhouses/${slaughterhouse.id}`)
    } else {
      yield call(slaughterhouseUpdate, credentials.token, args)
      const index = _.findIndex(slaughterhouses, (c: Slaughterhouse) => c.id === slaughterhouse.id)
      slaughterhouse.address = (yield call(addressQuery, credentials.token, {
        args: { cityId: slaughterhouse.cityId }
      }))[0]
      slaughterhouses[index] = slaughterhouse
    }

    yield put({
      type: SlaughterhouseTypes.REDUCER_FETCH_SLAUGHTERHOUSE_DATA,
      payload: {
        slaughterhouses: [...slaughterhouses],
        selectedSlaughterhouse: { ...slaughterhouse },
        slaughterhouseLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'slaughterhouse/sagas/workerSaveSlaughterhouse', error)
      console.log(ex.getMessage().getText())
    }
    yield put({
      type: SlaughterhouseTypes.REDUCER_FETCH_SLAUGHTERHOUSE_DATA,
      payload: { slaughterhouseLoading: false }
    })
  }
}
