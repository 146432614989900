import { Props } from './types'
import Loading from 'components/widgets/Loading'
import React, { useRef } from 'react'
import { Form } from '@unform/web'
import Input from 'components/widgets/Input'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'
import Button from 'components/widgets/Button'
import { SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import _ from 'lodash'
import { Equipment } from 'store/feedlot/types'
import { saveEquipment } from 'store/feedlot/actions'
import Select from 'components/widgets/Select'
import { statusOptions } from 'data'
import EquipmentModelForm from '../EquipmentModelForm'
import { useTranslation } from 'react-i18next'

const EquipmentForm: React.FC<Props> = ({ feedlotId, penId }) => {
  const { t } = useTranslation()
  const formRef: any = useRef(null)
  const dispatch = useDispatch()
  const { selectedEquipment, equipmentLoading, selectedEquipmentModel } = useSelector(
    (state: ApplicationState) => state.feedlot
  )

  const buttonLabel = selectedEquipment?.id ? 'save' : 'add'

  const clearError = (e: any) => formRef.current?.setFieldError(e.target?.id, '')

  const handleSubmit: SubmitHandler<Equipment> = async data => {
    const equipmentModel = selectedEquipmentModel
    const equipmentModelId = selectedEquipmentModel?.id
    delete data.equipmentModel
    const equipment: any = data
    equipment.id = selectedEquipment?.id
    equipment.penId = penId
    equipment.feedlotId = feedlotId
    equipment.active = equipment.active === 1 ? true : false
    equipment.equipmentModelId = equipmentModelId

    _.map(Object.entries(equipment), ([key, value]) => (equipment[key] = value === '' ? null : value))

    try {
      if (!penId) return
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        serialNumber: Yup.string().required(() => t('field.required'))
      })
      await schema.validate(equipment, { abortEarly: false })
      dispatch(saveEquipment({ equipment }, equipmentModel))
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }

  return (
    <div className='container'>
      {equipmentLoading ? (
        <Loading />
      ) : (
        <>
          <>
            <Form ref={formRef} initialData={selectedEquipment} onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-3'>
                  <Input name='serialNumber' label={t('field.number')} onFocus={clearError} />
                </div>
                <div className='col-3'>
                  <Input name='description' label={t('field.description')} onFocus={clearError} />
                </div>
                <div className='col-3'>
                  <Select
                    name='active'
                    label={t('field.status')}
                    defaultOptions={statusOptions}
                    selectedValue={selectedEquipment?.active === false ? 0 : 1}
                  />
                </div>
              </div>
              <div className='row'>
                <h4>{t('field.equipmentModel')}</h4>
                <div className='col-12'>
                  <EquipmentModelForm />
                </div>
              </div>
              <div className='row'>
                <div className='col-5 col-offset-3 mtop-3'>
                  <Button label={buttonLabel} />
                </div>
              </div>
            </Form>
          </>
        </>
      )}
    </div>
  )
}

export default EquipmentForm
