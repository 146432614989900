import { UserTypes, UserState } from './types'

const INITIAL_STATE: UserState = {
  users: [],
  userLoading: false,
  userPermissionsLoading: false,
  userEmails: [],
  userEmailsLoading: false
}

export default (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case UserTypes.REDUCER_CLEAR_USER_DATA:
      return { ...state, ...INITIAL_STATE, checkingAuthentication: false }
    case UserTypes.REDUCER_FETCH_USER_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
