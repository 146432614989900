import { action } from 'typesafe-actions'
import { ProcessedWeighingQueryParams, DeleteGrossWeighingsArgs } from '../../../services/animal/weighing/types'
import { WeighingTypes } from './types'

export const getWeighing = (params?: ProcessedWeighingQueryParams) =>
  action(WeighingTypes.ACTION_GET_PROCESSED_WEIGHING, { params })

export const setSelectedWeighing = (feedlotId?: number) =>
  action(WeighingTypes.ACTION_SET_SELECTED_PROCESSED_WEIGHING, { feedlotId })

export const deleteGrossWeighings = (args: DeleteGrossWeighingsArgs) =>
  action(WeighingTypes.ACTION_DELETE_GROSS_WEIGHINGS, { args })
