import { combineReducers } from 'redux'

import auth from './auth/reducers'
import main from './main/reducers'
import customer from './customer/reducers'
import user from './user/reducers'
import animal from './animal/reducers'
import unprocessedAnimals from './animal/unprocessedAnimals/reducers'
import weighing from './animal/weighing/reducers'
import feedlot from './feedlot/reducers'
import address from './address/reducers'
import slaughterhouse from './slaughterhouse/reducers'
import economic from './economic/reducers'

export default combineReducers({
  auth,
  main,
  customer,
  user,
  animal,
  unprocessedAnimals,
  weighing,
  feedlot,
  address,
  slaughterhouse,
  economic
})
