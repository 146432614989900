export const NO_CONNECTION_ERROR_CODE = 0

export const DATABASE_UNEXPECTED_ERROR = 7
export const DATABASE_ERROR_CODE = 9
export const BAD_PARAMS_ERROR_CODE = 98
export const UNEXPECTED_ERROR_CODE = 99
export const USE_CELLULAR_DENIED = 100

export const INVALID_USERNAME = 1000
export const WRONG_PASSWORD = 1001
export const USER_NOT_FOUND_OR_INACTIVE_ERROR = 1002
export const SIGN_IN_AGAIN = 1003
export const CUSTOMER_NOT_FOUND_OR_INACTIVE_ERROR = 1004
export const FEEDLOT_NOT_FOUND_OR_INACTIVE_ERROR = 1005
export const PEN_NOT_FOUND_OR_INACTIVE_ERROR = 1006
export const SLAUGHTERHOUSE_NOT_FOUND_OR_INACTIVE_ERROR = 1007
export const PRICE_NOT_FOUND_OR_INACTIVE_ERROR = 1008
export const WEIGHING_NOT_FOUND_OR_INACTIVE_ERROR = 1009
export const UNPROCESSED_ANIMALS_NOT_FOUND_ERROR = 1010
