import { Props } from './types'
import Loading from 'components/widgets/Loading'
import React, { useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'
import Button from 'components/widgets/Button'
import * as Yup from 'yup'
import _ from 'lodash'
import { getLot } from 'store/feedlot/actions'
import { updateAnimalLot } from 'store/animal/actions'
import { useTranslation } from 'react-i18next'
import LotSelector from 'components/selectors/Lot'
import ConfirmationModal from 'components/modals/ConfirmationModal'

const MoveLotForm: React.FC<Props> = ({ feedlotId, lotId, handleClose }) => {
  const { t } = useTranslation()
  const formRef: any = useRef(null)
  const dispatch = useDispatch()
  const { selectedAnimalGroup, animalGroupLoading } = useSelector((state: ApplicationState) => state.animal)
  const [isConfirmationModalOpen, setConfirmationModalStatus] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>(null)
  const [selectedLotId, setSelectedLotId] = useState<number | undefined>(undefined)

  useEffect(() => {
    dispatch(getLot({ args: { feedlotId } }))
  }, [feedlotId, dispatch])

  const buttonLabel = 'save'

  const handleSubmit: any = (data: any) => {
    setFormData(data)
    setConfirmationModalStatus(true)
  }

  const callSaveAnimalGroupData: any = () => {
    saveAnimalData(formData)
  }

  const saveAnimalData: any = async () => {
    const animalsIds: number[] = []

    _.map(selectedAnimalGroup, a => {
      animalsIds.push(a.id)
    })

    try {
      if (!lotId) return
      formRef.current.setErrors({})
      if (selectedLotId) dispatch(updateAnimalLot({ lotId, newLotId: selectedLotId, animalId: animalsIds }))
      setConfirmationModalStatus(false)
      handleClose()
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }

  const handleCancel: any = () => {
    handleClose()
  }

  return (
    <div>
      <ConfirmationModal
        text={t('confirmation.update_animal_group')}
        handleConfirm={() => callSaveAnimalGroupData()}
        handleCancel={() => setConfirmationModalStatus(false)}
        open={isConfirmationModalOpen}
        title='Attention'
      ></ConfirmationModal>
      <div className='container'>
        {animalGroupLoading ? (
          <Loading />
        ) : (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-3'>
                <LotSelector
                  feedlotId={feedlotId}
                  cleanError={() => formRef.current?.setFieldError('selectedAnimalGroup.newLotId', '')}
                  handleSelect={(id: number) => setSelectedLotId(id)}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-3 mtop-3'>
                <Button onClick={handleCancel} autoFocus label={'button.cancel'} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Button label={buttonLabel} type='submit' />
              </div>
            </div>
          </Form>
        )}
      </div>
    </div>
  )
}

export default MoveLotForm
