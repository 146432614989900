import { put, call, select } from 'redux-saga/effects'
import { AddressTypes } from './types'
import { countryQuery, addressQuery } from 'services/address'
import { stateQuery } from 'services/address'
import { cityQuery } from 'services/address'
import Exception from 'helpers/exception'
import { MainTypes } from '../main/types'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'

import { workerCheckAuthentication } from '../auth/sagas'

export function* workerGetAddress(action: any) {
  const { params } = action.payload
  try {
    yield put({ type: AddressTypes.REDUCER_FETCH_ADDRESS_DATA, payload: { loading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    let addresses = []
    addresses = yield call(addressQuery, credentials.token, params)

    yield put({
      type: AddressTypes.REDUCER_FETCH_ADDRESS_DATA,
      payload: { loading: false, addresses: [...addresses] }
    })
  } catch (error) {
    console.log(error)
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'address/sagas/workerSetSelectedRegion', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: AddressTypes.REDUCER_FETCH_ADDRESS_DATA, payload: { loading: false } })
  }
}

export function* workerSetSelectedCountry(action: any) {
  const { selectedCountryId, selectedRegionId, selectedCityId } = action.payload
  try {
    yield put({ type: AddressTypes.REDUCER_FETCH_ADDRESS_DATA, payload: { loading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    const countryStore = yield select(s => s.address)
    let { countries, cities, regions } = countryStore
    if (!countries.length) countries = yield call(countryQuery, credentials.token, {})
    if (selectedCountryId) regions = yield call(stateQuery, credentials.token, { args: { id: selectedCountryId } })
    if (selectedRegionId) cities = yield call(cityQuery, credentials.token, { args: { id: selectedRegionId } })

    yield put({
      type: AddressTypes.REDUCER_FETCH_ADDRESS_DATA,
      payload: { loading: false, countries, cities, regions, selectedCountryId, selectedRegionId, selectedCityId }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'address/sagas/workerGetCountries', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: AddressTypes.REDUCER_FETCH_ADDRESS_DATA, payload: { loading: false } })
  }
}

export function* workerSetSelectedRegion(action: any) {
  const { selectedRegionId } = action.payload
  try {
    yield put({ type: AddressTypes.REDUCER_FETCH_ADDRESS_DATA, payload: { loading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    let cities = []
    if (selectedRegionId) cities = yield call(cityQuery, credentials.token, { args: { id: selectedRegionId } })

    yield put({
      type: AddressTypes.REDUCER_FETCH_ADDRESS_DATA,
      payload: { loading: false, selectedRegionId, cities: [...cities] }
    })
  } catch (error) {
    console.log(error)
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'address/sagas/workerSetSelectedRegion', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: AddressTypes.REDUCER_FETCH_ADDRESS_DATA, payload: { loading: false } })
  }
}
