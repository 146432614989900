import gql from 'graphql-tag'
import { LotQueryParams, LotCreateArgs, LotUpdateArgs } from './types'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import graphql from 'services'
import { Lot } from 'store/feedlot/types'
import getFormattedQuery from 'helpers/getFormattedQuery'

export const lotQuery = async (token: string, params: LotQueryParams): Promise<Lot[]> => {
  if (!params.fields) {
    params.fields = {
      id: true,
      code: true,
      initDate: true,
      endDate: true,
      description: true,
      animalsCount: true,
      animalsDelayedWeighingCount: true,
      registerStatus: true,
      processing: {
        id: true,
        startDate: true,
        endDate: true,
        processed: true
      },
      feedlot: { id: true, description: true },
      allowedUsers: { id: true, name: true },
      customers: { lotId: true, customerId: true, owner: true }
    }
  }
  const QUERY = gql`
    query QUERY(
      $delayedWeighingsOnly: Boolean
      $customerId: Int
      $feedlotId: Int
      $active: Boolean
      $penId: Int
      $id: Int
    ) {
      lot(
        delayedWeighingsOnly: $delayedWeighingsOnly, 
        customerId: $customerId, 
        feedlotId: $feedlotId,
        active: $active,
        penId: $penId, 
        id: $id
      ) ${getFormattedQuery(params.fields)}
    }
  `

  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/lot/getLotRequest', errors)
    }
    return data.lot
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/lot/getLotRequest', error)
  }
}

export const lotCreate = async (token: string, params: LotCreateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($lot: CreateLot!) {
      createLot(lot: $lot)
    }
  `

  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/graphql/lot/lotCreate', errors)
    }
    return data.createLot
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/graphql/lot/lotCreate', error)
  }
}

export const lotUpdate = async (token: string, params: LotUpdateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($lot: UpdateLot!) {
      updateLot(lot: $lot)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/graphql/lot/lotUpdate', errors)
    }
    return data.updateLot
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/graphql/lot/lotUpdate', error)
  }
}
