import { Grid } from '@material-ui/core'
import React from 'react'
import i18next from 'i18next'
import { EquipmentType } from 'store/feedlot/types'

export const generalRender = (rowData: any, type: string) => {
  switch (type) {
    case 'equipmentModel.description':
      return <Grid>{rowData?.equipmentModel?.description || i18next.t('table.not_registered')}</Grid>
    case 'equipmentModel.model':
      return <Grid>{rowData?.equipmentModel?.model || i18next.t('table.not_registered')}</Grid>
    case 'active':
      return (
        <Grid className={`field ${rowData.active ? 'active' : 'inactive'}`}>
          {rowData.active ? i18next.t('active') : i18next.t('inactive')}
        </Grid>
      )
    case 'equipmentModel.equipmentTypeId':
      return <Grid>{rowData?.equipmentModel?.equipmentTypeId === EquipmentType.scale ? 'Scale' : 'Camera'}</Grid>
    case 'equipmentModel.manufacturer.name':
      return <Grid>{rowData?.equipmentModel?.manufacturer?.name || i18next.t('table.not_registered')}</Grid>
    default:
      return <Grid>{rowData[type] || i18next.t('table.not_registered')}</Grid>
  }
}

export const getTitle = (column: string) => {
  switch (column) {
    case 'serialNumber':
      return i18next.t('field.number')
    case 'active':
      return i18next.t('field.status')
    case 'equipmentModel.description':
      return i18next.t('field.equipmentModelDescription')
    case 'equipmentModel.model':
      return i18next.t('field.equipmentModel')
    case 'equipmentModel.equipmentTypeId':
      return i18next.t('field.type')
    case 'equipmentModel.manufacturer.name':
      return i18next.t('field.manufacturer')
    default:
      return i18next.t('field.' + column)
  }
}
