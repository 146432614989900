/**
 * Classe de exceção
 * @class
 *
 */

import i18next from 'i18next'
import Message from '../Message'
import {
  HTTP_NO_CONNECTION,
  HTTP_200_OK,
  HTTP_201_CREATED,
  HTTP_400_INVALID_REQUEST,
  HTTP_401_UNAUTHORIZED,
  HTTP_409_GENERAL_CONFLICT,
  HTTP_500_INTERNAL_ERROR,
  HTTP_404_NOT_FOUND
} from '../http/Codes'

import {
  NO_CONNECTION_ERROR_CODE,
  DATABASE_ERROR_CODE,
  BAD_PARAMS_ERROR_CODE,
  UNEXPECTED_ERROR_CODE,
  SIGN_IN_AGAIN,
  CUSTOMER_NOT_FOUND_OR_INACTIVE_ERROR,
  FEEDLOT_NOT_FOUND_OR_INACTIVE_ERROR,
  PEN_NOT_FOUND_OR_INACTIVE_ERROR
} from '../EventCodes'
export default class Exception {
  /**
   * @constructs
   * @param {number} code
   * @param {string} source
   * @param {string | unknown} additionalInfo
   */
  code: number | string

  source: string

  additionalInfo?: string | unknown

  constructor(code: number | string, source: string, additionalInfo?: string | unknown) {
    this.code = code
    this.source = source
    this.additionalInfo = additionalInfo
  }

  getCode = () => this.code

  /**
   * @function
   * Retorna instância de mensagem que pode ser exibiba ao usuário
   */
  getMessage = () =>
    new Message(
      this.getMessageType(),
      this.getMessageTitle(),
      this.getLocalizedText(),
      Message.SNACK,
      this.source,
      this.additionalInfo
    )

  getMessageType = () => {
    switch (this.code) {
      case UNEXPECTED_ERROR_CODE: {
        return Message.ERROR
      }
      case BAD_PARAMS_ERROR_CODE: {
        return Message.ERROR
      }
      case DATABASE_ERROR_CODE: {
        return Message.ERROR
      }
      case HTTP_NO_CONNECTION: {
        return Message.ERROR
      }
      case HTTP_200_OK: {
        return Message.INFO
      }
      case HTTP_201_CREATED: {
        return Message.INFO
      }
      case HTTP_400_INVALID_REQUEST: {
        return Message.ERROR
      }
      case HTTP_401_UNAUTHORIZED: {
        return Message.ERROR
      }
      case HTTP_409_GENERAL_CONFLICT: {
        return Message.ERROR
      }
      case HTTP_500_INTERNAL_ERROR: {
        return Message.ERROR
      }
      case NO_CONNECTION_ERROR_CODE: {
        return Message.ERROR
      }
      case HTTP_404_NOT_FOUND: {
        return Message.ERROR
      }
      case SIGN_IN_AGAIN: {
        return Message.ERROR
      }
      case CUSTOMER_NOT_FOUND_OR_INACTIVE_ERROR: {
        return Message.ERROR
      }
      case FEEDLOT_NOT_FOUND_OR_INACTIVE_ERROR: {
        return Message.ERROR
      }
      case PEN_NOT_FOUND_OR_INACTIVE_ERROR: {
        return Message.ERROR
      }
      default:
        return Message.ERROR
    }
  }

  getLocalizedText = () => {
    switch (this.code) {
      case UNEXPECTED_ERROR_CODE: {
        return i18next.t('unexpected_error')
      }
      case BAD_PARAMS_ERROR_CODE: {
        return i18next.t('unexpected_error')
      }
      case DATABASE_ERROR_CODE: {
        return i18next.t('unexpected_error')
      }
      case HTTP_NO_CONNECTION: {
        return i18next.t('verify_connection')
      }
      case HTTP_200_OK: {
        return i18next.t('unexpected_error')
      }
      case HTTP_201_CREATED: {
        return i18next.t('unexpected_error')
      }
      case HTTP_400_INVALID_REQUEST: {
        return i18next.t('invalid_request')
      }
      case HTTP_401_UNAUTHORIZED: {
        return i18next.t('unauthorized')
      }
      case HTTP_409_GENERAL_CONFLICT: {
        return i18next.t('email_already_exists')
      }
      case HTTP_500_INTERNAL_ERROR: {
        return i18next.t('unexpected_error')
      }
      case NO_CONNECTION_ERROR_CODE: {
        return i18next.t('unexpected_error')
      }
      case HTTP_404_NOT_FOUND: {
        return i18next.t('not_found')
      }
      case SIGN_IN_AGAIN: {
        return i18next.t('sign_in_again')
      }
      case CUSTOMER_NOT_FOUND_OR_INACTIVE_ERROR: {
        return `${i18next.t('customer')} ${i18next.t('not_found')}`
      }
      case FEEDLOT_NOT_FOUND_OR_INACTIVE_ERROR: {
        return `${i18next.t('feedlot')} ${i18next.t('not_found')}`
      }
      case PEN_NOT_FOUND_OR_INACTIVE_ERROR: {
        return `${i18next.t('pen')} ${i18next.t('not_found')}`
      }
      default:
        return i18next.t('unexpected_error')
    }
  }

  getMessageTitle = () => {
    switch (this.code) {
      case UNEXPECTED_ERROR_CODE: {
        return 'error'
      }
      case BAD_PARAMS_ERROR_CODE: {
        return 'error'
      }
      case DATABASE_ERROR_CODE: {
        return 'error'
      }
      case HTTP_NO_CONNECTION: {
        return 'error'
      }
      case HTTP_200_OK: {
        return 'success'
      }
      case HTTP_201_CREATED: {
        return 'success'
      }
      case HTTP_400_INVALID_REQUEST: {
        return 'error'
      }
      case HTTP_401_UNAUTHORIZED: {
        return 'error'
      }
      case HTTP_409_GENERAL_CONFLICT: {
        return 'error'
      }
      case HTTP_500_INTERNAL_ERROR: {
        return 'error'
      }
      case NO_CONNECTION_ERROR_CODE: {
        return 'warning'
      }
      case HTTP_404_NOT_FOUND: {
        return 'error'
      }
      case SIGN_IN_AGAIN: {
        return 'error'
      }
      case CUSTOMER_NOT_FOUND_OR_INACTIVE_ERROR: {
        return 'error'
      }
      case FEEDLOT_NOT_FOUND_OR_INACTIVE_ERROR: {
        return 'error'
      }
      case PEN_NOT_FOUND_OR_INACTIVE_ERROR: {
        return 'error'
      }
      default:
        return 'information'
    }
  }
}
