import React from 'react'
import { Props } from './types'

import Modal from 'components/widgets/Modal'
import AnimalGroupForm from 'components/forms/AnimalGroupForm'

const AnimalGroupModal: React.FC<Props> = ({ handleClose, isOpen, feedlotId, lotId, additionalInfoKeys }) => (
  <Modal handleClose={handleClose} open={isOpen} title={'animal.update_animals'}>
    <AnimalGroupForm
      feedlotId={feedlotId || 0}
      lotId={lotId || 0}
      additionalInfoKeys={additionalInfoKeys}
      handleClose={handleClose}
    />
  </Modal>
)

export default AnimalGroupModal
