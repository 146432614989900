import { gql } from 'apollo-boost'
import graphql from 'services'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import getFormattedQuery from 'helpers/getFormattedQuery'
import { UnprocessedAnimalsQueryParams } from './types'
import { UnprocessedAnimal } from 'store/animal/unprocessedAnimals/types'

export const unprocessedAnimalsQuery = async (
  token: string,
  params: UnprocessedAnimalsQueryParams
): Promise<UnprocessedAnimal[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      lotId: true,
      processingFailure: {
        id: true,
        type: true,
        description: true
      },
      beeftraderProcessing: {
        id: true,
        lotId: true,
        processingDate: true,
        feedlotId: true,
        unprocessedDays: true
      },
      animals: {
        id: true,
        earring: true,
        tag: true
      }
    }
  const QUERY = gql`
      query QUERY($id: Int, $lotId: Int, $processingFailureId: Int, $feedlotId: Int) {
        unprocessedAnimals(id: $id, lotId: $lotId, feedlotId: $feedlotId, processingFailureId: $processingFailureId) ${getFormattedQuery(
          params.fields
        )}
      }
      `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(
        statusCode || UNEXPECTED_ERROR_CODE,
        'services/unprocessedAnimals/unprocessedAnimalsQuery',
        errors
      )
    }
    return data.unprocessedAnimals
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/unprocessedAnimals/unprocessedAnimalsQuery', error)
  }
}
