import React from 'react'
import { Route, Switch } from 'react-router-dom'

// import AboutScreen from 'screens/AboutScreen'
// import HomeScreen from 'screens/HomeScreen'
import NotFoundScreen from 'screens/NotFoundScreen'
import FeedlotListScreen from 'screens/FeedlotListScreen'
import FeedlotScreen from 'screens/FeedlotScreen'
import PenScreen from 'screens/PenScreen'
import LotScreen from 'screens/LotScreen'
import WeighingScreen from 'screens/WeighingScreen'
import CustomerListScreen from 'screens/CustomerListScreen'
import CustomerScreen from 'screens/CustomerScreen'
import SlaughterhouseListScreen from 'screens/SlaughterhouseListScreen'
import SlaughterhouseScreen from 'screens/SlaughterhouseScreen'
import MonitoringScreen from 'screens/MonitoringScreen'

const App = () => (
  <Switch>
    <Route exact path='/' component={MonitoringScreen} />
    {/* <Route exact path='/home' component={HomeScreen} /> */}
    <Route exact path='/monitoring' component={MonitoringScreen} />
    {/* <Route exact path='/about' component={AboutScreen} /> */}
    <Route exact path='/feedlots' component={FeedlotListScreen} />
    <Route exact path='/feedlots/new' component={FeedlotScreen} />
    <Route exact path='/feedlots/:id' component={FeedlotScreen} />
    <Route exact path='/feedlots/:id/pens/:id' component={PenScreen} />
    <Route exact path='/feedlots/:id/pens/:id/lots/:id' component={LotScreen} />
    <Route exact path='/feedlots/:id/pens/new' component={PenScreen} />
    <Route exact path='/weighings/' component={WeighingScreen} />
    <Route exact path='/customers' component={CustomerListScreen} />
    <Route exact path='/customers/new' component={CustomerScreen} />
    <Route exact path='/customers/:id' component={CustomerScreen} />
    <Route exact path='/slaughterhouses' component={SlaughterhouseListScreen} />
    <Route exact path='/slaughterhouses/:id' component={SlaughterhouseScreen} />
    <Route component={NotFoundScreen} />
  </Switch>
)
export default App
