import React from 'react'

import { Props } from './types'

import Modal from 'components/widgets/Modal'
import UserPermissionsForm from 'components/forms/UserPermissionsForm'
import i18next from 'i18next'

const UserPermissionsModal: React.FC<Props> = ({ handleClose, isOpen, feedlotId, isNew }) => (
  <Modal
    handleClose={handleClose}
    open={isOpen}
    title={isNew ? i18next.t('user.registration') : i18next.t('default.general_information')}
  >
    <UserPermissionsForm feedlotId={feedlotId || 0} setUserPermissionsModalStatus={handleClose} isNew={isNew} />
  </Modal>
)
export default UserPermissionsModal
