import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Props } from './types'
import { ApplicationState } from 'store/types'

const PrivateRoute = (props: Props) => {
  const { component } = props
  const isAuthenticated = useSelector((state: ApplicationState) => state.auth.isAuthenticated)
  return (
    <Route
      render={routeProps =>
        isAuthenticated ? component : <Redirect to={{ pathname: '/login', state: { from: routeProps.location } }} />
      }
    />
  )
}
export default PrivateRoute
