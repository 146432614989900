import { put, call, select } from 'redux-saga/effects'
import _ from 'lodash'
import {
  FeedlotTypes,
  Feedlot,
  Pen,
  Lot,
  Equipment,
  EquipmentModel,
  FeedlotMonitoring,
  PenMonitoring,
  LotMonitoring
} from './types'
import { feedlotCreate, feedlotQuery, feedlotUpdate } from 'services/feedlot'
import { feedlotMonitoringQuery } from 'services/feedlotMonitoring'
import Exception from 'helpers/exception'
import { MainTypes } from '../main/types'
import {
  UNEXPECTED_ERROR_CODE,
  FEEDLOT_NOT_FOUND_OR_INACTIVE_ERROR,
  PEN_NOT_FOUND_OR_INACTIVE_ERROR
} from 'helpers/EventCodes'
import { workerCheckAuthentication } from '../auth/sagas'
import { AnyAction } from 'redux'
import { workerGetAddress } from 'store/address/sagas'
import { FeedlotQueryParams } from 'services/feedlot/types'
import { lotQuery, lotUpdate, lotCreate } from 'services/lot'
import { penQuery, penCreate, penUpdate } from 'services/pen'
import { LotQueryParams } from 'services/lot/types'
import { AddressTypes } from 'store/address/types'
import { equipmentQuery, equipmentUpdate, equipmentCreate } from 'services/equipment'
import { equipmentModelQuery } from 'services/equipmentModel'
import { EquipmentQueryParams } from 'services/equipment/types'

export function* workerGetFeedlot(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { feedlotLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    const feedlots = yield call(feedlotQuery, credentials.token, params)

    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: {
        selectedFeedlot: undefined,
        feedlots: [...feedlots],
        feedlotLoading: false,
        users: [],
        pens: [],
        equipment: [],
        lots: [],
        animals: []
      }
    })
    yield put({ type: AddressTypes.REDUCER_FETCH_ADDRESS_DATA, payload: { locations: [] } })
  } catch (error) {
    let message = null
    if (error instanceof Exception) message = error.getMessage()
    else message = new Exception(UNEXPECTED_ERROR_CODE, 'feedlot/sagas/workerGetFeedlot', error).getMessage()
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { feedlotLoading: false } })
    yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
  }
}

export function* workerSetSelectedFeedlot(action: AnyAction) {
  const store = yield select(s => s)
  const { feedlotId } = action.payload
  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { feedlotLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    let selectedFeedlot: Feedlot | undefined

    if (feedlotId) {
      const params: FeedlotQueryParams = { args: { id: feedlotId } }
      const feedlotList = yield call(feedlotQuery, credentials.token, params)

      const mainStore = store.main
      const { history } = mainStore
      if (feedlotList.length) selectedFeedlot = feedlotList[0]
      else {
        history.push('/feedlots')
        throw new Exception(FEEDLOT_NOT_FOUND_OR_INACTIVE_ERROR, 'feedlots/sagas/workerSetSelectedFeedlot')
      }
    }
    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: { selectedFeedlot: selectedFeedlot ? { ...selectedFeedlot } : undefined, feedlotLoading: false }
    })
    if (selectedFeedlot?.cityId) {
      const payload = { params: { args: { cityId: selectedFeedlot.cityId } } }
      yield call(workerGetAddress, { payload })
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // let selectedCountryId: number | undefined
    // let selectedRegionId: number | undefined
    // let selectedCityId: number | undefined
    // if (selectedFeedlot) {
    //   selectedCountryId = selectedFeedlot.countryId
    //   selectedRegionId = selectedFeedlot.stateId
    //   selectedCityId = selectedFeedlot.cityId
    // }
    // const payload = { selectedCountryId, selectedRegionId, selectedCityId }
    // yield call(workerSetSelectedCountry, { payload })
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'feedlot/sagas/workerSetSelectFeedlot', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { feedlotLoading: false } })
  }
}

export function* workerSaveFeedlot(action: any) {
  const { args } = action.payload
  const { feedlot, customerOwner } = args
  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { feedlotLoading: true } })
    yield call(workerCheckAuthentication)

    const store = yield select(s => s)

    const authStore = store.auth
    const { credentials } = authStore

    const mainStore = store.main
    const { history } = mainStore

    const feedlotStore = store.feedlot
    const { feedlots } = feedlotStore
    if (!feedlot.id) {
      feedlot.id = yield call(feedlotCreate, credentials.token, args)
      feedlot.customer = { id: customerOwner }
      feedlots.push(feedlot)
      history.push(`/feedlots/${feedlot.id}`)
    } else {
      yield call(feedlotUpdate, credentials.token, args)
      feedlot.customer = { id: customerOwner }
      const index = _.findIndex(feedlots, (f: Feedlot) => f.id === feedlot.id)
      feedlots[index] = feedlot
    }

    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: { feedlots: [...feedlots], selectedFeedlot: { ...feedlot }, feedlotLoading: false }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'feedlot/sagas/workerSaveFeedlot', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { feedlotLoading: false } })
  }
}

export function* workerGetFeedlotMonitoring(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { feedlotMonitoringLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    let feedlotsMonitoring = []
    if (!feedlotsMonitoring.length) feedlotsMonitoring = yield call(feedlotMonitoringQuery, credentials.token, params)

    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: {
        feedlotsMonitoring: [...feedlotsMonitoring],
        feedlotMonitoringLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) message = error.getMessage()
    else message = new Exception(UNEXPECTED_ERROR_CODE, 'feedlot/sagas/workerGetFeedlotMonitoring', error).getMessage()
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { feedlotMonitoringLoading: false } })
    yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
  }
}

export function* workerSaveFeedlotMonitoring(action: any) {
  const { args } = action.payload
  const { feedlotMonitoring } = args
  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { feedlotMonitoringLoading: true } })
    yield call(workerCheckAuthentication)

    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: { feedlotMonitoring: [...feedlotMonitoring], feedlotMonitoringLoading: false }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'feedlot/sagas/workerSaveFeedlotMonitoring', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { feedlotMonitoringLoading: false } })
  }
}

export function* workerGetPen(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { penLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    const pens = yield call(penQuery, credentials.token, params)

    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { pens: [...pens], penLoading: false } })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'feedlot/sagas/workerGetPen', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { penLoading: false } })
  }
}

export function* workerSetSelectedPen(action: AnyAction) {
  const store = yield select(s => s)
  const { params } = action.payload
  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { penLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    let selectedPen: Pen | undefined

    if (params.args?.id) {
      const penList = yield call(penQuery, credentials.token, params)

      const mainStore = store.main
      const { history } = mainStore
      if (penList.length) selectedPen = penList[0]
      else {
        history.goBack()
        throw new Exception(PEN_NOT_FOUND_OR_INACTIVE_ERROR, 'feedlots/sagas/workerSetSelectedPen')
      }
    }
    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: { selectedPen: selectedPen ? { ...selectedPen } : undefined, penLoading: false }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'feedlot/sagas/workerSetSelectedPen', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { penLoading: false } })
  }
}

export function* workerSavePen(action: AnyAction) {
  const { args } = action.payload
  try {
    const store = yield select(s => s)
    const mainStore = store.main
    const authStore = store.auth
    const feedlotStore = store.feedlot

    const { history } = mainStore
    const { credentials } = authStore
    const { pens } = feedlotStore
    const { pen } = args

    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { penLoading: true } })
    yield call(workerCheckAuthentication)
    if (!pen.id) {
      pen.id = yield call(penCreate, credentials.token, args)
      pens.push(pen)
      history.push(`/feedlots/${pen.feedlotId}/pens/${pen.id}`)
    } else {
      yield call(penUpdate, credentials.token, args)
      const index = _.findIndex(pens, (p: Pen) => p.id === pen.id)
      pens[index] = pen
    }
    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: { pens: [...pens], selectedPen: { ...pen }, penLoading: false }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'feedlot/sagas/workerSavePen', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { penLoading: false } })
  }
}

export function* workerGetEquipment(action: AnyAction) {
  //MUDAR
  const { params } = action.payload
  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { equipmentLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    const equipments = yield call(equipmentQuery, credentials.token, params)
    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: { equipments: [...equipments], equipmentLoading: false }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'feedlot/sagas/workerGetEquipment', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { equipmentLoading: false } })
  }
}

export function* workerSetSelectedEquipment(action: AnyAction) {
  const store = yield select(s => s)
  const { equipmentId } = action.payload

  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { equipmentLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    let selectedEquipment: Equipment | undefined

    if (equipmentId) {
      const params: EquipmentQueryParams = { args: { id: equipmentId } }
      const equipmentList = yield call(equipmentQuery, credentials.token, params)

      const mainStore = store.main
      const { history } = mainStore
      if (equipmentList.length) selectedEquipment = equipmentList[0]
      else {
        history.goBack()
        throw new Exception('', 'feedlots/sagas/workerSetSelectedEquipment')
      }
    }
    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: {
        selectedEquipment: selectedEquipment ? { ...selectedEquipment } : undefined,
        selectedEquipmentModel: selectedEquipment?.equipmentModel ? { ...selectedEquipment.equipmentModel } : undefined,
        equipmentLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'feedlot/sagas/workerSetSelectedEquipment', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { equipmentLoading: false } })
  }
}

export function* workerSaveEquipment(action: AnyAction) {
  const { args, equipmentModel } = action.payload
  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { equipmentLoading: true } })
    yield call(workerCheckAuthentication)

    const store = yield select(s => s)
    const authStore = store.auth
    const feedlotStore = store.feedlot

    const { equipments } = feedlotStore
    const { equipment } = args
    const { credentials } = authStore

    if (equipment.id) {
      yield call(equipmentUpdate, credentials.token, args)
      const index = _.findIndex(equipments, (e: Equipment) => e.id === equipment.id)
      equipment.equipmentModel = equipmentModel
      equipments[index] = equipment
    } else {
      equipment.id = yield call(equipmentCreate, credentials.token, args)
      equipment.equipmentModel = equipmentModel
      equipments.push(equipment)
    }

    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: { equipments: [...equipments], selectedEquipment: { ...equipment }, equipmentLoading: false }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'equipment/sagas/workerSaveEquipment', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { equipmentLoading: false } })
  }
}

export function* workerGetEquipmentModel(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { equipmentModelLoading: true } })

    yield call(workerCheckAuthentication)
    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    const equipmentModels = yield call(equipmentModelQuery, credentials.token, params)
    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: { equipmentModelLoading: false, equipmentModels }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'feedlot/sagas/workerGetEquipmentModel', error)
      console.log(ex.getMessage().getText())
    }
  }
}

export function* workerSetSelectedEquipmentModel(action: AnyAction) {
  const store = yield select(s => s)
  const { equipmentModelId } = action.payload

  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { equipmentModelLoading: true } })
    yield call(workerCheckAuthentication)

    const feedlotStore = store.feedlot
    const { equipmentModels } = feedlotStore
    let selectedEquipmentModel: EquipmentModel | undefined

    if (equipmentModelId) {
      const mainStore = store.main
      const { history } = mainStore
      const index = _.findIndex(equipmentModels, (em: EquipmentModel) => em.id === equipmentModelId)
      if (index !== -1) selectedEquipmentModel = equipmentModels[index]
      else {
        history.goBack()
        throw new Exception('', 'feedlots/sagas/workerSetSelectedEquipmentModel')
      }
    }
    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: {
        selectedEquipmentModel: selectedEquipmentModel ? { ...selectedEquipmentModel } : undefined,
        equipmentModelLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'feedlot/sagas/workerSetSelectedEquipmentModel', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { equipmentModelLoading: false } })
  }
}

export function* workerGetLot(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { lotLoading: true } })

    yield call(workerCheckAuthentication)
    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    const lots = yield call(lotQuery, credentials.token, params)
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { lotLoading: false, lots } })
  } catch (error) {
    let message = null
    if (error instanceof Exception) message = error.getMessage()
    else message = new Exception(UNEXPECTED_ERROR_CODE, 'lot/sagas/workerGetLot', error).getMessage()
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { lotLoading: false } })
    yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
  }
}

export function* workerSetSelectedLot(action: AnyAction) {
  const store = yield select(s => s)
  const { lotId } = action.payload

  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { lotLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    let selectedLot: Lot | undefined

    if (lotId) {
      const params: LotQueryParams = { args: { id: lotId } }
      const lotList = yield call(lotQuery, credentials.token, params)

      const mainStore = store.main
      const { history } = mainStore
      if (lotList.length) selectedLot = lotList[0]
      else {
        history.goBack()
        throw new Exception('', 'feedlots/sagas/workerSetSelectedLot')
      }
    }
    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: { selectedLot: selectedLot ? { ...selectedLot } : undefined, lotLoading: false }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'feedlot/sagas/workerSetSelectedLot', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { lotLoading: false } })
  }
}

export function* workerSaveLot(action: AnyAction) {
  const { args } = action.payload
  try {
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { lotLoading: true } })
    yield call(workerCheckAuthentication)

    const store = yield select(s => s)
    const authStore = store.auth
    const feedlotStore = store.feedlot

    const { lots, selectedLot, feedlotsMonitoring } = feedlotStore
    const feedlotsMonitoringCp = [...feedlotsMonitoring]
    const { lot } = args
    const { credentials } = authStore

    if (lot.id) {
      yield call(lotUpdate, credentials.token, args)
      const index = _.findIndex(lots, (l: Lot) => l.id === lot.id)
      lot['animalsCount'] = selectedLot?.animalsCount
      lot['animalsDelayedWeighingCount'] = selectedLot?.animalsDelayedWeighingCount
      lots[index] = lot
      // When updated lot register status on home page updates on store too
      _.map(feedlotsMonitoringCp, f => {
        _.map(f.pens, p => {
          _.map(p.lots, l => {
            console.log(l.id)
            if (l.id === lot.id) {
              f.lotsToValidate = lot?.registerStatus ? f.lotsToValidate - 1 : f.lotsToValidate + 1
              l.registerStatus = lot?.registerStatus
            }
          })
        })
      })
    } else {
      lot.id = yield call(lotCreate, credentials.token, args)
      lots.push(lot)
    }

    yield put({
      type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
      payload: {
        lots: [...lots],
        selectedLot: { ...lot },
        lotLoading: false,
        feedlotsMonitoring: [...feedlotsMonitoringCp]
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'lot/sagas/workerSaveLot', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { lotLoading: false } })
  }
}
