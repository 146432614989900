import { takeLatest, all } from 'redux-saga/effects'
import { UserTypes } from './types'
import {
  workerGetUser,
  workerSetSelectedUser,
  workerSaveUser,
  workerGetUserEmails,
  workerSaveUserPermissions
} from './sagas'

export function* watcherGetUser() {
  yield takeLatest(UserTypes.ACTION_GET_USER, workerGetUser)
}

export function* watcherGetUserEmails() {
  yield takeLatest(UserTypes.ACTION_GET_USER_EMAILS, workerGetUserEmails)
}

export function* watcherSetSelectedUser() {
  yield takeLatest(UserTypes.ACTION_SET_SELECTED_USER, workerSetSelectedUser)
}
export function* watcherSaveUser() {
  yield takeLatest(UserTypes.ACTION_SAVE_USER, workerSaveUser)
}

export function* watcherSaveUserPermissions() {
  yield takeLatest(UserTypes.ACTION_SAVE_USER_PERMISSIONS, workerSaveUserPermissions)
}

export default function* authRootSaga() {
  yield all([
    watcherGetUser(),
    watcherGetUserEmails(),
    watcherSetSelectedUser(),
    watcherSaveUser(),
    watcherSaveUserPermissions()
  ])
}
