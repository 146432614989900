/* eslint-disable no-loop-func */
import { Grid } from '@material-ui/core'
import React from 'react'
import i18next from 'i18next'
import { Breed, Coat, Gender } from 'store/animal/types'
import _ from 'lodash'
// import moment from 'moment'

export const formatDataToSave = (
  data: any[],
  feedlotId: number | undefined,
  penId: number | undefined,
  lotId: number
) => {
  const finalData: any[] = []

  let row = null
  _.map(data, d => {
    const additionalInfo: Record<string, any> = {}

    for (const key in d) {
      d[key] = d[key] === '' || d[key] === undefined ? null : d[key]

      if (key.includes('additionalInfo')) {
        const newKey: string = key.slice(15)
        if (key) additionalInfo[newKey] = d[key]
        d = _.omit(d, key)
      }
    }
    if (d !== null) {
      d.feedlotId = feedlotId
      d.penId = penId
      d.lotId = lotId
      d.active = d.active === true ? true : false
      d.age = d.age ? Number(d.age) : null
      d.breedId = d.breedId ? Number(d.breedId) : null
      d.coatId = d.coatId ? Number(d.coatId) : null
      d.genderId = d.genderId ? Number(d.genderId) : null
      d.initialWeight = d.initialWeight ? Number(d.initialWeight) : null
      d.finalWeight = d.finalWeight ? Number(d.finalWeight) : null
      d.eccHandling = d.initialEcc ? Number(d.initialEcc) : null

      row = _.omit(d, 'initialEcc')
      row = _.omit(row, 'breed')
      row = _.omit(row, 'coat')
      row = _.omit(row, 'gender')
      row.additionalField = additionalInfo
      finalData.push(_.omit(row, 'tableData'))
    }
  })
  return finalData
}

export const generalRender = (rowData: any, type: string) => {
  if (type === 'active') {
    return (
      <Grid className={`field ${rowData.active ? i18next.t('field.active') : i18next.t('field.inactive')}`}>
        {rowData.active ? i18next.t('default.active') : i18next.t('default.inactive')}
      </Grid>
    )
  }
  if (rowData[type]) {
    // if (type === 'endDate') {
    //   rowData[type] = moment(rowData[type]).format('DD/MM/YYYY HH:mm:ss')
    // }
    if (type === 'breed' || type === 'coat' || type === 'gender') {
      return <Grid>{rowData[type]?.description || i18next.t('table.not_registered')}</Grid>
    }
    return <Grid>{rowData[type] || i18next.t('table.not_registered')}</Grid>
  }
}

export const getTitle = (column: string) => {
  if (column.includes('additionalInfo.')) {
    return column.slice(15)
  }
  switch (column) {
    case 'initialWeight':
      return i18next.t('field.initialWeight')
    // case 'endDate':
    //   return i18next.t('field.endDate')
    case 'initialEcc':
      return i18next.t('field.initialEcc')
    default:
      return i18next.t(column.toUpperCase())
  }
}

export const formatData = (
  animals: any[],
  breeds: Breed[] | undefined,
  coats: Coat[] | undefined,
  genders: Gender[] | undefined,
  isFileModalOpen: boolean
) => {
  const list = animals
  let breed = null
  let coat = null
  let gender = null

  for (let i = 0; i < list.length; i++) {
    _.map(breeds, b => {
      if (
        b.description
          ?.replace(/\s/g, '')
          ?.toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '') ===
        list[i]['breed']
          ?.replace(/\s/g, '')
          ?.toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '')
      ) {
        breed = b
        list[i]['breedId'] = b.id
      }
    })
    if (list[i]['breedId'] === null || list[i]['breedId'] === undefined || isNaN(list[i]['breedId'])) {
      const noIdentified = breeds?.find(
        ({ description }) =>
          description
            ?.replace(/\s/g, '')
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') === 'naoespecificado' ||
          description
            ?.replace(/\s/g, '')
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') === 'naoinformado'
      )
      breed = noIdentified
      list[i]['breedId'] = noIdentified?.id
    }
    _.map(coats, c => {
      if (
        c.description
          ?.replace(/\s/g, '')
          ?.toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '') ===
        list[i]['coat']
          ?.replace(/\s/g, '')
          ?.toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '')
      ) {
        coat = c
        list[i]['coatId'] = c.id
      }
    })
    if (list[i]['coatId'] === null || list[i]['coatId'] === undefined || isNaN(list[i]['coatId'])) {
      const noIdentified = coats?.find(
        ({ description }) =>
          description
            ?.replace(/\s/g, '')
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') === 'naoespecificado' ||
          description
            ?.replace(/\s/g, '')
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') === 'naoinformado'
      )
      list[i].coat = noIdentified
      list[i]['coatId'] = noIdentified?.id
    }
    _.map(genders, g => {
      if (
        g.description
          ?.replace(/\s/g, '')
          ?.toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '') ===
        list[i]['gender']
          ?.replace(/\s/g, '')
          ?.toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '')
      ) {
        gender = g
        list[i]['genderId'] = g.id
      }
    })
    if (list[i]['genderId'] === null || list[i]['genderId'] === undefined || isNaN(list[i]['genderId'])) {
      const noIdentified = genders?.find(
        ({ description }) =>
          description
            ?.replace(/\s/g, '')
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') === 'naoespecificado' ||
          description
            ?.replace(/\s/g, '')
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') === 'naoinformado'
      )
      gender = noIdentified
      list[i]['genderId'] = noIdentified?.id
    }
    list[i].breed = breed
    list[i].coat = coat
    list[i].gender = gender

    list[i].active = list[i].active === true ? true : false
    // list[i].endDate = list[i].endDate ?  moment(list[i].endDate).format('DD/MM/YYYY HH:mm:ss'): ''

    for (const key in list[i]) {
      list[i][key] = list[i][key] === undefined || list[i][key] === '' || list[i][key] === '' ? null : list[i][key]

      if (key === 'age' || key === 'initialWeight' || key === 'finalWeight' || (key === 'initialEcc' && list[i][key])) {
        if (isNaN(Number(list[i][key]))) {
          if (isFileModalOpen) {
            alert(
              'Value ' +
                list[i][key] +
                ' not allowed in ' +
                key.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase() +
                ' field.'
            )
          }
          list[i][key] = null
        }
      }
    }
  }
  return list
}
