import React from 'react'

import { Props } from './types'

import Modal from 'components/widgets/Modal'
import UserForm from 'components/forms/UserForm'
import i18next from 'i18next'

const UserModal: React.FC<Props> = ({ handleClose, isOpen, customerId, isNew }) => (
  <Modal
    handleClose={handleClose}
    open={isOpen}
    title={isNew ? i18next.t('user.registration') : i18next.t('default.general_information')}
  >
    <UserForm customerId={customerId || 0} setUserModalStatus={handleClose} isNew={isNew} />
  </Modal>
)
export default UserModal
