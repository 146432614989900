import { takeLatest, all } from 'redux-saga/effects'
import { workerGetUnprocessedAnimals, workerSetSelectedUnprocessedAnimals } from './sagas'
import { UnprocessedAnimalsTypes } from './types'

export function* watcherGetUnprocessedAnimals() {
  yield takeLatest(UnprocessedAnimalsTypes.ACTION_GET_UNPROCESSED_ANIMALS, workerGetUnprocessedAnimals)
}
export function* watcherSetSelectedUnprocessedAnimals() {
  yield takeLatest(UnprocessedAnimalsTypes.ACTION_SET_SELECTED_UNPROCESSED_ANIMALS, workerSetSelectedUnprocessedAnimals)
}
export default function* authRootSaga() {
  yield all([watcherGetUnprocessedAnimals(), watcherSetSelectedUnprocessedAnimals()])
}
