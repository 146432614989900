import { UNEXPECTED_ERROR_CODE, UNPROCESSED_ANIMALS_NOT_FOUND_ERROR } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import { AnyAction } from 'redux'
import { put, select, call } from 'redux-saga/effects'
import { unprocessedAnimalsQuery } from 'services/animal/unprocessedAnimals'
import { UnprocessedAnimalsQueryParams } from 'services/animal/unprocessedAnimals/types'
import { workerCheckAuthentication } from 'store/auth/sagas'
import { MainTypes } from 'store/main/types'
import { UnprocessedAnimal, UnprocessedAnimalsTypes } from './types'

export function* workerGetUnprocessedAnimals(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({
      type: UnprocessedAnimalsTypes.REDUCER_FETCH_UNPROCESSED_ANIMALS_DATA,
      payload: { unprocessedAnimalsLoading: true }
    })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    const unprocessedAnimals = yield call(unprocessedAnimalsQuery, credentials.token, params)

    yield put({
      type: UnprocessedAnimalsTypes.REDUCER_FETCH_UNPROCESSED_ANIMALS_DATA,
      payload: {
        selectedUnprocessedAnimals: undefined,
        unprocessedAnimals: [...unprocessedAnimals],
        unprocessedAnimalsLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) message = error.getMessage()
    else message = new Exception(UNEXPECTED_ERROR_CODE, 'weghing/sagas/workerGetUnprocessedAnimals', error).getMessage()
    yield put({
      type: UnprocessedAnimalsTypes.REDUCER_FETCH_UNPROCESSED_ANIMALS_DATA,
      payload: { unprocessedAnimalsLoading: false }
    })
    yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
  }
}

export function* workerSetSelectedUnprocessedAnimals(action: AnyAction) {
  const store = yield select(s => s)
  const { lotId } = action.payload
  try {
    yield put({
      type: UnprocessedAnimalsTypes.REDUCER_FETCH_UNPROCESSED_ANIMALS_DATA,
      payload: { unprocessedAnimalsLoading: true }
    })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    let selectedUnprocessedAnimals: UnprocessedAnimal[] | undefined

    if (lotId) {
      const params: UnprocessedAnimalsQueryParams = { args: { id: lotId } }
      const unprocessedAnimalsList = yield call(unprocessedAnimalsQuery, credentials.token, params)

      const mainStore = store.main
      const { history } = mainStore
      if (!unprocessedAnimalsList.length) {
        history.push('/unprocessedAnimals')
        throw new Exception(
          UNPROCESSED_ANIMALS_NOT_FOUND_ERROR,
          'unprocessedAnimals/sagas/workerSetSelectedUnprocessedAnimals'
        )
      }
    }
    yield put({
      type: UnprocessedAnimalsTypes.REDUCER_FETCH_UNPROCESSED_ANIMALS_DATA,
      payload: {
        selectedUnprocessedAnimals: selectedUnprocessedAnimals ? { ...selectedUnprocessedAnimals } : undefined,
        unprocessedAnimalsLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'weghing/sagas/workerSaveUnprocessedAnimals', error)
      console.log(ex.getMessage().getText())
    }
    yield put({
      type: UnprocessedAnimalsTypes.REDUCER_FETCH_UNPROCESSED_ANIMALS_DATA,
      payload: { unprocessedAnimalsLoading: false }
    })
  }
}
