import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from 'store/types'
import Input from 'components/widgets/Input'
import { SubmitHandler } from '@unform/core'
import { Pen } from 'store/feedlot/types'
import { Form } from '@unform/web'
import { Props } from './types'
import * as Yup from 'yup'
import _ from 'lodash'
import { savePen } from 'store/feedlot/actions'
import Button from 'components/widgets/Button'
import Loading from 'components/widgets/Loading'
import Select from 'components/widgets/Select'
import { statusOptions } from 'data'
import { useTranslation } from 'react-i18next'

const PenForm: React.FC<Props> = ({ feedlotId }) => {
  const { t } = useTranslation()
  const formRef: any = useRef(null)
  const dispatch = useDispatch()

  const selectedPen = useSelector((state: ApplicationState) => state.feedlot.selectedPen)
  const loading = useSelector((state: ApplicationState) => state.feedlot.penLoading)

  const buttonLabel = selectedPen ? 'save' : 'add'

  const handleSubmit: SubmitHandler<Pen> = async data => {
    const pen: any = data
    pen.id = selectedPen?.id
    pen.feedlotId = feedlotId

    _.map(Object.entries(pen), ([key, value]) => (pen[key] = value === '' ? null : value))

    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        code: Yup.string().required(() => t('field.required'))
      })
      await schema.validate(pen, { abortEarly: false })
      dispatch(savePen({ pen }))
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }
  return (
    <div className='container'>
      {loading ? (
        <Loading />
      ) : (
        <Form ref={formRef} initialData={selectedPen} onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-3'>
              <Input name='code' label={t('field.code')} onFocus={() => formRef.current?.setFieldError('code', '')} />
            </div>
            <div className='col-3'>
              <Select
                name='status'
                label={t('field.status')}
                defaultOptions={statusOptions}
                selectedValue={selectedPen?.status || 1}
              />
            </div>
            <div className='col-2 mtop-1'>
              <Button label={buttonLabel} />
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}

export default PenForm
