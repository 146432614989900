import { put, call } from 'redux-saga/effects'
import { authRequest, validateToken } from 'services/auth'
import { saveAuthToStorage, getAuthFromStorage, removeAuthFromStorage } from 'storage/auth'
import Exception from 'helpers/exception'
import { UNEXPECTED_ERROR_CODE, SIGN_IN_AGAIN } from 'helpers/EventCodes'
import { HTTP_401_UNAUTHORIZED } from 'helpers/http/Codes'

import { AuthTypes } from './types'
import { FeedlotTypes } from '../feedlot/types'
import { MainTypes } from '../main/types'
import { CustomerTypes } from '../customer/types'
import { Credentials } from 'services/auth/types'
import { AnyAction } from 'redux'
import { AddressTypes } from 'store/address/types'
import { AnimalTypes } from 'store/animal/types'
import { SlaughterhouseTypes } from 'store/slaughterhouse/types'
import { UserTypes } from 'store/user/types'

export function* workerAttemptSignIn(action: AnyAction) {
  yield put({ type: AuthTypes.REDUCER_FETCH_AUTH_DATA, payload: { attemptingSignIn: true } })
  try {
    const { payload } = action
    const { params } = payload
    const credentials: Credentials = params.args
    credentials.token = (yield call(authRequest, params)).token

    yield call(saveAuthToStorage, credentials)
    yield put({
      type: AuthTypes.REDUCER_FETCH_AUTH_DATA,
      payload: { credentials, attemptingSignIn: false, isAuthenticated: true }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()

      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'sagas/auth/sagas/workerAttemptSignIn', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: AuthTypes.REDUCER_FETCH_AUTH_DATA, payload: { attemptingSignIn: false } })
  }
}

export function* workerCheckAuthentication() {
  try {
    yield put({ type: AuthTypes.REDUCER_FETCH_AUTH_DATA, payload: { checkingAuthentication: true } })
    const credentials = getAuthFromStorage()
    if (credentials) {
      yield call(validateToken, credentials.token)
    }

    yield put({
      type: AuthTypes.REDUCER_FETCH_AUTH_DATA,
      payload: {
        credentials,
        isAuthenticated: credentials !== null,
        attemptingSignIn: false,
        checkingAuthentication: false
      }
    })
  } catch (error) {
    if (error instanceof Exception) {
      if (error.getCode() === HTTP_401_UNAUTHORIZED) {
        removeAuthFromStorage()
        yield put({
          type: AuthTypes.REDUCER_FETCH_AUTH_DATA,
          payload: {
            attemptingSignIn: false,
            isAuthenticated: false,
            checkingAuthentication: false
          }
        })
        throw new Exception(SIGN_IN_AGAIN, 'workerCheckAuthentication', '')
      }
    } else throw new Exception(UNEXPECTED_ERROR_CODE, 'sagas/auth/sagas/workerCheckAuthentication', error)
  }
}

export function* workerSignOut() {
  try {
    yield call(removeAuthFromStorage)
    yield put({ type: AuthTypes.REDUCER_CLEAR_AUTH_DATA })
    yield put({ type: AddressTypes.REDUCER_CLEAR_ADDRESS_DATA })
    yield put({ type: AnimalTypes.REDUCER_CLEAR_ANIMAL_DATA })
    yield put({ type: CustomerTypes.REDUCER_CLEAR_CUSTOMER_DATA })
    yield put({ type: SlaughterhouseTypes.REDUCER_CLEAR_SLAUGHTERHOUSE_DATA })
    yield put({ type: UserTypes.REDUCER_CLEAR_USER_DATA })
    yield put({ type: FeedlotTypes.REDUCER_CLEAR_FEEDLOT_DATA })
    yield put({ type: MainTypes.REDUCER_CLEAR_MAIN_DATA })
    yield call(workerCheckAuthentication)
  } catch (error) {
    const ex = new Exception(UNEXPECTED_ERROR_CODE, 'sagas/auth/sagas/workerSignOut', error)
    console.log(ex)
  }
}
