import { UnprocessedAnimalsState, UnprocessedAnimalsTypes } from './types'

const INITIAL_STATE: UnprocessedAnimalsState = {
  unprocessedAnimals: [],
  unprocessedAnimalsLoading: false
}

export default (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case UnprocessedAnimalsTypes.REDUCER_CLEAR_UNPROCESSED_ANIMALS_DATA:
      return { ...state, ...INITIAL_STATE, checkingAuthentication: false }
    case UnprocessedAnimalsTypes.REDUCER_FETCH_UNPROCESSED_ANIMALS_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
