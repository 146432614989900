import { WeighingTypes, WeighingState } from './types'

const INITIAL_STATE: WeighingState = {
  processedWeighings: [],
  weighingLoading: false
}

export default (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case WeighingTypes.REDUCER_CLEAR_WEIGHING_DATA:
      return { ...state, ...INITIAL_STATE, checkingAuthentication: false }
    case WeighingTypes.REDUCER_FETCH_WEIGHING_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
