import React, { useState, useEffect, useRef } from 'react'

import './styles.scss'
import { Props } from './types'
import { useField } from '@unform/core'
import { useTranslation } from 'react-i18next'

const Chip: React.FC<Props> = ({ name, label }) => {
  const { t } = useTranslation()
  const chipRef = useRef(null)
  const { fieldName, defaultValue, registerField } = useField(name)

  const [selected, setSelected] = useState<boolean>(defaultValue || false)

  useEffect(() => {
    registerField({ name: fieldName, path: 'checked', ref: chipRef.current })
  }, [fieldName, registerField])

  return (
    <button
      id={fieldName}
      type='button'
      className={`chip-${selected ? 'checked' : 'unchecked'}`}
      onClick={() => setSelected(!selected)}
    >
      <label htmlFor={fieldName}>{t(label)}</label>
      <input ref={chipRef} type='checkbox' checked={selected} onChange={() => undefined} />
    </button>
  )
}
export default Chip
