import { action } from 'typesafe-actions'
import { EconomicTypes, CategoryPrice } from './types'
import {
  PriceQueryParams,
  PriceMutationArgs,
  CurrencyQueryParams,
  WeightUnityQueryParams
} from 'services/economic/types'

export const getPrice = (params?: PriceQueryParams) => action(EconomicTypes.ACTION_GET_PRICE, { params })
export const setSelectedPrice = (selectedPrice?: CategoryPrice) =>
  action(EconomicTypes.ACTION_SET_SELECTED_PRICE, { selectedPrice })
export const savePrice = (args: PriceMutationArgs) => action(EconomicTypes.ACTION_SAVE_PRICE, { args })

export const getCurrency = (params?: CurrencyQueryParams) => action(EconomicTypes.ACTION_GET_CURRENCY, { params })
export const setSelectedCurrency = (currencyId?: number) =>
  action(EconomicTypes.ACTION_SET_SELECTED_CURRENCY, { currencyId })

export const getWeightUnity = (params?: WeightUnityQueryParams) =>
  action(EconomicTypes.ACTION_GET_WEIGHTUNITY, { params })
export const setSelectedWeightUnity = (weightUnityId?: number) =>
  action(EconomicTypes.ACTION_SET_SELECTED_CURRENCY, { weightUnityId })
