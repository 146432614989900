export type MainStore = {
  readonly mainLoading: boolean
  readonly isMainSidebarOpened: boolean
  readonly isSecondarySidebarOpened: boolean
  readonly isToastOpened: boolean
  readonly toastMessage: string
  readonly defaultTheme: string
  readonly history: any
}

export enum MainTypes {
  REDUCER_FETCH_MAIN_DATA = 'REDUCER_FETCH_MAIN_DATA',
  REDUCER_TOGGLE_MAIN_SIDEBAR = 'REDUCER_TOGGLE_MAIN_SIDEBAR',
  REDUCER_TOGGLE_SECONDARY_SIDEBAR = 'REDUCER_TOGGLE_SECONDARY_SIDEBAR',
  REDUCER_TOGGLE_TOAST = 'REDUCER_TOGGLE_TOAST',
  REDUCER_SET_THEME = 'REDUCER_SET_THEME',
  REDUCER_CLEAR_MAIN_DATA = 'REDUCER_CLEAR_MAIN_DATA',
  RUN_BEEFTRADER = 'RUN_BEEFTRADER',
  ACTION_RUN_BEEFTRADER = 'ACTION_RUN_BEEFTRADER'
}
