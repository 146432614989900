import React from 'react'
import { Props } from './types'

import Modal from 'components/widgets/Modal'
import './styles.scss'
import DisableAnimalFileList from 'components/lists/DisableAnimalFileList'

const DisableAnimalFileModal: React.FC<Props> = ({ isOpen, feedlotId, handleClose, selectedDisableAnimalsFile }) => {
  const close = () => {
    handleClose()
  }

  return (
    <Modal handleClose={handleClose} open={isOpen} title={'animal.disable_animals_list'}>
      <DisableAnimalFileList
        feedlotId={feedlotId}
        setModalStatus={close}
        selectedDisableAnimalsFile={selectedDisableAnimalsFile}
      />
    </Modal>
  )
}

export default DisableAnimalFileModal
