import { FeedlotTypes, FeedlotState } from './types'

const INITIAL_STATE: FeedlotState = {
  selectedFeedlot: undefined,
  feedlots: [],
  feedlotLoading: false,
  feedlotsMonitoring: [],
  feedlotMonitoringLoading: false,
  userLoading: false,
  pens: [],
  penLoading: false,
  equipments: [],
  equipmentLoading: false,
  equipmentModels: [],
  selectedEquipmentModel: undefined,
  equipmentModelLoading: false,
  lots: [],
  lotLoading: false
}

export default (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case FeedlotTypes.REDUCER_CLEAR_FEEDLOT_DATA:
      return { ...state, ...INITIAL_STATE, checkingAuthentication: false }
    case FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
