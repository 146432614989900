/**
 * @class
 * Classe para mensagens de aviso ao usuário
 *
 */

export default class Message {
  /**
   * @constructs
   * @param {string} type
   * @param {string} title
   * @param {string} text
   * @param {string} alertMode
   * @param {string} source
   * @param {string} additionalInfo
   */
  type: string

  title: string

  text: string

  alertMode: string

  source: string

  additionalInfo?: string | unknown

  /**
   * @constant
   * Constantes de tipos de mensagens
   */
  static INFO = 'info'

  static WARN = 'warn'

  static ERROR = 'error'

  /**
   * @constant
   * Constantes de tipos de alerta
   */
  static INNER = 'inner'

  static MODAL = 'modal'

  static SNACK = 'snack'

  constructor(
    type: string,
    title: string,
    text: string,
    alertMode: string,
    source: string,
    additionalInfo?: string | unknown
  ) {
    this.type = type
    this.title = title
    this.text = text
    this.alertMode = alertMode
    this.source = source
    this.additionalInfo = additionalInfo
  }

  getType = (): string => this.type

  getTitle = (): string => this.title

  getText = (): string => this.text

  getSource = (): string => this.source

  getAdditionalInfo = () => this.additionalInfo

  getAlertMode = () => this.alertMode

  setAlertMode = (mode: string) => {
    this.alertMode = mode
  }
}
