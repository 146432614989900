import gql from 'graphql-tag'
import { PenQueryParams, PenCreateArgs, PenUpdateArgs } from './types'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import graphql from 'services'
import { Pen } from 'store/feedlot/types'
import getFormattedQuery from 'helpers/getFormattedQuery'

export const penQuery = async (token: string, params: PenQueryParams): Promise<Pen[]> => {
  if (!params.fields) params.fields = { id: true, code: true, status: true }
  const QUERY = gql`
    query QUERY($feedlotId: Int, $id: Int) {
      pen(feedlotId: $feedlotId, id: $id) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/pen/penQuery', errors)
    }
    return data.pen
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/pen/penQuery', error)
  }
}

export const penCreate = async (token: string, params: PenCreateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($pen: CreatePen!) {
      createPen(pen: $pen)
    }
  `

  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response

    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/graphql/pen/penCreate', errors)
    }
    return data.createPen
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/graphql/pen/penCreate', error)
  }
}

export const penUpdate = async (token: string, params: PenUpdateArgs) => {
  const MUTATION = gql`
    mutation Mutation($pen: UpdatePen!) {
      updatePen(pen: $pen)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const statusCode = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/graphql/pne/penUpdate', errors)
    }
    return data.updatePen
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/graphql/pen/updatePen', error)
  }
}
