import { put, call, select } from 'redux-saga/effects'
import { CustomerTypes, Customer } from './types'
import { customerQuery, customerCreate, customerUpdate } from 'services/customer'
import Exception from 'helpers/exception'
import { MainTypes } from 'store/main/types'
import { UNEXPECTED_ERROR_CODE, CUSTOMER_NOT_FOUND_OR_INACTIVE_ERROR } from 'helpers/EventCodes'
import { workerGetAddress } from 'store/address/sagas'
import _ from 'lodash'
import { workerCheckAuthentication } from '../auth/sagas'
import { CustomerQueryParams } from 'services/customer/types'
import { FeedlotTypes } from 'store/feedlot/types'
import { AddressTypes } from 'store/address/types'

export function* workerGetCustomers(action: any) {
  const { params } = action.payload
  try {
    yield put({ type: CustomerTypes.REDUCER_FETCH_CUSTOMER_DATA, payload: { customerLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    const customers = yield call(customerQuery, credentials.token, params)

    yield put({
      type: CustomerTypes.REDUCER_FETCH_CUSTOMER_DATA,
      payload: { customerLoading: false, customers: [...customers], selectedCustomer: undefined }
    })
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { feedlots: [] } })
    yield put({ type: AddressTypes.REDUCER_FETCH_ADDRESS_DATA, payload: { locations: [] } })
  } catch (error) {
    let message = null
    if (error instanceof Exception) message = error.getMessage()
    else message = new Exception(UNEXPECTED_ERROR_CODE, 'customer/sagas/workerGetCustomers', error).getMessage()
    yield put({ type: CustomerTypes.REDUCER_FETCH_CUSTOMER_DATA, payload: { customerLoading: false } })
    yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
  }
}

export function* workerSetSelectedCustomer(action: any) {
  const store = yield select(s => s)
  const { customerId } = action.payload
  try {
    yield put({ type: CustomerTypes.REDUCER_FETCH_CUSTOMER_DATA, payload: { customerLoading: true } })
    yield call(workerCheckAuthentication)

    // console.log(store.auth)
    // const authStore = store.auth
    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    let selectedCustomer: Customer | undefined

    if (customerId) {
      const params: CustomerQueryParams = { args: { id: customerId } }
      const customerList = yield call(customerQuery, credentials?.token, params)

      const mainStore = store.main
      const { history } = mainStore
      if (customerList.length) selectedCustomer = customerList[0]
      else {
        history.push('/customers')
        throw new Exception(CUSTOMER_NOT_FOUND_OR_INACTIVE_ERROR, 'customer/sagas/workerSetSelectedCustomer')
      }
    }
    yield put({
      type: CustomerTypes.REDUCER_FETCH_CUSTOMER_DATA,
      payload: { customerLoading: false, selectedCustomer: selectedCustomer ? { ...selectedCustomer } : undefined }
    })
    yield put({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { feedlots: [] } })
    if (selectedCustomer?.cityId) {
      const payload = { params: { args: { cityId: selectedCustomer.cityId } } }
      yield call(workerGetAddress, { payload })
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // let selectedCountryId: number | undefined
    // let selectedRegionId: number | undefined
    // let selectedCityId: number | undefined
    // if (selectedCustomer) {
    //   selectedCountryId = selectedCustomer.countryId
    //   selectedRegionId = selectedCustomer.stateId
    //   selectedCityId = selectedCustomer.cityId
    // }
    // const payload = { selectedCountryId, selectedRegionId, selectedCityId }
    // yield call(workerSetSelectedCountry, { payload })
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'customer/sagas/workerSaveCustomer', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: CustomerTypes.REDUCER_FETCH_CUSTOMER_DATA, payload: { customerLoading: false } })
  }
}

export function* workerSaveCustomer(action: any) {
  const { args } = action.payload
  const { customer } = args
  try {
    yield put({ type: CustomerTypes.REDUCER_FETCH_CUSTOMER_DATA, payload: { customerLoading: true } })
    yield call(workerCheckAuthentication)

    const store = yield select(s => s)
    const authStore = store.auth

    const mainStore = store.main
    const { history } = mainStore

    const customerStore = store.customer
    const { customers } = customerStore
    const { credentials } = authStore
    if (!customer.id) {
      customer.id = yield call(customerCreate, credentials.token, args)
      customers.push(customer)
      history.push(`/customers/${customer.id}`)
    } else {
      yield call(customerUpdate, credentials.token, args)
      const index = _.findIndex(customers, (c: Customer) => c.id === customer.id)
      customers[index] = customer
    }

    yield put({
      type: CustomerTypes.REDUCER_FETCH_CUSTOMER_DATA,
      payload: { customers: [...customers], selectedCustomer: { ...customer }, customerLoading: false }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'customer/sagas/workerSaveCustomer', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: CustomerTypes.REDUCER_FETCH_CUSTOMER_DATA, payload: { customerLoading: false } })
  }
}
