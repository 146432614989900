import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Props } from './types'
import { ApplicationState } from 'store/types'
import { getPen } from 'store/feedlot/actions'
import { setSelectedPen } from 'store/feedlot/actions'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { PenQueryFields } from 'services/pen/types'
import { Column } from 'components/widgets/Table/types'
import _ from 'lodash'
import { generalRender } from './helper'
import { FeedlotTypes } from 'store/feedlot/types'
import MaterialTable from 'material-table'
import CSVReader, { IFileInfo } from 'react-csv-reader'
import { Icon, Tooltip, IconButton, ListItemIcon } from '@material-ui/core'
import DisableAnimalFileModal from 'components/modals/DisableAnimalFileModal'
import { Animal } from 'store/animal/types'
import { ExportToCsv } from 'export-to-csv'
import './styles.scss'
import Button from 'components/widgets/Button'
import i18next from 'i18next'
import OxIcon from '../../../images/icons/beeftrader-ox-icon'
import { colors } from 'styles/variables'

const PenList: React.FC<Props> = ({ feedlotId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const { pens, penLoading } = useSelector((state: ApplicationState) => state.feedlot)

  const handleOption = (to: string) => history.push(`/feedlots/${feedlotId}/pens/${to}`)
  const [isFileModalOpen, setFileModalStatus] = useState<boolean>(false)
  const [selectedDisableAnimalsFile, setSelectedDisableAnimalsFile] = useState<Array<Animal>>([])
  const animalsModelFileOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    filename: i18next.t('file.disable_animals_model_file'),
    // useKeysAsHeaders: true
    headers: ['earring', 'tag', 'sisbov', 'datafim'] // Won't work with useKeysAsHeaders present!
  }
  const animalsModelFileData: Record<string, any>[] = [{}]

  const editAction = (selectedPen: any) => {
    if (selectedPen.id) {
      const fields: PenQueryFields = {
        id: true,
        code: true,
        status: true,
        feedlot: { id: true },
        equipments: {
          id: true,
          serialNumber: true,
          description: true,
          active: true
        }
      }
      dispatch(setSelectedPen({ args: { id: selectedPen.id }, fields }))
      handleOption(selectedPen.id)
    }
  }

  useEffect(() => {
    dispatch(getPen({ args: { feedlotId } }))
  }, [dispatch, feedlotId])

  const columnNames = ['code', 'status']
  const columns: Column[] = []
  if (pens.length) {
    _.map(columnNames, (column: string) => {
      columns.push({
        title: t('field.' + column),
        field: column,
        render: (rowData: Record<string, any>) => generalRender(rowData, column),
        cellStyle: { width: 10 }
      })
    })
  }
  const importFile = (file: any, fileInfo?: IFileInfo) => {
    for (let i = 1; i < file.length; i++) {
      if (file[i].length === 1 && file[i][0] === '') {
        file.splice(i, 1)
      }
    }
    setSelectedDisableAnimalsFile(file)
    // dispatch(setSelectedDisableAnimalsFile(file))
    setFileModalStatus(true)
  }

  const downloadAnimalsModelFile = () => {
    const csvExporter = new ExportToCsv(animalsModelFileOptions)
    csvExporter.generateCsv(animalsModelFileData)
  }

  return (
    <div className='pen-list'>
      <DisableAnimalFileModal
        isOpen={isFileModalOpen}
        handleClose={() => setFileModalStatus(false)}
        feedlotId={feedlotId}
        selectedDisableAnimalsFile={selectedDisableAnimalsFile}
      />
      <MaterialTable
        isLoading={penLoading}
        data={pens}
        columns={columns}
        style={{ width: 'auto' }}
        options={{
          header: !!pens.length,
          toolbar: true,
          paging: !!pens.length,
          toolbarButtonAlignment: 'left',
          searchFieldAlignment: 'left',
          showTitle: false,
          emptyRowsWhenPaging: false,
          addRowPosition: 'first',
          headerStyle: { textAlign: 'left', fontSize: 16, fontWeight: 'bold' },
          pageSizeOptions: pens.length < 5 ? [] : pens.length < 10 ? [5, 10] : [5, 10, 20]
        }}
        actions={[
          {
            icon: 'add_box',
            tooltip: t('pen.new'),
            isFreeAction: true,
            onClick: () => {
              dispatch({
                type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA,
                payload: { selectedPen: undefined }
              })
              handleOption('new')
            }
          },
          {
            icon: 'text_snippet',
            tooltip: t('file.disable_animals_model_file'),
            onClick: (_: any, data: any) => downloadAnimalsModelFile(),
            isFreeAction: true
          },
          {
            icon: 'import',
            tooltip: t('disable_animals'),
            isFreeAction: true,
            onClick: (_: any, data: any) => null
          },
          {
            icon: 'details',
            tooltip: t('animal.animal_plural'),
            onClick: (_: any, data: any) => editAction(data)
          }
        ]}
        components={{
          Action: (props: {
            action: {
              tooltip: string
              icon: string | Record<string, any> | null | undefined
              onClick: (arg0: React.MouseEvent<HTMLButtonElement, MouseEvent>, arg1: any) => void
            }
            data: any
          }) => {
            if (props.action.icon === 'import') {
              return (
                <Tooltip title={t('notice.disable_animals_import_file') ?? ''}>
                  <button className='download-button'>
                    <CSVReader
                      label={t('default.disable_animals')}
                      inputStyle={{ opacity: 0, width: '5%' }}
                      onFileLoaded={(data, fileInfo) => importFile(data, fileInfo)}
                    ></CSVReader>
                  </button>
                </Tooltip>
              )
            } else if (props.action.icon === 'details') {
              return (
                <Tooltip title={props.action.tooltip}>
                  <IconButton size='small' onClick={(event: any) => props.action.onClick(event, props.data)}>
                    <div className='card-icon-container'>
                      <OxIcon width={'2rem'} height={'2rem'} fill={colors.primary} />
                    </div>
                  </IconButton>
                </Tooltip>
              )
            } else {
              return (
                <Tooltip title={props.action.tooltip}>
                  <IconButton
                    // aria-label={props.action?.icon}
                    size='small'
                    onClick={(event: any) => props.action.onClick(event, props.data)}
                  >
                    <Icon>{props.action.icon}</Icon>
                  </IconButton>
                </Tooltip>
              )
            }
          },
          Container: (props: {
            children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
          }) => <div style={{ width: 'auto' }}>{props.children}</div>
        }}
      />
    </div>
  )
}
export default PenList
