import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Props } from './types'

import LoginScreen from 'screens/LoginScreen'
import LoadingScreen from 'screens/LoadingScreen'
import { ApplicationState } from 'store/types'

const AuthRoute = (parentProps: Props) => {
  const { isAuthenticated } = useSelector((state: ApplicationState) => state.auth)
  const checkingAuthentication = useSelector((state: ApplicationState) => state.auth.checkingAuthentication)
  const { exact, path } = parentProps
  return (
    <Route
      exact={exact !== undefined}
      path={path}
      render={(props: any) =>
        checkingAuthentication ? (
          <LoadingScreen />
        ) : isAuthenticated ? (
          <Redirect to={props.location.state.from || { from: { pathname: '/' } }} />
        ) : (
          <LoginScreen />
        )
      }
    />
  )
}
export default AuthRoute
