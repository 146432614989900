import React, { useEffect, useState } from 'react'
import { Props } from './types'
import ItemList from 'components/widgets/ItemList'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'
import { useParams } from 'react-router-dom'
import { getPen, getLot } from 'store/feedlot/actions'
import { Pen, Lot } from 'store/feedlot/types'
import { useTranslation } from 'react-i18next'

const PenLotPermission: React.FC<Props> = ({ emitSelectedPensIds, emitSelectedLotsIds, permissionSet }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { id } = useParams()
  const { pens, penLoading, lots, lotLoading } = useSelector((state: ApplicationState) => state.feedlot)
  const [selectedPens, setSelectedPens] = useState<Pen[]>(permissionSet?.pens || [])
  const [selectedLots, setSelectedLots] = useState<Lot[]>(permissionSet?.lots || [])
  const [allowAllPens, setAllowAllPens] = useState<boolean>(
    permissionSet?.permissions?.allowAllPens || permissionSet?.permissions?.allowAllLots ? true : false
  )

  useEffect(() => {
    if (pens?.length === 0) dispatch(getPen({ args: { feedlotId: Number(id) } }))
    if (lots.length === 0)
      dispatch(getLot({ args: { feedlotId: Number(id) }, fields: { id: true, description: true, pen: { id: true } } }))
    if (allowAllPens) {
      setSelectedPens(
        pens?.map(p => {
          return p
        })
      )
      setSelectedLots(
        lots?.map(l => {
          return l
        })
      )
    }
  }, [pens, lots, id, allowAllPens, dispatch])

  // const openPens = () => pens?.length === 0 && dispatch(getPen({ args: { feedlotId: Number(id) } }))
  // const openLots = (penId: number) =>
  //   lots.length === 0 && dispatch(getLot({ args: { feedlotId: Number(id) }, fields: { id: true, description: true, pen: { id: true } } }))

  const setAllowAllPensMethod = (value: boolean) => {
    setAllowAllPens(value)
  }

  const getPenChecked = (id: number) => {
    return selectedPens?.find(p => p.id === id) ? true : false
  }

  const getLotChecked = (penId: number, id: number) => {
    return selectedLots?.find(l => l.id === id) || selectedPens?.find(p => p.id === penId) ? true : false
  }

  return (
    <ItemList
      id='pens'
      label={t('pen.pen', { count: 0 })}
      onOpen={() => {
        return
      }}
      loading={penLoading}
      checked={allowAllPens}
      onChange={(checked: boolean) => {
        checked
          ? setSelectedPens(
              pens?.map(p => {
                return p
              })
            )
          : setSelectedPens([])
        checked
          ? setSelectedLots(
              lots?.map(l => {
                return l
              })
            )
          : setSelectedLots([])
        checked ? emitSelectedPensIds(pens?.map(p => p.id)) : emitSelectedPensIds([])
        checked ? emitSelectedLotsIds(lots?.map(l => l.id)) : emitSelectedLotsIds([])
      }}
    >
      {pens.map(p => (
        <ItemList
          id={String(p.id)}
          key={p.id}
          label={p.code}
          onOpen={() => {
            return
          }}
          loading={lotLoading}
          checked={getPenChecked(p.id)}
          onChange={(checked: boolean) => {
            let updatedPens = selectedPens || []
            let updatedLots = selectedLots || []
            if (updatedPens.length === pens.length) {
              setAllowAllPensMethod(true)
            }
            if (checked) {
              if (!updatedPens.find(up => up.id === p.id)) {
                updatedPens.push({ id: p.id, code: p.code, status: p.status })
                const ls = lots?.filter(up => up.pen?.id === p.id)
                ls.map(l => {
                  if (!updatedLots?.find(ul => ul.id === l.id)) updatedLots.push(l)
                  return null
                })
              }
              if (updatedPens.length === pens.length) {
                setAllowAllPensMethod(true)
              }
            } else {
              if (allowAllPens) setAllowAllPensMethod(false)
              updatedPens = updatedPens?.filter(up => up.id !== p.id)
              updatedLots = updatedLots?.filter(ul => ul.pen?.id !== p.id)
            }

            setSelectedPens(updatedPens)
            emitSelectedPensIds(updatedPens?.map(p => p.id))
            setSelectedLots(updatedLots)
            emitSelectedLotsIds(updatedLots?.map(l => l.id))
          }}
        >
          {lots
            .filter(l => l.pen?.id === p.id)
            .map(l => (
              <ItemList
                id={String(l.id)}
                key={l.id}
                label={l.description}
                checked={getLotChecked(p.id, l.id)}
                onChange={(checked: boolean) => {
                  let updatedLots = selectedLots || []
                  let updatedPens = selectedPens || []
                  if (checked) {
                    if (!updatedLots?.find(sl => sl.id === l.id)) {
                      const lot = lots?.find(ls => ls.id === l.id)
                      if (lot) updatedLots.push(lot)
                    }
                  } else {
                    if (allowAllPens) setAllowAllPensMethod(false)
                    updatedPens = updatedPens?.filter(up => up.id !== p.id)
                    updatedLots = updatedLots?.filter(ul => ul.id !== l.id)
                  }
                  setSelectedPens(updatedPens)
                  emitSelectedPensIds(updatedPens?.map(p => p.id))
                  setSelectedLots(updatedLots)
                  emitSelectedLotsIds(updatedLots?.map(l => l.id))
                }}
              />
            ))}
        </ItemList>
      ))}
    </ItemList>
  )
}
export default PenLotPermission
