export type AuthStore = {
  readonly attemptingSignIn: boolean
  readonly isAuthenticated: boolean
  readonly credentials: Record<string, any> | null
  readonly checkingAuthentication: boolean
}

export enum AuthTypes {
  REDUCER_CLEAR_AUTH_DATA = 'REDUCER_CLEAR_AUTH_DATA',
  REDUCER_FETCH_AUTH_DATA = 'REDUCER_FETCH_AUTH_DATA',
  REDUCER_FETCH_EMAIL_CHANGE_PASS = 'REDUCER_FETCH_EMAIL_CHANGE_PASS',
  ACTION_ATTEMPT_SIGN_IN = 'ACTION_ATTEMPT_SIGN_IN',
  ACTION_SIGN_OUT = 'ACTION_SIGN_OUT',
  ACTION_CHECK_AUTHENTICATION = 'ACTION_CHECK_AUTHENTICATION'
}
