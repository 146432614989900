import React from 'react'
import { Props } from './types'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'
import { useTranslation } from 'react-i18next'

import Scatterchart from '../../widgets/Charts/Scatter'
import { Scatter, Cell } from 'recharts'
import Loading from 'components/widgets/Loading'
import SimpleCard from 'components/widgets/Card'

import _ from 'lodash'

const AnimalWeighingChart: React.FC<Props> = ({ minDay, maxDay, maxWeight, minWeight }) => {
  const { t } = useTranslation()
  const { animalLoading, selectedAnimal } = useSelector((state: ApplicationState) => state.animal)
  const { weighingLoading } = useSelector((state: ApplicationState) => state.weighing)

  const weighingLegend = t('field.weighing')

  let selectedAnimalWeighings: number | undefined = 0
  const animalWeighing: any[] | undefined = selectedAnimal?.weighing

  _.map(animalWeighing, w => {
    w.fill = 'rgb(23, 209, 54)'
    if (minWeight === 0 || minWeight) {
      if (!maxWeight && !minDay && !maxDay && w.weight >= minWeight) {
        w.fill = 'rgb(23, 161, 210)'
      }
      if (maxWeight === 0 || maxWeight) {
        if (!minDay && !maxDay && w.weight >= minWeight && w.weight <= maxWeight) {
          w.fill = 'rgb(23, 161, 210)'
        }
        if (minDay) {
          if (!maxDay && w.weight >= minWeight && w.weight <= maxWeight && w.day >= minDay) {
            w.fill = 'rgb(23, 161, 210)'
          }
          if (maxDay) {
            if (w.weight >= minWeight && w.weight <= maxWeight && w.day >= minDay && w.day <= maxDay) {
              w.fill = 'rgb(23, 161, 210)'
            }
          }
        }
      }
    }

    if (w.prediction === 'p') w.fill = 'rgb(118, 35, 26)'
  })

  selectedAnimalWeighings = animalWeighing?.filter(w => w.fill === 'rgb(23, 161, 210)').length

  const data = animalWeighing

  return (
    <div className='row'>
      <div className='offset-5'>
        {animalLoading || weighingLoading ? (
          <Loading />
        ) : (
          <Scatterchart
            width={730}
            height={450}
            xAxisDataKey={'day'}
            xAxisName={t('field.day')}
            yAxisDataKey={'weight'}
            yAxisName={t('field.weight')}
            yAxisUnit={'kg'}
            // chartData={animalWeighing}
          >
            <Scatter name={weighingLegend} data={data} fill={'rgb(23, 209, 54)'}>
              {data?.map((entry: any, index: number) => {
                return <Cell key={`cell-${index}`} fill={entry.fill} />
              })}
            </Scatter>
            {/* <Brush dataKey="day" height={30} />   */}
          </Scatterchart>
        )}
      </div>
      <div className='offset-7'>
        <SimpleCard
          style={{ backgroundColor: 'rgb(23, 161, 210)' }}
          legend={t('weight.count_selected')}
          body={selectedAnimalWeighings ? selectedAnimalWeighings : null}
        ></SimpleCard>
      </div>
    </div>
  )
}
export default AnimalWeighingChart
