import React from 'react'
import { Props } from './types'

import Modal from 'components/widgets/Modal'
import MoveLotForm from 'components/forms/MoveLotForm'

const MoveLotModal: React.FC<Props> = ({ handleClose, isOpen, feedlotId, lotId }) => (
  <Modal handleClose={handleClose} open={isOpen} title={'animal.update_animals'}>
    <MoveLotForm feedlotId={feedlotId || 0} lotId={lotId || 0} handleClose={handleClose} />
  </Modal>
)

export default MoveLotModal
