import { Address } from 'store/address/types'

export type Slaughterhouse = {
  id: number
  description: string
  status?: number
  latitude?: string
  longitude?: string
  slaughterday?: number
  slaughteryr?: number
  class?: string
  address?: Address
}

export type SlaughterhouseState = {
  readonly selectedSlaughterhouse?: Slaughterhouse
  readonly slaughterhouses: Slaughterhouse[]
  readonly slaughterhouseLoading: boolean
}

export enum SlaughterhouseTypes {
  REDUCER_FETCH_SLAUGHTERHOUSE_DATA = 'REDUCER_FETCH_SLAUGHTERHOUSE_DATA',
  REDUCER_CLEAR_SLAUGHTERHOUSE_DATA = 'REDUCER_CLEAR_SLAUGHTERHOUSE_DATA',
  ACTION_GET_SLAUGHTERHOUSES = 'ACTION_GET_SLAUGHTERHOUSES',
  ACTION_SET_SELECTED_SLAUGHTERHOUSE = 'ACTION_SET_SELECTED_SLAUGHTERHOUSE',
  ACTION_SAVE_SLAUGHTERHOUSE = 'ACTION_SAVE_SLAUGHTERHOUSE'
}
