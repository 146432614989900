import React, { ChangeEvent, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Props } from './types'
import { CustomerQueryFields } from 'services/customer/types'
import { ApplicationState } from 'store/types'
import { getCustomers } from 'store/customer/actions'
import { Customer } from 'store/customer/types'

import { Option } from 'data/types'
import Select from 'components/widgets/Select'
import { useTranslation } from 'react-i18next'

const CustomerSelector: React.FC<Props> = ({ selected, disabled, cleanError, emitSelectedCustomerId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { customers, customerLoading } = useSelector((state: ApplicationState) => state.customer)

  const [options, setOptions] = useState<Option[]>([])

  const formatCustomers = (customers: Customer[]) =>
    customers.map((c: Customer) => ({ label: c.name || c.email || '', value: c.id || -1 }))

  useEffect(() => {
    if (!customers.length) {
      const fields: CustomerQueryFields = { id: true, name: true, email: true }
      dispatch(getCustomers({ fields }))
    } else setOptions(formatCustomers(customers))
  }, [customers, dispatch])

  const onChange = (event: ChangeEvent) => {
    if (emitSelectedCustomerId !== undefined) emitSelectedCustomerId(Number(event))
  }

  return (
    <Select
      name='customer.id'
      label={t('customer.customer')}
      loading={customerLoading}
      defaultOptions={options}
      onFocus={cleanError}
      selectedValue={selected}
      handleSelect={event => onChange(event)}
      disabled={disabled}
    />
  )
}
export default CustomerSelector
