import React from 'react'
import AnimalWeighingForm from 'components/forms/AnimalWeighingForm'
import BreadCrumb from 'components/layout/Breadcrumb'
import { useTranslation } from 'react-i18next'

const WeighingScreen = () => {
  const { t } = useTranslation()

  return (
    <>
      <BreadCrumb items={[{ label: t('field.weighing', { count: 0 }), path: '/', active: false }]} />
      <AnimalWeighingForm></AnimalWeighingForm>
    </>
  )
}

export default WeighingScreen
