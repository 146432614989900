import { AnyAction } from 'redux'
import { put, call, select } from 'redux-saga/effects'
import { UserTypes, User } from './types'
import { workerCheckAuthentication } from 'store/auth/sagas'
import { userQuery, userCreate, userUpdate, userPermissionsCreate, userPermissionsUpdate } from 'services/user'
import Exception from 'helpers/exception'
import { MainTypes } from 'store/main/types'
import { UNEXPECTED_ERROR_CODE, USER_NOT_FOUND_OR_INACTIVE_ERROR } from 'helpers/EventCodes'
import { UserQueryParams } from 'services/user/types'
import _ from 'lodash'

export function* workerGetUser(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: UserTypes.REDUCER_FETCH_USER_DATA, payload: { userLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    const users = yield call(userQuery, credentials.token, params)

    yield put({
      type: UserTypes.REDUCER_FETCH_USER_DATA,
      payload: {
        selectedUser: undefined,
        users: [...users],
        userLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'user/sagas/workerGetUsers', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: UserTypes.REDUCER_FETCH_USER_DATA, payload: { userLoading: false } })
  }
}

export function* workerGetUserEmails(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: UserTypes.REDUCER_FETCH_USER_DATA, payload: { userEmailsLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    const userEmails = yield call(userQuery, credentials.token, params)

    yield put({
      type: UserTypes.REDUCER_FETCH_USER_DATA,
      payload: {
        userEmails: [...userEmails],
        userEmailsLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'user/sagas/workerGetUserEmails', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: UserTypes.REDUCER_FETCH_USER_DATA, payload: { userEmailsLoading: false } })
  }
}

export function* workerSetSelectedUser(action: AnyAction) {
  const store = yield select(s => s)
  const { userId } = action.payload
  try {
    yield put({ type: UserTypes.REDUCER_FETCH_USER_DATA, payload: { userLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    let selectedUser: User | undefined

    if (userId) {
      const params: UserQueryParams = { args: { id: userId } }
      const userList = yield call(userQuery, credentials.token, params)

      const mainStore = store.main
      const { history } = mainStore
      if (userList.length) selectedUser = userList[0]
      else {
        //LEMBRAR: voltar para o confinamento certo
        history.push('/feedlots')
        throw new Exception(USER_NOT_FOUND_OR_INACTIVE_ERROR, 'users/sagas/workerSetSelectedUser')
      }
    }
    yield put({
      type: UserTypes.REDUCER_FETCH_USER_DATA,
      payload: {
        selectedUser: { ...selectedUser },
        userLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'user/sagas/workerSaveUser', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: UserTypes.REDUCER_FETCH_USER_DATA, payload: { userLoading: false } })
  }
}

export function* workerSaveUser(action: AnyAction) {
  const { args } = action.payload
  try {
    yield put({ type: UserTypes.REDUCER_FETCH_USER_DATA, payload: { userLoading: true } })
    yield call(workerCheckAuthentication)

    const store = yield select(s => s)
    const authStore = store.auth
    const userStore = store.user

    let { user } = args

    let { users } = userStore
    users = users ? users : []
    const { credentials } = authStore
    if (!user.id) {
      user.id = yield call(userCreate, credentials.token, args)
      user = yield call(userQuery, credentials.token, { args: { id: user.id } })
      if (user[0]) users.push(user[0])
    } else {
      yield call(userUpdate, credentials.token, args)
      user = yield call(userQuery, credentials.token, { args: { id: user.id } })
      const index = _.findIndex(users, (u: User) => u.id === user[0].id)
      users[index] = user[0]
    }
    yield put({
      type: UserTypes.REDUCER_FETCH_USER_DATA,
      payload: {
        users: [...users],
        selectedUser: { ...user },
        userLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      // if (error.additionalInfo) {
      //   message.text += _.map(error?.additionalInfo, a => {
      //     return '\n' + JSON.stringify(a)
      //   })
      // }
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'user/sagas/workerCreateUser', error)
      console.log(ex.getMessage())
    }
    yield put({ type: UserTypes.REDUCER_FETCH_USER_DATA, payload: { userLoading: false } })
  }
}

export function* workerSaveUserPermissions(action: AnyAction) {
  const { args } = action.payload
  try {
    yield put({ type: UserTypes.REDUCER_FETCH_USER_DATA, payload: { userPermissionsLoading: true } })
    yield call(workerCheckAuthentication)

    const store = yield select(s => s)
    const authStore = store.auth

    let { userPermissions } = args

    userPermissions = userPermissions ? userPermissions : []
    const { credentials } = authStore
    if (!userPermissions.permissionSet.removedPens && !userPermissions.permissionSet.removedLots) {
      userPermissions.userId = yield call(userPermissionsCreate, credentials.token, args)
    } else {
      yield call(userPermissionsUpdate, credentials.token, args)
    }
    userPermissions = yield call(userQuery, credentials.token, { args: { feedlotId: userPermissions.feedlotId } })
    yield put({
      type: UserTypes.REDUCER_FETCH_USER_DATA,
      payload: {
        users: [...userPermissions],
        userPermissionsLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      if (error.additionalInfo) {
        // message.text += _.map(error.additionalInfo, a => {
        //   return '\n' + JSON.stringify(a)
        // })
      }
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'user/sagas/workerCreateUserPermissions', error)
      console.log(ex.getMessage())
    }
    yield put({ type: UserTypes.REDUCER_FETCH_USER_DATA, payload: { userLoading: false } })
  }
}
