import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Props } from './types'
import { ApplicationState } from 'store/types'
import * as Yup from 'yup'
import './styles.scss'
import { getUserEmails, saveUserPermissions } from 'store/user/actions'

import { SubmitHandler, Scope } from '@unform/core'
import { Form } from '@unform/web'
import 'components/widgets/Chip/styles.scss'
import 'components/widgets/Input/styles.scss'
import Chip from 'components/widgets/Chip'
import Button from 'components/widgets/Button'
import _ from 'lodash'
import { PermissionSet, User } from 'store/user/types'
import Switch from 'components/widgets/Switch'
import Loading from 'components/widgets/Loading'
import PenLotPermission from 'components/permissions/PenLotPermission'
import CustomerSelector from 'components/selectors/CustomerSelector'
import ItemList from 'components/widgets/ItemList'
import Input from 'components/widgets/Input'

const UserPermissionsForm: React.FC<Props> = ({ feedlotId, setUserPermissionsModalStatus, isNew }) => {
  const formRef: any = useRef(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { pens } = useSelector((state: ApplicationState) => state.feedlot)
  const { users, userEmails, selectedUser, userLoading } = useSelector((state: ApplicationState) => state.user)
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const [customerUsers, setCustomerUsers] = useState<User[]>([])
  const [penIds, setSelectedPensIds] = useState<number[]>([])
  const [lotIds, setSelectedLotsIds] = useState<number[]>([])

  const buttonLabel = selectedUser?.id ? t('field.save') : t('field.add')
  let permissionSetIndex: number | undefined = 0
  permissionSetIndex = selectedUser?.permissionSet?.findIndex(p => p.feedlotId === feedlotId)
  permissionSetIndex = permissionSetIndex ? permissionSetIndex : 0
  const customerId = selectedUser?.customer?.id

  useEffect(() => {
    dispatch(
      getUserEmails({
        fields: { id: true, email: true, customer: { id: true } }
      })
    )
  }, [dispatch, customerId])

  const handleSubmit: SubmitHandler<User> = async data => {
    const allPens = penIds.length === pens.length ? true : false
    const userPermissionSet: any = data

    const selectedPermissionSet = userPermissionSet.permissionSet[permissionSetIndex || 0]

    selectedPermissionSet.permissions.allowAllPens = allPens
    selectedPermissionSet.permissions.allowAllLots = allPens

    const permissionSet: PermissionSet | undefined = selectedUser?.permissionSet?.find(ps => ps.feedlotId === feedlotId)

    selectedPermissionSet.pens = allPens ? [] : penIds
    selectedPermissionSet.lots = allPens ? [] : lotIds

    if (!allPens && !isNew) {
      const userPens: number[] = []
      const userLots: number[] = []

      permissionSet?.pens.map(p => userPens.push(p.id))
      permissionSet?.lots.map(l => userLots.push(l.id))
      selectedPermissionSet.removedPens = userPens.filter((id: number) => !penIds.includes(id))
      selectedPermissionSet.removedLots = userLots.filter((id: number) => !lotIds.includes(id))
    }

    delete userPermissionSet.permissionSet
    if (selectedPermissionSet) userPermissionSet.permissionSet = selectedPermissionSet
    else userPermissionSet.permissionSet[0] = null

    userPermissionSet.feedlotId = feedlotId

    delete userPermissionSet.id
    delete userPermissionSet.customer
    delete userPermissionSet.email

    _.map(
      Object.entries(userPermissionSet),
      ([key, value]) => (userPermissionSet[key] = value === '' || undefined ? null : value)
    )
    const usersIds: number[] = []
    selectedUsers.map(su => {
      if (!users.find(u => u.id === su)) {
        usersIds.push(su)
      }
      return null
    })
    userPermissionSet.userId = selectedUser?.id || usersIds
    try {
      if (!feedlotId) return
      formRef.current.setErrors({})
      console.log(userPermissionSet)
      dispatch(saveUserPermissions({ userPermissions: userPermissionSet }))
      setUserPermissionsModalStatus(false)
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }

  const handleCustomerChange = (customerId: number) => {
    const customerUsers = userEmails.filter(u => u?.customer?.id === customerId)
    const usersIds: number[] = []
    users.map(u =>
      customerUsers.find(cu => {
        if (cu.id === u.id) usersIds.push(u.id)
        return null
      })
    )
    !selectedUser?.id ? setSelectedUsers(usersIds.map(id => id)) : setSelectedUsers([selectedUser?.id])
    setCustomerUsers(customerUsers)
  }

  const getSelected = (userId: number) => {
    if (selectedUsers?.find(id => id === userId)) return true
  }

  return (
    <div className='container'>
      {userLoading ? (
        <Loading />
      ) : (
        <Form ref={formRef} initialData={selectedUser} onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-6'>
              {/* <UserEmailsSelector selected={selectedUser?.id} /> */}
              <CustomerSelector
                selected={selectedUser?.customer?.id}
                emitSelectedCustomerId={customerId => handleCustomerChange(customerId)}
                disabled={!selectedUser?.id ? false : true}
              />
            </div>
          </div>

          <div className='row'>
            <div className='column'>
              {!selectedUser?.id ? (
                customerUsers.map(u => (
                  <ItemList
                    id={String(u.id)}
                    key={u.id}
                    label={u.email}
                    loading={userLoading}
                    checked={getSelected(u.id)}
                    disabled={users?.find(user => user.id === u.id) ? true : false}
                    onChange={(checked: boolean) => {
                      if (checked) {
                        if (!selectedUsers.find(id => id === u.id)) selectedUsers.push(u.id)
                      } else {
                        const users = selectedUsers.filter(id => id !== u.id)
                        setSelectedUsers(users)
                      }
                    }}
                  ></ItemList>
                ))
              ) : (
                <Input name='email' label={t('field.email')} disabled={true} />
              )}
            </div>
          </div>
          <div className='row'>
            <div className='column'>
              <label>{t('permission.permission', { count: 0 })}</label>
              <div className='permissions'>
                <Scope path={'permissionSet[' + permissionSetIndex + ']'}>
                  <Scope path='permissions'>
                    <Switch
                      name='admin'
                      label={t('permission.admin')}
                      handleChange={checked => {
                        return
                      }}
                      onChange={() => console.log('e.target.checked === true')}
                      checkValue={false}
                    />
                    <div className='chips-container'>
                      <Chip name='allowEdit' label={t('permission.edit')} />
                      <Chip name='allowEconomicInfo' label={t('permission.view_economic')} />
                    </div>
                  </Scope>
                </Scope>
                <PenLotPermission
                  emitSelectedPensIds={setSelectedPensIds}
                  emitSelectedLotsIds={setSelectedLotsIds}
                  permissionSet={
                    selectedUser
                      ? selectedUser?.permissionSet
                        ? selectedUser?.permissionSet[permissionSetIndex || 0]
                        : []
                      : []
                  }
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-5 mtop-2'>
              <Button label={buttonLabel} />
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}
export default UserPermissionsForm
