import { put, call, select } from 'redux-saga/effects'
import { EconomicTypes } from './types'
import { priceQuery, priceCreate, priceUpdate, currencyQuery, weightUnityQuery } from 'services/economic'
import Exception from 'helpers/exception'
import { MainTypes } from 'store/main/types'
import { UNEXPECTED_ERROR_CODE, PRICE_NOT_FOUND_OR_INACTIVE_ERROR } from 'helpers/EventCodes'
import { workerCheckAuthentication } from '../auth/sagas'
import { PriceQueryParams } from 'services/economic/types'
import { AnyAction } from 'redux'

export function* workerGetPrice(action: AnyAction) {
  const { params } = action.payload
  const pList = [
    {
      id: 1,
      category: 'Macho inteiro nelore',
      value: 'mi_nel',
      cash: 0,
      term: 0,
      currency: {
        id: 1,
        code: '',
        symbol: '',
        description: ''
      },
      weightUnity: {
        id: 1,
        code: '',
        symbol: ''
      }
    },
    {
      id: 2,
      category: 'Macho castrado nelore',
      value: 'mc_nel',
      cash: 0,
      term: 0,
      currency: {
        id: 1,
        code: '',
        symbol: '',
        description: ''
      },
      weightUnity: {
        id: 1,
        code: '',
        symbol: ''
      }
    },
    {
      id: 3,
      category: 'Macho inteiro cruzado',
      value: 'mi_cruz',
      cash: 0,
      term: 0,
      currency: {
        id: 1,
        code: '',
        symbol: '',
        description: ''
      },
      weightUnity: {
        id: 1,
        code: '',
        symbol: ''
      }
    },
    {
      id: 4,
      category: 'Macho castrado cruzado',
      value: 'mc_cruz',
      cash: 0,
      term: 0,
      currency: {
        id: 1,
        code: '',
        symbol: '',
        description: ''
      },
      weightUnity: {
        id: 1,
        code: '',
        symbol: ''
      }
    },
    {
      id: 5,
      category: 'Novilha nelore',
      value: 'nv_nel',
      cash: 0,
      term: 0,
      currency: {
        id: 1,
        code: '',
        symbol: '',
        description: ''
      },
      weightUnity: {
        id: 1,
        code: '',
        symbol: ''
      }
    },
    {
      id: 6,
      category: 'Novilha Cruzada',
      value: 'nv_cruz',
      cash: 0,
      term: 0,
      currency: {
        id: 1,
        code: '',
        symbol: '',
        description: ''
      },
      weightUnity: {
        id: 1,
        code: '',
        symbol: ''
      }
    },
    {
      id: 7,
      category: 'Vaca nelore',
      value: 'vc_nel',
      cash: 0,
      term: 0,
      currency: {
        id: 1,
        code: '',
        symbol: '',
        description: ''
      },
      weightUnity: {
        id: 1,
        code: '',
        symbol: ''
      }
    }
  ]
  try {
    yield put({ type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA, payload: { priceLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    const price = yield call(priceQuery, credentials.token, params)

    yield put({
      type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA,
      payload: { priceLoading: false, price: price[0], selectedPrice: undefined, pricesList: pList }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) message = error.getMessage()
    else message = new Exception(UNEXPECTED_ERROR_CODE, 'price/sagas/workerGetPrice', error).getMessage()
    yield put({ type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA, payload: { priceLoading: false } })
    yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
  }
}

export function* workerSetSelectedPrice(action: AnyAction) {
  const store = yield select(s => s)
  const { selectedPrice } = action.payload
  try {
    yield put({ type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA, payload: { priceLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    if (selectedPrice.id) {
      const params: PriceQueryParams = { args: { id: selectedPrice.id } }
      const priceList = yield call(priceQuery, credentials?.token, params)

      const mainStore = store.main
      const { history } = mainStore
      if (!priceList.length) {
        history.push('/prices')
        throw new Exception(PRICE_NOT_FOUND_OR_INACTIVE_ERROR, 'price/sagas/workerSetSelectedPrice')
      }
    }
    yield put({
      type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA,
      payload: { priceLoading: false, selectedPrice: selectedPrice ? { ...selectedPrice } : undefined }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'price/sagas/workerSavePrice', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA, payload: { priceLoading: false } })
  }
}

export function* workerSavePrice(action: AnyAction) {
  const { args } = action.payload
  const { price, selectedPrice } = args
  try {
    yield put({ type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA, payload: { priceLoading: true } })
    yield call(workerCheckAuthentication)

    const store = yield select(s => s)
    const authStore = store.auth
    const { credentials } = authStore

    if (!price.id) {
      price.id = yield call(priceCreate, credentials.token, args)
      price.currency = (yield call(currencyQuery, credentials.token, { args: { id: price.currencyId } }))[0]
      price.weightUnity = (yield call(weightUnityQuery, credentials.token, { args: { id: price.weightUnityId } }))[0]
    } else {
      yield call(priceUpdate, credentials.token, args)
      price.currency = (yield call(currencyQuery, credentials.token, { args: { id: price.currencyId } }))[0]
      price.weightUnity = (yield call(weightUnityQuery, credentials.token, { args: { id: price.weightUnityId } }))[0]
    }

    yield put({
      type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA,
      payload: { price: price, selectedPrice: selectedPrice, priceLoading: false }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'price/sagas/workerSavePrice', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA, payload: { priceLoading: false } })
  }
}

export function* workerGetCurrency(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA, payload: { currencyLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    const currencies = yield call(currencyQuery, credentials.token, params)

    yield put({
      type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA,
      payload: { currencyLoading: false, currencies, selectedCurrency: undefined }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) message = error.getMessage()
    else message = new Exception(UNEXPECTED_ERROR_CODE, 'currency/sagas/workerGetCurrency', error).getMessage()
    yield put({ type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA, payload: { currencyLoading: false } })
    yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
  }
}

export function* workerGetWeightUnity(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA, payload: { weightUnityLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    const weightUnits = yield call(weightUnityQuery, credentials.token, params)

    yield put({
      type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA,
      payload: { weightUnityLoading: false, weightUnits, selectedWeightUnity: undefined }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) message = error.getMessage()
    else message = new Exception(UNEXPECTED_ERROR_CODE, 'weightUnity/sagas/workerGetWeightUnity', error).getMessage()
    yield put({ type: EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA, payload: { weightUnityLoading: false } })
    yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
  }
}
