import { all, takeLatest } from 'redux-saga/effects'
import { workerGetCustomers, workerSetSelectedCustomer, workerSaveCustomer } from './sagas'
import { CustomerTypes } from './types'

export function* watcherGetCustomers() {
  yield takeLatest(CustomerTypes.ACTION_GET_CUSTOMERS, workerGetCustomers)
}

export function* watcherSetSelectedCustomer() {
  yield takeLatest(CustomerTypes.ACTION_SET_SELECTED_CUSTOMER, workerSetSelectedCustomer)
}

export function* watcherSaveCustomer() {
  yield takeLatest(CustomerTypes.ACTION_SAVE_CUSTOMER, workerSaveCustomer)
}

export default function* authRootSaga() {
  yield all([watcherGetCustomers(), watcherSetSelectedCustomer(), watcherSaveCustomer()])
}
