import React from 'react'
import { Props } from './types'

import { colors } from 'styles/variables'
import './styles.scss'

import ReactLoading from 'react-loading'

const Loading: React.FC<Props> = ({ size }) => {
  return (
    <div className='loading'>
      <ReactLoading type='spin' color={colors.primary} width={size || 30} height={size || 30} />
    </div>
  )
}
export default Loading
