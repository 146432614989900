import { all, takeLatest } from 'redux-saga/effects'

import { workerAttemptSignIn, workerCheckAuthentication, workerSignOut } from './sagas'

import { AuthTypes } from './types'

export function* watcherAttemptLogin() {
  yield takeLatest(AuthTypes.ACTION_ATTEMPT_SIGN_IN, workerAttemptSignIn)
}

export function* watcherCheckAuthentication() {
  yield takeLatest(AuthTypes.ACTION_CHECK_AUTHENTICATION, workerCheckAuthentication)
}

export function* watcherSignOut() {
  yield takeLatest(AuthTypes.ACTION_SIGN_OUT, workerSignOut)
}

export default function* authRootSaga() {
  yield all([watcherAttemptLogin(), watcherCheckAuthentication(), watcherSignOut()])
}
