import React from 'react'
import i18next from 'i18next'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'

const NotFoundScreen = () => {
  const { isMainSidebarOpened } = useSelector((state: ApplicationState) => state.main)
  return (
    <div>
      <h1>{i18next.t('default.notFoundScreen')}</h1>
    </div>
  )
}

export default NotFoundScreen
