import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Props } from './types'
import { ApplicationState } from 'store/types'
import { getPrice } from 'store/economic/actions'
import { setSelectedPrice } from 'store/economic/actions'
import { Column } from 'components/widgets/Table/types'
import _ from 'lodash'
import { generalRender } from './helper'
import PriceModal from 'components/modals/PriceModal'
import Loading from 'components/widgets/Loading'
import i18next from 'i18next'

const PriceList: React.FC<Props> = ({ slaughterhouseId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { price, priceLoading, pricesList } = useSelector((state: ApplicationState) => state.economic)
  const [isPriceModalOpen, setPriceModalStatus] = useState<boolean>(false)
  const [isNew, setIsNew] = useState<boolean>(true)
  const MaterialTable = require('material-table').default

  const editAction = (selectedPrice: any) => {
    if (selectedPrice.id) setIsNew(false)
    dispatch(setSelectedPrice(selectedPrice))
    setPriceModalStatus(true)
  }

  useEffect(() => {
    dispatch(getPrice({ args: { slaughterhouseId } }))
  }, [dispatch, slaughterhouseId])

  const list: any = price
  // const pricesToAdd: any[] = []

  if (list) {
    for (let i = 0; i < pricesList.length; i++) {
      for (const key in list) {
        if (key.includes(pricesList[i].value)) {
          pricesList[i].id = list.id
          if (key.includes('vista') && list) {
            pricesList[i].cash = list[key]
          } else if (key.includes('prazo') && list) {
            pricesList[i].term = list[key]
          }
          if (
            (pricesList[i].cash === 0 || pricesList[i].cash === null) &&
            (pricesList[i].term === 0 || pricesList[i].term === null)
          ) {
            // pricesToAdd.push(pricesList.splice(i, 1))
          }
        }
        pricesList[i].currency = list.currency
        pricesList[i].weightUnity = list.weightUnity
      }
    }
  }

  const columnNames = ['category', 'cash', 'term']
  const columns: Column[] = []
  if (pricesList.length) {
    _.map(columnNames, (column: string) => {
      columns.push({
        title: column.charAt(0).toUpperCase() + i18next.t(column).slice(1),
        field: column,
        render: (rowData: Record<string, any>) => generalRender(rowData, column),
        cellStyle: { width: '300px' }
      })
    })
  }

  return (
    <div>
      <PriceModal
        isOpen={isPriceModalOpen}
        handleClose={() => setPriceModalStatus(false)}
        isNew={isNew}
        slaughterhouseId={slaughterhouseId}
      />
      {priceLoading ? (
        <Loading />
      ) : (
        <div>
          <MaterialTable
            isLoading={priceLoading}
            data={pricesList}
            columns={columns}
            style={{ width: 'auto' }}
            options={{
              header: !!pricesList?.length,
              toolbar: !!pricesList?.length,
              paging: false,
              toolbarButtonAlignment: 'left',
              searchFieldAlignment: 'left',
              showTitle: false,
              emptyRowsWhenPaging: false,
              addRowPosition: 'first',
              headerStyle: { fontSize: 16, fontWeight: 'bold' },
              pageSizeOptions: [pricesList?.length]
            }}
            actions={[{ icon: 'edit', tooltip: t('table.edit'), onClick: (_: any, data: any) => editAction(data) }]}
            components={{
              Container: (props: {
                children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
              }) => <div style={{ width: 'auto' }}>{props.children}</div>
            }}
          />
        </div>
      )}
    </div>
  )
}
export default PriceList
