import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'

import Header from '../Header'
import Sidebar from '../Sidebar'
import App from 'routers/App'
import Toast from 'components/widgets/Toast'
import { Props } from './types'
import './styles.scss'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { setHistory, toggleMainSidebar } from 'store/main/actions'
import { ApplicationState } from 'store/types'

const Container = (props: Props) => {
  const dispatch = useDispatch()
  const isMainSidebarOpened = useSelector((state: ApplicationState) => state.main.isMainSidebarOpened)

  const { history } = props

  dispatch(setHistory(history))
  return (
    <div className='container-root'>
      <CssBaseline />
      <Toast />
      <Header setOpen={() => dispatch(toggleMainSidebar())} />
      <Sidebar open={isMainSidebarOpened} />
      <div className='container-content'>
        <div className='container-toolbar' />
        <App />
      </div>
    </div>
  )
}

export default withRouter(Container)
