import { Lot } from 'store/feedlot/types'
import { ProcessedWeighing } from './weighing/types'

export type Breed = {
  id: number
  code?: string
  description?: string
}

export type Coat = {
  id: number
  code: string
  description: string
}

export type Gender = {
  id: number
  code: string
  description: string
}

export type AdditionalInfo = {
  info: {
    [key: string]: any
  }
}

export type Handling = {
  handlingId: number
  animalId: number
  ecc: number
  observation?: string
  entrySequence?: number
}

export type Animal = {
  id: number
  penId: number
  feedlotId: number
  lotId: number
  earring: string
  newLotId?: number
  active?: boolean
  tag?: string
  age?: number
  horn?: string
  ecc?: number
  sisbov?: string
  initialWeight?: number
  endDate?: Date
  breed: Breed
  coat: Coat
  gender: Gender
  delayedWeighingDays: number
  lastWeighingDate: Date
  weighing: ProcessedWeighing[]
  handling: Handling
  lot: Lot
  additionalField?: Record<string, any>
}

export type AnimalGroup = {
  id: number
  penId: number
  newLotId?: number
  active?: boolean
  age?: number
  ecc?: number
  endDate?: Date
  breed: Breed
  coat: Coat
  gender: Gender
  additionalField: Record<string, any>
}

export type AnimalState = {
  readonly animals: Animal[]
  readonly selectedAnimal?: Animal
  readonly animalLoading: boolean
  readonly selectedAnimalGroup?: [AnimalGroup]
  readonly animalGroupLoading: boolean
  readonly selectedAnimalFile?: any[]
  readonly breeds?: Breed[]
  readonly selectedBreed?: Breed
  readonly breedLoading: boolean
  readonly coats?: Coat[]
  readonly selectedCoat?: Coat
  readonly coatLoading: boolean
  readonly genders?: Gender[]
  readonly selectedGender?: Gender
  readonly genderLoading: boolean
  readonly disableAnimalLoading: boolean
  readonly disableAnimalsNumber?: number
}

export type DisableAnimalType = {
  id: number
  endDate: string
}

export enum AnimalTypes {
  REDUCER_FETCH_ANIMAL_DATA = 'REDUCER_FETCH_ANIMAL_DATA',
  REDUCER_CLEAR_ANIMAL_DATA = 'REDUCER_CLEAR_ANIMAL_DATA',
  ACTION_GET_BREED = 'ACTION_GET_BREED',
  ACTION_SET_SELECTED_BREED = 'ACTION_SET_SELECTED_BREED',
  ACTION_GET_COAT = 'ACTION_GET_COAT',
  ACTION_SET_SELECTED_COAT = 'ACTION_SET_SELECTED_COAT',
  ACTION_GET_GENDER = 'ACTION_GET_GENDER',
  ACTION_SET_SELECTED_GENDER = 'ACTION_SET_SELECTED_GENDER',
  ACTION_GET_ANIMAL = 'ACTION_GET_ANIMAL',
  ACTION_SET_SELECTED_ANIMAL = 'ACTION_SET_SELECTED_ANIMAL',
  ACTION_SAVE_ANIMAL = 'ACTION_SAVE_ANIMAL',
  ACTION_DISABLE_ANIMAL = 'ACTION_DISABLE_ANIMAL',
  ACTION_SET_SELECTED_ANIMAL_GROUP = 'ACTION_SET_SELECTED_ANIMAL_GROUP',
  ACTION_GET_ANIMAL_ADDITIONAL_INFO_TEMPLATE = 'ACTION_GET_ANIMAL_ADDITIONAL_INFO_TEMPLATE',
  ACTION_SET_SELECTED_ANIMAL_ADDITIONAL_INFO_TEMPLATE = 'ACTION_SET_SELECTED_ANIMAL_ADDITIONAL_INFO_TEMPLATE',
  ACTION_SET_SELECTED_ANIMAL_FILE = 'ACTION_SET_SELECTED_ANIMAL_FILE',
  ACTION_UPDATE_ANIMAL_LOT = 'ACTION_UPDATE_ANIMAL_LOT'
}
