import { Props } from './types'
import React from 'react'
import './styles.scss'

const Slider: React.FC<Props> = ({
  double,
  start_id,
  min_start,
  max_start,
  end_id,
  min_end,
  max_end,
  minHandleChange,
  maxHandleChange,
  default_min,
  default_max,
  children
}) => {
  return (
    <>
      <section className='range-slider'>
        <p className='rangeValues'>{children}</p>
        <input
          type='range'
          min={min_start}
          max={max_start}
          defaultValue={default_min}
          onChange={minHandleChange}
          className='slider'
          id={start_id}
        />
        {double ? (
          <input
            type='range'
            min={min_end}
            max={max_end}
            defaultValue={default_max}
            onChange={maxHandleChange}
            className='slider'
            id={end_id}
          />
        ) : (
          <></>
        )}
      </section>
    </>
  )
}
export default Slider
