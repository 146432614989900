import React from 'react'
import { Props } from './types'
import './styles.scss'
import { useTranslation } from 'react-i18next'

const Button: React.FC<Props> = ({ label, onClick, ...rest }) => {
  const { t } = useTranslation()
  return (
    <div className='main-button'>
      <button {...rest} onClick={onClick}>
        {t(label)}
      </button>
    </div>
  )
}
export default Button
