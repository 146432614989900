import { createStore, applyMiddleware, Store } from 'redux'
import createSagaMiddleware from 'redux-saga'

import authWatchers from './auth/watchers'
import customerWatchers from './customer/watchers'
import userWatchers from './user/watchers'
import animalWatchers from './animal/watchers'
import unprocessedAnimalsWatchers from './animal/unprocessedAnimals/watchers'
import weighingWatchers from './animal/weighing/watchers'
import feedlotWatchers from './feedlot/watchers'
import addressWatchers from './address/watchers'
import slaughterhouseWatchers from './slaughterhouse/watchers'
import economicWatchers from './economic/watchers'

import { ApplicationState } from './types'

import combinedReducers from './combinedReducers'

const sagaMiddleware = createSagaMiddleware()

const store: Store<ApplicationState> = createStore(combinedReducers, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(authWatchers)
sagaMiddleware.run(customerWatchers)
sagaMiddleware.run(userWatchers)
sagaMiddleware.run(animalWatchers)
sagaMiddleware.run(unprocessedAnimalsWatchers)
sagaMiddleware.run(weighingWatchers)
sagaMiddleware.run(feedlotWatchers)
sagaMiddleware.run(addressWatchers)
sagaMiddleware.run(slaughterhouseWatchers)
sagaMiddleware.run(economicWatchers)

export default store
