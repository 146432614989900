import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Props } from './types'
import { useField } from '@unform/core'
import _ from 'lodash'
import './styles.scss'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import ReactLoading from 'react-loading'
import { Option } from 'data/types'
import { colors } from 'styles/variables'

const Select: React.FC<Props> = ({
  name,
  label,
  defaultOptions,
  loading,
  handleChange,
  handleSelect,
  selectedValue,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const defaultValue = selectedValue
  const { fieldName, registerField, error } = useField(name)

  const [options, setOptions] = useState<Option[]>(defaultOptions || [])
  const [selected, setSelected] = useState<number>(defaultValue)
  const [text, setText] = useState<string>('')
  const [opened, setOpened] = useState<boolean>(false)
  const [cursor, setCursor] = useState<number>(-1)
  const getText = (value: number) => options.find((o: Option) => o.value === value)?.label || ''

  useEffect(
    () =>
      //registra o input como parte do formulário pai
      registerField({
        name: fieldName,
        ref: inputRef.current,
        // path: 'value', // não precisaria mais após mudar o getValue, mas é obrigatório
        getValue: () => selected
      }),
    [fieldName, registerField, selected]
  )

  useEffect(() => {
    //quando defaultOptions muda, seta o selecionado e as opções novamente
    setOptions(defaultOptions)
    setSelected(defaultValue)
    if (!defaultValue) setText('')
  }, [defaultOptions, defaultValue])

  useEffect(() => {
    //se o seletor está aberto, e ocorrem mudanças no defaultOptions, seta o foco novamente para o input ao re-renderizar
    if (opened) {
      if (!inputRef.current?.onfocus) inputRef.current?.focus()
    }
    //caso o seletor não esteja aberto, seta o texto como o label do selecionado (caso exista)
    else if (text.length === 0) {
      const resInList = defaultOptions.find((o: Option) => o.value === defaultValue)
      if (resInList) setText(resInList?.label)
    }
  }, [defaultOptions, defaultValue, opened, t, text.length])

  const handleClickOption = (value: number) => {
    if (value !== -1) {
      setSelected(value)
      if (handleSelect) handleSelect(value)
      setText(getText(value))
    }
    setOpened(false)
  }

  const getOptionByText = (value: string) => {
    setOptions(defaultOptions.filter((o: Option) => o.label.toUpperCase().includes(value.toUpperCase())))
  }

  const handleText = (e: any) => {
    const { value } = e.target
    if (handleChange) {
      //if (value.length <= 3) {
      //if (options.length !== 0) setOptions([])
      // } else if (value.length === 4) {
      if (!options.find((o: Option) => o.label.toUpperCase().includes(value.toUpperCase()))) {
        handleChange(value)
        inputRef.current?.focus()
      }
      //  } else getOptionByText(value)
    } else getOptionByText(value)
    getOptionByText(value)
    setText(value)
  }

  const handleKeyDown = (e: any) => {
    // enter = 13 / seta pra baixo = 40 / seta pra cima = 38
    if (e.keyCode === 13) {
      e.preventDefault()
      if (cursor !== -1) {
        setSelected(options[cursor].value)
        if (handleSelect) handleSelect(options[cursor].value)
        setText(options[cursor].label)
        setOpened(false)
        inputRef.current?.blur()
      }
    }
    if (e.keyCode === 40) {
      if (cursor >= options.length) setCursor(0)
      else cursor === options.length - 1 ? setCursor(0) : setCursor(cursor + 1)
    } else if (e.keyCode === 38) {
      cursor === 0 || cursor === -1 ? setCursor(options.length - 1) : setCursor(cursor - 1)
    }
  }
  return (
    <div className={!error ? 'div' : 'div-error'}>
      {error && <span>{t('field.' + error)}</span>}
      <div
        className={'select'}
        onFocus={() => setOpened(true)}
        onBlur={(e: any) => !e.currentTarget.contains(e.relatedTarget) && setOpened(false)}
      >
        <div className='select-input'>
          <input
            id={fieldName}
            ref={inputRef}
            value={text}
            disabled={disabled || loading}
            onChange={handleText}
            onKeyDown={handleKeyDown}
            {...rest}
          />
          {loading && <ReactLoading type='spin' width={20} height={20} color={colors.primary} />}
          {disabled ? (
            <></>
          ) : (
            <button className='icon' type='button' disabled={disabled || loading} onClick={() => setOpened(!opened)}>
              {opened ? <ExpandLess color='action' /> : <ExpandMore color='action' />}
            </button>
          )}
        </div>
        {opened && !loading && (
          <div className='options'>
            <ul tabIndex={1} onClick={(e: any) => handleClickOption(e.target.value)}>
              {options.length === 0 && <li value={-1}>{t('no_options_text')}</li>}
              {_.map(options, (o: any, index: number) => (
                <li
                  key={o.value}
                  className={`${selected === o.value ? 'checked' : ''} ${index === cursor ? ' focused' : ''}`}
                  value={o.value}
                >
                  {o.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {label && (
        <label className='label' htmlFor={fieldName}>
          {t('field.' + label)}
        </label>
      )}
    </div>
  )
}
export default Select
