import { Grid } from '@material-ui/core'
import React from 'react'
import i18next from 'i18next'
import { PenStatus } from 'store/feedlot/types'

export const generalRender = (rowData: any, type: string) => {
  if (type === 'status') {
    return (
      <Grid className={`field ${rowData.status === PenStatus.active ? 'active' : 'inactive'}`}>
        {rowData.status === PenStatus.active ? i18next.t('default.active') : i18next.t('default.inactive')}
      </Grid>
    )
  }
  return <Grid>{rowData[type] || i18next.t('table.not_registered')}</Grid>
}
