import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Props } from './types'
import { ApplicationState } from 'store/types'
import { EquipmentModel } from 'store/feedlot/types'

import { Option } from 'data/types'
import { getEquipmentModel, setSelectedEquipmentModel } from 'store/feedlot/actions'
import { EquipmentModelQueryFields } from 'services/equipmentModel/types'
import Select from 'components/widgets/Select'
import { useTranslation } from 'react-i18next'

const EquipmentModelSelector: React.FC<Props> = ({ selected }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { equipmentModels, equipmentModelLoading } = useSelector((state: ApplicationState) => state.feedlot)

  const [options, setOptions] = useState<Option[]>([])

  const formatEquipmentModels = (equipmentModels: EquipmentModel[]) =>
    equipmentModels.map((e: EquipmentModel) => ({ label: e.description || '', value: e.id || -1 }))

  const setSelected = (id: number) => {
    dispatch(setSelectedEquipmentModel(id))
  }

  useEffect(() => {
    if (!equipmentModels.length) {
      const fields: EquipmentModelQueryFields = {
        id: true,
        model: true,
        description: true,
        equipmentTypeId: true,
        manufacturer: { id: true, name: true }
      }
      dispatch(getEquipmentModel({ fields }))
    } else setOptions(formatEquipmentModels(equipmentModels))
  }, [equipmentModels, dispatch])

  return (
    <Select
      name='equipmentModel.id'
      label={t('field.description')}
      loading={equipmentModelLoading}
      defaultOptions={options}
      selectedValue={selected}
      handleSelect={(id: number) => setSelected(id)}
    />
  )
}
export default EquipmentModelSelector
