import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Props } from './types'
import { ApplicationState } from 'store/types'
import * as Yup from 'yup'
import './styles.scss'

import { SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Input from 'components/widgets/Input'
import DatePicker from 'components/widgets/DatePicker'
import Button from 'components/widgets/Button'
import _ from 'lodash'
import { User } from 'store/user/types'
import { completeStatusOptions } from 'data'
import InputMask from 'components/widgets/InputMask'
import Loading from 'components/widgets/Loading'
import Select from 'components/widgets/Select'
import { saveUser } from 'store/user/actions'

const UserForm: React.FC<Props> = ({ customerId, setUserModalStatus, isNew }) => {
  const formRef: any = useRef(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { selectedUser, userLoading } = useSelector((state: ApplicationState) => state.user)

  const buttonLabel = selectedUser?.id ? t('field.save') : t('field.add')
  const clearError = (e: any) => formRef.current?.setFieldError(e.target?.id, '')

  const handleSubmit: SubmitHandler<User> = async data => {
    const user: any = data
    user.customerId = customerId

    if (selectedUser) user.id = selectedUser?.id

    _.map(Object.entries(user), ([key, value]) => (user[key] = value === '' || undefined ? null : value))
    try {
      if (!customerId) return
      formRef.current.setErrors({})
      dispatch(saveUser({ user }))
      setUserModalStatus(false)
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }
  return (
    <div className='container'>
      {userLoading ? (
        <Loading />
      ) : (
        <Form ref={formRef} initialData={selectedUser} onSubmit={handleSubmit}>
          <>
            <div className='row'>
              <div className='col-6'>
                <Input name='email' label={t('field.email')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-4'>
                <Input name='name' label={t('field.name')} onFocus={clearError} />
              </div>
              <div className='col-2'>
                <Select
                  name='status'
                  label={t('field.status')}
                  defaultOptions={completeStatusOptions}
                  onFocus={clearError}
                  selectedValue={selectedUser?.status || 1}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-4'>
                <Input name='document' label={t('field.document')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-4'>
                <DatePicker name='dateOfBirth' label={t('field.dateOfBirth')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-4'>
                <InputMask name='phone' label={t('field.phone')} mask='(99) 99999-9999' onFocus={clearError} />
              </div>
            </div>
          </>
          <div className='row'>
            <div className='col-5 mtop-2'>
              <Button label={buttonLabel} />
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}
export default UserForm
