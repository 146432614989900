import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
// import { useTranslation } from 'react-i18next'
import { Props } from './types'
import { ApplicationState } from 'store/types'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { Column } from 'components/widgets/Table/types'
import _ from 'lodash'
import { generalRender, getTitle } from './helper'
import i18next from 'i18next'
import { setSelectedSlaughterhouse, getSlaughterhouses } from 'store/slaughterhouse/actions'
import BreadCrumb from 'components/layout/Breadcrumb'
import { Grid } from '@material-ui/core'
import { SlaughterhouseQueryFields } from 'services/slaughterhouse/types'
import { useTranslation } from 'react-i18next'
import MaterialTable from 'material-table'

const SlaughterhouseListScreen: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { slaughterhouses, slaughterhouseLoading } = useSelector((state: ApplicationState) => state.slaughterhouse)

  const handleOption = (to: string) => history.push(`slaughterhouses/${to}`)

  const onRowClick = (e: any, rowData: any) => {
    const slaughterhouseId = rowData.id
    setSelectedSlaughterhouse(slaughterhouseId)
    handleOption(slaughterhouseId)
  }

  useEffect(() => {
    const fields: SlaughterhouseQueryFields = {
      id: true,
      description: true,
      status: true,
      address: {
        cityDescription: true,
        stateDescription: true
      }
    }
    slaughterhouses.length === 0 && dispatch(getSlaughterhouses({ fields }))
  }, [slaughterhouses, slaughterhouseLoading, dispatch])

  const columnNames = ['description', 'status', 'address.cityDescription', 'address.stateDescription']
  const columns: Column[] = []
  if (slaughterhouses.length) {
    _.map(columnNames, (column: string) => {
      columns.push({
        title: getTitle(i18next.t(column)),
        field: column,
        render: (rowData: Record<string, any>) => generalRender(rowData, column),
        cellStyle: { width: 10 }
      })
    })
  }

  return (
    <Grid>
      <BreadCrumb
        items={[{ label: t('slaughterhouse.slaughterhouse', { count: 0 }), path: '/slaughterhouses', active: true }]}
      />
      <MaterialTable
        isLoading={slaughterhouseLoading}
        data={slaughterhouses}
        columns={columns}
        style={{ width: 'auto' }}
        options={{
          header: !!slaughterhouses.length,
          toolbar: !!slaughterhouses.length,
          // paging: !!slaughterhouses.length,
          toolbarButtonAlignment: 'left',
          searchFieldAlignment: 'left',
          showTitle: false,
          emptyRowsWhenPaging: false,
          addRowPosition: 'first',
          headerStyle: { fontSize: 16, fontWeight: 'bold' },
          pageSizeOptions: slaughterhouses.length < 5 ? [] : slaughterhouses.length < 10 ? [5, 10] : [5, 10, 20]
        }}
        onRowClick={onRowClick}
        actions={[
          {
            icon: () => <AddBoxIcon />,
            tooltip: i18next.t('slaughterhouse.new'),
            isFreeAction: true,
            onClick: () => handleOption('new')
          }
        ]}
        components={{
          Container: (props: {
            children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
          }) => <div style={{ width: 'auto' }}>{props.children}</div>
        }}
      />
    </Grid>
  )
}
export default withRouter(SlaughterhouseListScreen)
