import { takeLatest, all } from 'redux-saga/effects'
import { AnimalTypes } from 'store/animal/types'
import {
  workerGetBreed,
  workerSetSelectedBreed,
  workerGetCoat,
  workerSetSelectedCoat,
  workerGetGender,
  workerSetSelectedGender,
  workerGetAnimal,
  workerSetSelectedAnimal,
  workerSetSelectedAnimalGroup,
  workerSetSelectedAnimalFile,
  workerSaveAnimal,
  workerDisableAnimal,
  workerUpdateAnimalLot
} from './sagas'

export function* watcherGetBreed() {
  yield takeLatest(AnimalTypes.ACTION_GET_BREED, workerGetBreed)
}
export function* watcherSetSelectedBreed() {
  yield takeLatest(AnimalTypes.ACTION_SET_SELECTED_BREED, workerSetSelectedBreed)
}

export function* watcherGetCoat() {
  yield takeLatest(AnimalTypes.ACTION_GET_COAT, workerGetCoat)
}
export function* watcherSetSelectedCoat() {
  yield takeLatest(AnimalTypes.ACTION_SET_SELECTED_COAT, workerSetSelectedCoat)
}

export function* watcherGetGender() {
  yield takeLatest(AnimalTypes.ACTION_GET_GENDER, workerGetGender)
}
export function* watcherSetSelectedGender() {
  yield takeLatest(AnimalTypes.ACTION_SET_SELECTED_GENDER, workerSetSelectedGender)
}

export function* watcherGetAnimal() {
  yield takeLatest(AnimalTypes.ACTION_GET_ANIMAL, workerGetAnimal)
}
export function* watcherSetSelectedAnimal() {
  yield takeLatest(AnimalTypes.ACTION_SET_SELECTED_ANIMAL, workerSetSelectedAnimal)
}

export function* watcherSetSelectedAnimalGroup() {
  yield takeLatest(AnimalTypes.ACTION_SET_SELECTED_ANIMAL_GROUP, workerSetSelectedAnimalGroup)
}

export function* watcherSetSelectedAnimalFile() {
  yield takeLatest(AnimalTypes.ACTION_SET_SELECTED_ANIMAL_FILE, workerSetSelectedAnimalFile)
}
export function* watcherSaveAnimal() {
  yield takeLatest(AnimalTypes.ACTION_SAVE_ANIMAL, workerSaveAnimal)
}

export function* watcherUpdateAnimalLot() {
  yield takeLatest(AnimalTypes.ACTION_UPDATE_ANIMAL_LOT, workerUpdateAnimalLot)
}

export function* watcherDisableAnimal() {
  yield takeLatest(AnimalTypes.ACTION_DISABLE_ANIMAL, workerDisableAnimal)
}

export default function* authRootSaga() {
  yield all([
    watcherGetBreed(),
    watcherSetSelectedBreed(),
    watcherGetCoat(),
    watcherSetSelectedCoat(),
    watcherGetGender(),
    watcherSetSelectedGender(),
    watcherGetAnimal(),
    watcherSetSelectedAnimal(),
    watcherSetSelectedAnimalGroup(),
    watcherSetSelectedAnimalFile(),
    watcherSaveAnimal(),
    watcherDisableAnimal(),
    watcherUpdateAnimalLot()
  ])
}
