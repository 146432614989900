import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Props } from './types'
import { ApplicationState } from 'store/types'
import { WeightUnity } from 'store/economic/types'

import { Option } from 'data/types'
import { setSelectedWeightUnity, getWeightUnity } from 'store/economic/actions'
import { WeightUnityQueryFields } from 'services/economic/types'
import Select from 'components/widgets/Select'
import { useTranslation } from 'react-i18next'

const WeightUnitySelector: React.FC<Props> = ({ selected, cleanError }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { weightUnits, weightUnityLoading } = useSelector((state: ApplicationState) => state.economic)

  const [options, setOptions] = useState<Option[]>([])

  const formatWeightUnitys = (weightUnits: WeightUnity[]) =>
    weightUnits.map((e: WeightUnity) => ({ label: e.symbol || '', value: e.id || -1 }))

  const setSelected = (id: number) => {
    dispatch(setSelectedWeightUnity(id))
  }

  useEffect(() => {
    if (!weightUnits?.length) {
      const fields: WeightUnityQueryFields = {
        id: true,
        code: true,
        symbol: true
      }
      dispatch(getWeightUnity({ fields }))
    } else setOptions(formatWeightUnitys(weightUnits))
  }, [weightUnits, dispatch])

  return (
    <Select
      name='weightUnity.id'
      label={t('field.weightUnity')}
      loading={weightUnityLoading}
      defaultOptions={options}
      selectedValue={selected}
      handleSelect={(id: number) => setSelected(id)}
      onFocus={cleanError}
    />
  )
}
export default WeightUnitySelector
