import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Props, SettingsMenuItem } from './types'
import './styles.scss'

import { AppBar, Toolbar, Typography, Icon, IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import { signOut } from 'store/auth/actions'
import SettingsMenu from 'components/widgets/SettingsMenu'

const Header = (props: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dropItems: SettingsMenuItem[] = [
    { key: 'sign_out', icon: <Icon>exit_to_app</Icon>, onClick: () => dispatch(signOut()) }
  ]
  return (
    <AppBar position='fixed'>
      <Toolbar className='header-appBar'>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={props.setOpen}
          edge='start'
          className='header-menuButton'
        >
          <MenuIcon />
        </IconButton>
        <Typography variant='h6' noWrap>
          {t('default.appName')}
        </Typography>
        <SettingsMenu items={dropItems} />
      </Toolbar>
    </AppBar>
  )
}
export default Header
