import { select, put, call } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { WeighingTypes, ProcessedWeighing } from './types'
import { workerCheckAuthentication } from 'store/auth/sagas'
import { processedWeighingQuery, deleteGrossWeighings } from 'services/animal/weighing'
import { AddressTypes } from 'store/address/types'
import Exception from 'helpers/exception'
import { UNEXPECTED_ERROR_CODE, WEIGHING_NOT_FOUND_OR_INACTIVE_ERROR } from 'helpers/EventCodes'
import { MainTypes } from 'store/main/types'
import { ProcessedWeighingQueryParams } from 'services/animal/weighing/types'
import _ from 'lodash'
import { Animal, AnimalTypes } from '../types'
import { animalQuery } from 'services/animal'

export function* workerGetProcessedWeighing(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: WeighingTypes.REDUCER_FETCH_WEIGHING_DATA, payload: { weghingLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    const weghings = yield call(processedWeighingQuery, credentials.token, params)

    yield put({
      type: WeighingTypes.REDUCER_FETCH_WEIGHING_DATA,
      payload: {
        selectedProcessedWeighing: undefined,
        weghings: [...weghings],
        weghingLoading: false,
        users: [],
        pens: [],
        equipment: [],
        lots: [],
        animals: []
      }
    })
    yield put({ type: AddressTypes.REDUCER_FETCH_ADDRESS_DATA, payload: { locations: [] } })
  } catch (error) {
    let message = null
    if (error instanceof Exception) message = error.getMessage()
    else message = new Exception(UNEXPECTED_ERROR_CODE, 'weghing/sagas/workerGetProcessedWeighing', error).getMessage()
    yield put({ type: WeighingTypes.REDUCER_FETCH_WEIGHING_DATA, payload: { weghingLoading: false } })
    yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
  }
}

export function* workerSetSelectedProcessedWeighing(action: AnyAction) {
  const store = yield select(s => s)
  const { weghingId } = action.payload
  try {
    yield put({ type: WeighingTypes.REDUCER_FETCH_WEIGHING_DATA, payload: { weghingLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    let selectedProcessedWeighing: ProcessedWeighing | undefined

    if (weghingId) {
      const params: ProcessedWeighingQueryParams = { args: { id: weghingId } }
      const weghingList = yield call(processedWeighingQuery, credentials.token, params)

      const mainStore = store.main
      const { history } = mainStore
      if (weghingList.length) selectedProcessedWeighing = weghingList[0]
      else {
        history.push('/weghings')
        throw new Exception(WEIGHING_NOT_FOUND_OR_INACTIVE_ERROR, 'weghings/sagas/workerSetSelectedProcessedWeighing')
      }
    }
    yield put({
      type: WeighingTypes.REDUCER_FETCH_WEIGHING_DATA,
      payload: {
        selectedProcessedWeighing: selectedProcessedWeighing ? { ...selectedProcessedWeighing } : undefined,
        weghingLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'weghing/sagas/workerSaveProcessedWeighing', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: WeighingTypes.REDUCER_FETCH_WEIGHING_DATA, payload: { weghingLoading: false } })
  }
}

export function* workerDeleteGrossWeighings(action: AnyAction) {
  const { args } = action.payload
  try {
    yield put({ type: WeighingTypes.REDUCER_FETCH_WEIGHING_DATA, payload: { weighingLoading: true } })
    yield call(workerCheckAuthentication)

    const store = yield select(s => s)
    const authStore = store.auth
    const animalStore = store.animal

    const { animals } = animalStore
    const { credentials } = authStore

    yield call(deleteGrossWeighings, credentials.token, args)
    const index = _.findIndex(animals, (a: Animal) => a.id === args.animalId)
    animals[index] = (yield call(animalQuery, credentials.token, { args: { id: args.animalId } }))[0]
    const animal = animals[index]

    yield put({
      type: WeighingTypes.REDUCER_FETCH_WEIGHING_DATA,
      payload: { weighingLoading: false }
    })

    yield put({
      type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA,
      payload: { animals: [...animals], selectedAnimal: { ...animal } }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'animal/weighing/sagas/workerDeleteGrossWeighings', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: WeighingTypes.REDUCER_FETCH_WEIGHING_DATA, payload: { weighingLoading: false } })
  }
}
