import React from 'react'
import { Props } from './types'
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ScatterChart, ZAxis } from 'recharts'

const Scatterchart: React.FC<Props> = ({
  width,
  height,
  xAxisDataKey,
  xAxisName,
  xAxisUnit,
  yAxisDataKey,
  yAxisName,
  yAxisUnit,
  zAxisDataKey,
  zAxisRange,
  zAxisName,
  zAxisUnit,
  chartData,
  children
}) => {
  return (
    <ScatterChart width={width} height={height} data={chartData} margin={{ top: 20, right: 20, bottom: 10, left: 10 }}>
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey={xAxisDataKey} name={xAxisName} type={'number'} unit={xAxisUnit} allowDuplicatedCategory={false} />
      <YAxis dataKey={yAxisDataKey} name={yAxisName} unit={yAxisUnit} />
      <ZAxis dataKey={zAxisDataKey} range={zAxisRange} name={zAxisName} unit={zAxisUnit} />
      <Tooltip cursor={{ strokeDasharray: '3 3' }} />
      <Legend />
      {children}
    </ScatterChart>
  )
}

export default Scatterchart
