import { Grid } from '@material-ui/core'
import React from 'react'
import i18next from 'i18next'

export const generalRender = (rowData: any, type: string) => {
  if (type === 'status') {
    return (
      <Grid className={`field ${rowData.status === 1 ? 'active' : 'inactive'}`}>
        {rowData.status === 1 ? i18next.t('default.active') : i18next.t('default.inactive')}
      </Grid>
    )
  }
  // if (type === 'address.cityStateDescription') {
  //   return <Grid>{rowData.address?.cityStateDescription || i18next.t('table.not_registered')}</Grid>
  // }
  if (type === 'address.cityDescription') {
    return <Grid>{rowData.address?.cityDescription || i18next.t('table.not_registered')}</Grid>
  }
  if (type === 'address.stateDescription') {
    return <Grid>{rowData.address?.stateDescription || i18next.t('table.not_registered')}</Grid>
  }
  return <Grid>{rowData[type] || i18next.t('table.not_registered')}</Grid>
}

export const getTitle = (column: string) => {
  switch (column) {
    case 'cityDescription':
      return i18next.t('address.city')
    case 'stateDescription':
      return i18next.t('address.region')
    default:
      return i18next.t('field.' + column)
  }
}
