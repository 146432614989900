import { Option } from './types'

export const statusOptions: Option[] = [
  { value: 0, label: 'inactive' },
  { value: 1, label: 'active' }
]

export const completeStatusOptions: Option[] = [
  { value: 0, label: 'inactive' },
  { value: 1, label: 'active' }
]

export const equipmenModelType: Option[] = [
  { value: 1, label: 'scale' },
  { value: 2, label: 'camera' }
]
