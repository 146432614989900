import gql from 'graphql-tag'
import { AuthRequestParams, Credentials } from './types'
import { UNEXPECTED_ERROR_CODE } from '../../helpers/EventCodes'
import Exception from '../../helpers/exception'
import graphql from 'services'
import getFormattedQuery from 'helpers/getFormattedQuery'

export const authRequest = async (params: AuthRequestParams): Promise<Credentials> => {
  if (!params.fields) params.fields = { username: true, name: true, token: true }
  const MUTATION = gql`
    mutation MUTATION($username: String!, $password: String!) {
      signIn(authUser: {username: $username, password: $password}) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.mutate(MUTATION, { ...params.args })
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/auth/authRequest', errors)
    }
    return data.signIn
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/auth/authRequest', error)
  }
}

export const validateToken = async (token: string) => {
  const MUTATION = gql`
    mutation MUTATION {
      validate
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, {}, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/auth/validateToken', errors)
    }
    return data.validate
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/auth/validateToken', error)
  }
}
