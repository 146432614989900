import { AddressTypes, AddressState } from './types'

const INITIAL_STATE: AddressState = {
  addresses: [],
  selectedCountryId: undefined,
  selectedRegionId: undefined,
  selectedCityId: undefined,
  countries: [],
  regions: [],
  cities: [],
  loading: false
}

export default (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case AddressTypes.REDUCER_CLEAR_ADDRESS_DATA:
      return { ...state, ...INITIAL_STATE, checkingAuthentication: false }
    case AddressTypes.REDUCER_FETCH_ADDRESS_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
