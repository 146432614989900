import { UserQueryParams, UserMutationArgs, UserPermissionsMutationArgs } from 'services/user/types'
import { action } from 'typesafe-actions'
import { UserTypes } from './types'

export const getUsers = (params?: UserQueryParams) => action(UserTypes.ACTION_GET_USER, { params })
export const setSelectedUser = (userId?: number) => action(UserTypes.ACTION_SET_SELECTED_USER, { userId })
export const saveUserPermissions = (args: UserPermissionsMutationArgs) =>
  action(UserTypes.ACTION_SAVE_USER_PERMISSIONS, { args })
export const saveUser = (args: UserMutationArgs) => action(UserTypes.ACTION_SAVE_USER, { args })
export const getUserEmails = (params?: UserQueryParams) => action(UserTypes.ACTION_GET_USER_EMAILS, { params })
