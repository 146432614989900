import { Grid } from '@material-ui/core'
import React from 'react'
import i18next from 'i18next'
import moment from 'moment'

export const generalRender = (rowData: any, type: string) => {
  if (type === 'initDate' || type === 'endDate') {
    return (
      <Grid>
        {rowData[type] ? moment(rowData[type]).format('DD/MM/YYYY HH:mm:ss') : i18next.t('field.not_registered')}
      </Grid>
    )
  }
  return <Grid>{rowData[type] || i18next.t('table.not_registered')}</Grid>
}

export const getTitle = (column: string) => {
  switch (column) {
    case 'initDate':
      return i18next.t('field.start')
    case 'endDate':
      return i18next.t('field.end')
    case 'animalsCount':
      return i18next.t('field.totalAnimals')
    case 'animalsDelayedWeighingCount':
      return i18next.t('field.totalOfDelayedWeighingAnimals')
    case 'registerStatus':
      return i18next.t('field.register_status')
    default:
      return i18next.t('field.' + column)
  }
}
