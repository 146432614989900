export type Customer = {
  id: number
  email: string
  name?: string
  document?: string
  phone?: string
  cityId?: number
  cityDescription?: string
  stateId?: number
  stateDescription?: string
  stateCode?: string
  countryId?: number
  countryDescription?: string
}

export type CustomerState = {
  readonly selectedCustomer?: Customer
  readonly customers: Customer[]
  readonly customerLoading: boolean
}

export enum CustomerTypes {
  REDUCER_FETCH_CUSTOMER_DATA = 'REDUCER_FETCH_CUSTOMER_DATA',
  REDUCER_CLEAR_CUSTOMER_DATA = 'REDUCER_CLEAR_CUSTOMER_DATA',
  ACTION_GET_CUSTOMERS = 'ACTION_GET_CUSTOMERS',
  ACTION_SET_SELECTED_CUSTOMER = 'ACTION_SET_SELECTED_CUSTOMER',
  ACTION_SAVE_CUSTOMER = 'ACTION_SAVE_CUSTOMER'
}
