import React, { useRef, useEffect, useState } from 'react'
import { Props } from './types'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { useField } from '@unform/core'
import ToggleSwitch from 'react-switch'
import { colors } from 'styles/variables'

const Switch: React.FC<Props> = ({ name, label, handleChange, checkValue }) => {
  const switchRef: any = useRef(null)
  const { fieldName, defaultValue, registerField } = useField(name)

  const [checked, setChecked] = useState<boolean>(defaultValue || checkValue || false)

  useEffect(() => {
    registerField({ name: fieldName, path: 'props.checked', ref: switchRef.current })
  }, [fieldName, registerField])
  const { t } = useTranslation()

  return (
    <div className='div-row'>
      {label && <label htmlFor={fieldName}>{t(label || '')}</label>}
      <ToggleSwitch
        className='switch'
        id={fieldName}
        ref={switchRef}
        checked={checked}
        onChange={() => {
          setChecked(!checked)
          handleChange(!checked)
        }}
        onColor={colors.primary}
        uncheckedIcon={false}
        checkedIcon={false}
        height={25}
        width={50}
        activeBoxShadow={`0 0 2px 3px ${colors.secondary}`}
      />
    </div>
  )
}
export default Switch
