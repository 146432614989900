import React, { useState, useEffect } from 'react'
import { withRouter, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'

import { Props } from './types'
import ExpandableCard from 'components/widgets/ExpandableCard'
import CustomerForm from 'components/forms/CustomerForm'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import { setSelectedCustomer } from 'store/customer/actions'
import BreadCrumb from 'components/layout/Breadcrumb'
import { ApplicationState } from 'store/types'
import UserList from 'components/lists/UserList'

const CustomerScreen: React.FC<Props> = ({ match }) => {
  const [panelExpanded, setPanelStatus] = useState<string | boolean>(false)
  const [isModalOpened, setModalStatus] = useState<boolean>(false)
  const [isEditingGeneral, setEditingGeneral] = useState<boolean>(false)
  const [clickedPanel, setClickedPanel] = useState<string | boolean>(false)

  const { selectedCustomer, customerLoading } = useSelector((state: ApplicationState) => state.customer)

  const dispatch = useDispatch()

  useEffect(() => {
    const { id } = match.params
    if (!id) {
      setPanelStatus('general')
      setClickedPanel('general')
    }
    dispatch(setSelectedCustomer(Number(id) || undefined))
  }, [dispatch, match.params])

  const handleExpanded = (panel: string) => (e: React.ChangeEvent<Record<string, any>>, isExpanded: boolean) => {
    if (panel !== panelExpanded) setClickedPanel(panel)
    isEditingGeneral ? setModalStatus(true) : isExpanded ? setPanelStatus(panel) : setPanelStatus(false)
  }
  const handleEditing = (active: boolean) => setEditingGeneral(active)

  const handleConfirm = () => {
    let panel: boolean | string = false
    if (clickedPanel !== panelExpanded) panel = clickedPanel
    setModalStatus(false)
    setPanelStatus(panel)
    setEditingGeneral(false)
  }

  const handleCancel = () => setModalStatus(false)

  const { id } = useParams()
  const isRegistration = id ? false : true
  const label = selectedCustomer ? selectedCustomer.name : ''

  return (
    <>
      <BreadCrumb
        items={[
          { label: i18next.t('customer.customer', { coutn: 0 }), path: '/customers', active: false },
          {
            label: label || i18next.t('default.registration'),
            path: '/customers',
            active: true,
            loading: customerLoading
          }
        ]}
      />
      <ExpandableCard
        id='general'
        title={isRegistration ? i18next.t('customer.registration') : i18next.t('default.general_information')}
        defaultExpanded={isRegistration}
        expanded={panelExpanded === 'general'}
        onChange={handleExpanded('general')}
      >
        <CustomerForm onChangeForm={handleEditing} />
      </ExpandableCard>
      {!isRegistration && (
        <ExpandableCard
          id='users'
          title={i18next.t('user.user', { count: 0 })}
          expanded={panelExpanded === 'userList'}
          onChange={handleExpanded('userList')}
        >
          <UserList customerId={Number(id)} />
        </ExpandableCard>
      )}
      <ConfirmationModal
        open={isModalOpened}
        title={i18next.t('confirmation.general_close_confirm_title')}
        text={i18next.t('confirmation.general_close_confirm_text')}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    </>
  )
}
export default withRouter(CustomerScreen)
