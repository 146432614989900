import { SlaughterhouseState, SlaughterhouseTypes } from './types'

const INITIAL_STATE: SlaughterhouseState = {
  selectedSlaughterhouse: undefined,
  slaughterhouses: [],
  slaughterhouseLoading: false
}

export default (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SlaughterhouseTypes.REDUCER_CLEAR_SLAUGHTERHOUSE_DATA:
      return { ...state, ...INITIAL_STATE, checkingAuthentication: false }
    case SlaughterhouseTypes.REDUCER_FETCH_SLAUGHTERHOUSE_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
