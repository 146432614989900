import gql from 'graphql-tag'
import { CountryQueryParams, StateQueryParams, CityQueryParams, AddressQueryParams } from './types'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import graphql from 'services'
import { Country, Region, City, Address } from 'store/address/types'
import getFormattedQuery from 'helpers/getFormattedQuery'

export const countryQuery = async (token: string, params: CountryQueryParams): Promise<Country[]> => {
  if (!params.fields) params.fields = { id: true, description: true }
  const QUERY = gql`
    query QUERY($id: Int) {
      country(id: $id) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/address/countryQuery', errors)
    }
    return data.country
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/address/countryQuery', error)
  }
}

export const stateQuery = async (token: string, params: StateQueryParams): Promise<Region[]> => {
  if (!params.fields) params.fields = { id: true, description: true }
  const QUERY = gql`
    query QUERY($id: Int) {
      state(id: $id) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/address/stateQuery', errors)
    }
    return data.state
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/address/stateQuery', error)
  }
}

export const cityQuery = async (token: string, params: CityQueryParams): Promise<City[]> => {
  if (!params.fields) params.fields = { id: true, description: true }
  const QUERY = gql`
    query QUERY($id: Int, $description: String) {
      city(id: $id, description: $description) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/address/cityQuery', errors)
    }
    return data.city
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/address/cityQuery', error)
  }
}

export const addressQuery = async (token: string, params: AddressQueryParams): Promise<Address[]> => {
  if (!params.fields) params.fields = { cityId: true, cityStateDescription: true }
  const QUERY = gql`
    query QUERY(
      $cityDescription: String, 
      $stateDescription: String, 
      $countryDescription: String, 
      $cityId: Int, 
      $stateId: Int, 
      $countryId: Int
    ) {
      address(
        cityDescription: $cityDescription, 
        stateDescription: $stateDescription, 
        countryDescription: $countryDescription, 
        cityId: $cityId, 
        stateId: $stateId, 
        countryId: $countryId
        ) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/address/addressQuery', errors)
    }
    return data.address
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/address/addressQuery', error)
  }
}
