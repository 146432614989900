import { AnimalTypes, AnimalState } from './types'

const INITIAL_STATE: AnimalState = {
  animals: [],
  animalLoading: false,
  animalGroupLoading: false,
  breedLoading: false,
  coatLoading: false,
  genderLoading: false,
  disableAnimalLoading: false
}

export default (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case AnimalTypes.REDUCER_CLEAR_ANIMAL_DATA:
      return { ...state, ...INITIAL_STATE, checkingAuthentication: false }
    case AnimalTypes.REDUCER_FETCH_ANIMAL_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
