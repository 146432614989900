import React from 'react'
import { Props } from './types'

import Modal from 'components/widgets/Modal'
import LotForm from 'components/forms/LotForm'
import i18next from 'i18next'

const LotModal: React.FC<Props> = ({ handleClose, isOpen, feedlotId, penId, isNew }) => (
  <Modal
    handleClose={handleClose}
    open={isOpen}
    title={isNew ? i18next.t('lot.registration') : i18next.t('default.general_information')}
  >
    <LotForm feedlotId={feedlotId || 0} penId={penId || 0} />
  </Modal>
)

export default LotModal
