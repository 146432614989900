import { Slaughterhouse } from 'store/slaughterhouse/types'

export type Price = {
  id: number
  mi_nel_vista: number
  mc_nel_prazo: number
  mi_nel_prazo: number
  mi_cruz_vista: number
  mc_cruz_vista: number
  mi_cruz_prazo: number
  mc_cruz_prazo: number
  nv_nel_vista: number
  nv_nel_prazo: number
  vc_nel_vista: number
  vc_nel_prazo: number
  nv_cruz_vista: number
  nv_cruz_prazo: number
  mc_nel_vista: number
  slaughterhouse: Slaughterhouse
  currency: Currency
  weightUnity: WeightUnity
}

export type CategoryPrice = {
  id: number
  category: string
  value: string
  cash: number
  term: number
  currency: Currency
  weightUnity: WeightUnity
}

export type Currency = {
  id: number
  code: string
  symbol: string
  description: string
}

export type WeightUnity = {
  id: number
  code: string
  symbol: string
}

export type EconomicState = {
  readonly selectedPrice?: CategoryPrice
  readonly price?: Price
  readonly priceLoading: boolean
  readonly pricesList: CategoryPrice[]
  readonly selectedCurrency?: Currency
  readonly currencies?: Currency[]
  readonly currencyLoading: boolean
  readonly selectedWeightUnity?: CategoryPrice
  readonly weightUnits?: WeightUnity[]
  readonly weightUnityLoading: boolean
}

export enum EconomicTypes {
  REDUCER_FETCH_ECONOMIC_DATA = 'REDUCER_FETCH_ECONOMIC_DATA',
  REDUCER_CLEAR_ECONOMIC_DATA = 'REDUCER_CLEAR_ECONOMIC_DATA',
  ACTION_GET_PRICE = 'ACTION_GET_PRICE',
  ACTION_SET_SELECTED_PRICE = 'ACTION_SET_SELECTED_PRICE',
  ACTION_SAVE_PRICE = 'ACTION_SAVE_PRICE',
  ACTION_SET_SELECTED_CURRENCY = 'ACTION_SET_SELECTED_CURRENCY',
  ACTION_SET_SELECTED_WEIGHTUNITY = 'ACTION_SET_SELECTED_WEIGHTUNITY',
  ACTION_GET_CURRENCY = 'ACTION_GET_CURRENCY',
  ACTION_GET_WEIGHTUNITY = 'ACTION_GET_WEIGHTUNITY'
}
