import React from 'react'
import { Props } from './types'

import Modal from 'components/widgets/Modal'
import EquipmentForm from 'components/forms/EquipmentForm'
import i18next from 'i18next'

const EquipmentModal: React.FC<Props> = ({ handleClose, isOpen, feedlotId, penId, isNew }) => (
  <Modal
    handleClose={handleClose}
    open={isOpen}
    title={isNew ? 'equipment.registration' : i18next.t('default.general_information')}
  >
    <EquipmentForm feedlotId={feedlotId || 0} penId={penId || 0} />
  </Modal>
)

export default EquipmentModal
