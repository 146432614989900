import { useDispatch, useSelector } from 'react-redux'
import { withRouter, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ApplicationState } from 'store/types'
import { Column } from 'components/widgets/Table/types'
import { getLot, setSelectedLot } from 'store/feedlot/actions'
import { Props } from './types'
import { Dispatch } from 'redux'
import _ from 'lodash'
import { generalRender, getTitle } from './helper'
import LotModal from 'components/modals/LotModal'
import { useTranslation } from 'react-i18next'
import MaterialTable from 'material-table'
import { Icon, IconButton } from '@material-ui/core'
import OxIcon from '../../../images/icons/beeftrader-ox-icon'
import { colors } from 'styles/variables'

const LotList: React.FC<Props> = ({ feedlotId, penId }) => {
  const history = useHistory()
  const dispatch: Dispatch = useDispatch()

  const [isLotModalOpen, setLotModalStatus] = useState<boolean>(false)
  const [isNew, setIsNew] = useState<boolean>(true)

  const loading = useSelector((state: ApplicationState) => state.feedlot.lotLoading)
  const lots = useSelector((state: ApplicationState) => state.feedlot.lots)

  useEffect(() => {
    dispatch(
      getLot({
        args: { penId }
      })
    )
  }, [dispatch, penId])

  const editAction = (selectedLot: any) => {
    if (selectedLot.id) setIsNew(false)
    dispatch(setSelectedLot(selectedLot.id))
    setLotModalStatus(true)
  }

  const handleOption = (to: string) => history.push(`/feedlots/${feedlotId}/pens/${penId}/lots/${to}`)

  const openAnimalsScreen = (selectedLot: any) => {
    dispatch(setSelectedLot(selectedLot.id))
    handleOption(selectedLot.id)
  }

  const columnNames = ['description', 'code', 'initDate', 'endDate', 'animalsCount', 'animalsDelayedWeighingCount']
  const columns: Column[] = []
  if (lots.length) {
    _.map(columnNames, (column: string) => {
      columns.push({
        title: getTitle(column),
        field: column,
        render: (rowData: Record<string, any>) => generalRender(rowData, column),
        cellStyle: { width: 10 }
      })
    })
  }
  const { t } = useTranslation()
  return (
    <div>
      <LotModal
        isOpen={isLotModalOpen}
        handleClose={() => setLotModalStatus(false)}
        feedlotId={feedlotId}
        penId={penId}
        isNew={isNew}
      />
      <MaterialTable
        isLoading={loading}
        columns={columns}
        data={lots}
        style={{ width: 'auto' }}
        options={{
          header: !!lots.length,
          toolbar: true,
          // paging: !!lots.length,
          toolbarButtonAlignment: 'left',
          searchFieldAlignment: 'left',
          showTitle: false,
          emptyRowsWhenPaging: false,
          addRowPosition: 'first',
          headerStyle: { fontSize: 16, fontWeight: 'bold' },
          pageSizeOptions: lots.length < 5 ? [] : lots.length < 10 ? [5, 10] : [5, 10, 20]
        }}
        actions={[
          { icon: 'add', tooltip: t('lot.new'), onClick: (_: any, data: any) => editAction(data), isFreeAction: true },
          { icon: 'edit', tooltip: t('table.edit'), onClick: (_: any, data: any) => editAction(data) },
          {
            icon: () => {
              return (
                <div className='card-icon-container'>
                  <OxIcon width={'2rem'} height={'2rem'} fill={colors.primary} />
                </div>
              )
            },
            tooltip: t('animal.animal_plural'),
            onClick: (_: any, data: any) => openAnimalsScreen(data)
          }
        ]}
        components={{
          Container: (props: {
            children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
          }) => <div style={{ width: 'auto' }}>{props.children}</div>
        }}
      />
    </div>
  )
}

export default withRouter(LotList)
