import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Props } from './types'
import { ApplicationState } from 'store/types'
import _ from 'lodash'
import * as Yup from 'yup'

import { saveCustomer } from 'store/customer/actions'

import Button from 'components/widgets/Button'
import { Form } from '@unform/web'
import { SubmitHandler } from '@unform/core'
import { Customer } from 'store/customer/types'
import Input from 'components/widgets/Input'
import InputMask from 'components/widgets/InputMask'
import Loading from 'components/widgets/Loading'
import AddressSelector from 'components/selectors/AddressSelector'
import { useTranslation } from 'react-i18next'

const CustomerForm: React.FC<Props> = ({ onChangeForm }) => {
  const { t } = useTranslation()
  const formRef: any = useRef(null)
  const dispatch = useDispatch()

  const selectedCustomer = useSelector((state: ApplicationState) => state.customer.selectedCustomer)
  // console.log(selectedCustomer)
  const loading = useSelector((state: ApplicationState) => state.customer.customerLoading)

  const buttonLabel = selectedCustomer?.id ? 'save' : 'add'

  const clearError = (e: any) => formRef.current?.setFieldError(e.target?.id, '')

  const handleSubmit: SubmitHandler<Customer> = async data => {
    const customer: any = data
    customer.id = selectedCustomer?.id
    _.map(Object.entries(customer), ([key, value]) => (customer[key] = value === '' ? null : value))
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        email: Yup.string()
          .required(() => t('field.required'))
          .email(() => 'invalid_email')
          .nullable()
      })
      await schema.validate(customer, { abortEarly: false })
      dispatch(saveCustomer({ customer }))
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }

  return (
    <div className='container'>
      {loading ? (
        <Loading />
      ) : (
        <Form ref={formRef} initialData={selectedCustomer} onSubmit={handleSubmit} onChange={() => onChangeForm(true)}>
          <div className='row'>
            <div className='col-4'>
              <Input name='name' label={t('customer.name')} onFocus={clearError} />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <Input name='document' label={t('customer.document')} onFocus={clearError} />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <Input name='email' label={t('field.email')} onFocus={clearError} />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <InputMask name='phone' label={t('field.phone')} mask='(99) 99999-9999' onFocus={clearError} />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <AddressSelector selected={selectedCustomer?.cityId} />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <Button label={buttonLabel} />
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}
export default CustomerForm
