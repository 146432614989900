import React, { useState, useEffect } from 'react'
import { Props } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'
import { Option } from 'data/types'
import { getAddress } from 'store/address/actions'
import Select from 'components/widgets/Select'
import { Address } from 'store/address/types'
import { useTranslation } from 'react-i18next'

const AddressSelector: React.FC<Props> = ({ selected }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { addresses, loading } = useSelector((state: ApplicationState) => state.address)

  const [options, setOptions] = useState<Option[]>([])

  const formatAddress = (addresses: Address[]) =>
    addresses.map((l: Address) => ({ label: l.cityStateDescription || '', value: l.cityId || -1 }))

  useEffect(() => setOptions(formatAddress(addresses)), [addresses])

  return (
    <Select
      name='cityId'
      label={t('address.city')}
      loading={loading}
      defaultOptions={options}
      handleChange={(cityDescription: string) => dispatch(getAddress({ args: { cityDescription } }))}
      selectedValue={selected}
    />
  )
}
export default AddressSelector
