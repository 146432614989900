import React from 'react'
import { Props } from './types'

import { CardContent, Card } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const SimpleCard: React.FC<Props> = ({ title, tooltip, body, legend, style, children }) => {
  return (
    <Card style={style} title={tooltip}>
      <CardContent>
        <Typography id='title' color='textSecondary' gutterBottom>
          {title}
        </Typography>
        <Typography variant='h3'>
          {body}
          {children}
        </Typography>
        <Typography id='legend' variant='h6'>
          {legend}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default SimpleCard
