import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory, withRouter } from 'react-router-dom'
import { Box, Grid } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import i18next from 'i18next'
import { ApplicationState } from 'store/types'
import { Props, State } from './types'
import { getCustomers, setSelectedCustomer } from 'store/customer/actions'

import { generalRender } from './helper'
import { CustomerQueryFields } from 'services/customer/types'
import BreadCrumb from 'components/layout/Breadcrumb'
import MaterialTable from 'material-table'

const CustomerListScreen = () => {
  const { customers, customerLoading } = useSelector((state: ApplicationState) => state.customer)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const fields: CustomerQueryFields = { id: true, name: true, email: true }
    if (!customers.length) dispatch(getCustomers({ fields }))
  }, [customers])
  const handleOption = (to: string) => history.push(`${location.pathname}/${to}`)
  const onRowClick = (e: any, rowData: any) => {
    const customerId = rowData.id
    setSelectedCustomer(customerId)
    handleOption(customerId)
  }

  return (
    <Grid>
      <BreadCrumb items={[{ label: i18next.t('customer.customer', { count: 0 }), path: '/customers', active: true }]} />
      <MaterialTable
        isLoading={customerLoading}
        title={<Box className='title'>{i18next.t('customer.list_title')}</Box>}
        columns={[
          {
            title: i18next.t('customer.name'),
            field: 'name',
            render: (rowData: any) => generalRender(rowData, 'name')
          },
          {
            title: i18next.t('field.email'),
            field: 'email',
            render: (rowData: any) => generalRender(rowData, 'email')
          }
        ]}
        data={customers}
        options={{
          emptyRowsWhenPaging: false,
          addRowPosition: 'first',
          headerStyle: { fontSize: 16, fontWeight: 'bold' },
          pageSizeOptions: customers.length < 5 ? [] : customers.length < 10 ? [5, 10] : [5, 10, 20]
        }}
        onRowClick={onRowClick}
        actions={[
          {
            icon: () => <AddBoxIcon />,
            tooltip: i18next.t('list.new', { item: i18next.t('customer.customer', { count: 1 }) }),
            isFreeAction: true,
            onClick: () => handleOption('new')
          }
        ]}
        style={{ padding: '30px 50px 50px' }}
        localization={{
          body: { emptyDataSourceMessage: i18next.t('customer.list_empty') },
          pagination: {
            firstTooltip: i18next.t('table.first_page'),
            lastTooltip: i18next.t('table.last_page'),
            previousTooltip: i18next.t('table.previous_page'),
            nextTooltip: i18next.t('table.next_page'),
            labelRowsSelect: i18next.t('table.rows')
          },
          toolbar: { searchTooltip: i18next.t('table.search'), searchPlaceholder: i18next.t('table.search') }
        }}
      />
    </Grid>
  )
}

export default CustomerListScreen
