import React, { useState, useEffect } from 'react'
import { withRouter, useParams } from 'react-router'
import { Props } from './types'
import ExpandableCard from 'components/widgets/ExpandableCard'
import SlaughterhouseForm from 'components/forms/SlaughterhouseForm'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import { setSelectedSlaughterhouse } from 'store/slaughterhouse/actions'
import { useSelector, useDispatch } from 'react-redux'
import BreadCrumb from 'components/layout/Breadcrumb'
import { ApplicationState } from 'store/types'
import PriceList from 'components/lists/PriceList'
import { useTranslation } from 'react-i18next'

const SlaughterhouseScreen: React.FC<Props> = ({ match }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { selectedSlaughterhouse, slaughterhouseLoading } = useSelector(
    (state: ApplicationState) => state.slaughterhouse
  )

  const [panelExpanded, setPanelExpanded] = useState<boolean | string>(false)
  const [isModalOpened, setModalOpened] = useState<boolean>(false)
  const [isEditingGeneral, setEditingGeneral] = useState<boolean>(false)
  const [clickedPanel, setClickedPanel] = useState<boolean | string>(false)

  useEffect(() => {
    const id = Number(match.params.id)
    if (!id) {
      setPanelExpanded('general')
      setClickedPanel('general')
    }
    dispatch(setSelectedSlaughterhouse(id || undefined))
  }, [dispatch, match.params.id])

  const handleExpanded = (panel: string) => (e: React.ChangeEvent<Record<string, any>>, isExpanded: boolean) => {
    if (panel !== panelExpanded) setClickedPanel(panel)
    isEditingGeneral ? setModalOpened(true) : isExpanded ? setPanelExpanded(panel) : setPanelExpanded(false)
  }

  const handleEditing = (active: boolean) => setEditingGeneral(active)
  const handleConfirm = () => {
    let panel: boolean | string = false
    if (clickedPanel !== panelExpanded) panel = clickedPanel
    setModalOpened(false)
    setPanelExpanded(panel)
    setEditingGeneral(false)
  }
  const handleCancel = () => setModalOpened(false)

  const { id } = useParams()
  const urlId = Number(id) || undefined
  const isRegistration = urlId ? false : true
  const label = selectedSlaughterhouse?.description
  return (
    <div>
      <BreadCrumb
        items={[
          { label: t('slaughterhouse.slaughterhouse', { count: 0 }), path: '/slaughterhouses', active: false },
          {
            label: label || t('default.registration'),
            path: '/slaughterhouses/',
            active: true,
            loading: slaughterhouseLoading
          }
        ]}
      />
      <ExpandableCard
        id='general'
        title={isRegistration ? t('slaughterhouse.registration') : t('default.general_information')}
        defaultExpanded={isRegistration}
        expanded={panelExpanded === 'general'}
        onChange={handleExpanded('general')}
      >
        <SlaughterhouseForm onChangeForm={handleEditing} />
      </ExpandableCard>
      {!isRegistration && urlId && (
        <>
          <ExpandableCard
            id='prices'
            title={t('price.price', { count: 0 })}
            expanded={panelExpanded === 'priceList'}
            onChange={handleExpanded('priceList')}
          >
            <PriceList slaughterhouseId={urlId} />
          </ExpandableCard>
        </>
      )}
      <ConfirmationModal
        open={isModalOpened}
        title={t('confirmation.general_close_confirm_title')}
        text={t('confirmation.general_close_confirm_text')}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    </div>
  )
}
export default withRouter(SlaughterhouseScreen)
