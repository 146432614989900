import gql from 'graphql-tag'
import { CustomerQueryParams, CustomerCreateArgs, CustomerUpdateArgs } from './types'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import graphql from 'services'
import { Customer } from 'store/customer/types'
import getFormattedQuery from 'helpers/getFormattedQuery'

export const customerQuery = async (token: string, params: CustomerQueryParams): Promise<Customer[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      name: true,
      email: true,
      document: true,
      phone: true,
      cityId: true,
      stateId: true,
      countryId: true
    }
  const QUERY = gql`
    query QUERY($status: Int, $id: Int, $email: String) {
      customer(status: $status, id: $id, email: $email) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/customer/customerQuery', errors)
    }
    return data.customer
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/customer/customerQuery', error)
  }
}

export const customerCreate = async (token: string, params: CustomerCreateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($customer: CreateCustomer!) {
      createCustomer(customer: $customer)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/customer/customerCreate', errors)
    }
    return data.createCustomer
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/customer/customerCreate', error)
  }
}

export const customerUpdate = async (token: string, params: CustomerUpdateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($customer: UpdateCustomer!) {
      updateCustomer(customer: $customer)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/customer/customerUpdate', errors)
    }
    return data.updateCustomer
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/customer/customerUpdate', error)
  }
}
