import React from 'react'
import { Props } from './types'

import Modal from 'components/widgets/Modal'
import PriceForm from 'components/forms/PriceForm'

const PriceModal: React.FC<Props> = ({ handleClose, isOpen, slaughterhouseId }) => {
  const close = () => {
    handleClose()
  }

  return (
    <Modal handleClose={handleClose} open={isOpen}>
      <PriceForm slaughterhouseId={slaughterhouseId || 0} setModalStatus={close} />
    </Modal>
  )
}

export default PriceModal
