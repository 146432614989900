import React, { useState } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { TextField, Button, Link, FormHelperText, Grid, Paper, CssBaseline } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import { attempSignIn } from 'store/auth/actions'
import { showToast } from 'store/main/actions'
import Beeftrader from 'resources/img/BeefTrader.png'
import Toast from 'components/widgets/Toast'

import { ApplicationState } from 'store/types'
import { Props } from './types'

import './styles.scss'
import { AuthRequestArguments, AuthRequestFields } from 'services/auth/types'

const LoginScreen = (props: Props) => {
  const { t } = useTranslation()

  const [inputTextEmail, setInputTextEmail] = useState<string>('')
  const [inputTextPassword, setInputTextPassword] = useState<string>('')
  const [inputEmailError, setInputEmailError] = useState<string>('')
  const [inputPasswordError, setInputPasswordError] = useState<string>('')

  const handleForm = (event: any) => {
    event.preventDefault()
    const validateEmail = validateField('email', inputTextEmail)
    const validatePassword = validateField('password', inputTextPassword)

    if (!validateEmail.isValid) {
      setInputEmailError(validateEmail.error)
    } else if (!validatePassword.isValid) {
      setInputEmailError(validatePassword.error)
    } else {
      const args: AuthRequestArguments = { username: inputTextEmail, password: inputTextPassword }
      const fields: AuthRequestFields = { token: true }
      props.attempSignIn({ args, fields })
    }
  }

  const validateField = (type: string, text: string) => {
    if (text.trim().length === 0) return { isValid: false, error: t('field.required') }
    switch (type) {
      case 'email':
        // if (!text.includes('.co')) return { isValid: false, error: 'invalid_email' }
        break
      case 'password':
        if (text.trim().length < 5) return { isValid: false, error: 'password_is_too_short' }
        break
      default:
        console.log('Error Field Type')
        break
    }

    return { isValid: true, error: '' }
  }

  const handleEmail = (event: any) => {
    setInputEmailError('')
    setInputTextEmail(event.target.value)
  }

  const handlePassword = (event: any) => {
    setInputPasswordError('')
    setInputTextPassword(event.target.value)
  }

  const emailError = inputEmailError !== '' ? t(inputEmailError) : ''
  const passwordError = inputPasswordError !== '' ? t(inputPasswordError) : ''

  return (
    <Grid container component='main' className='login-root'>
      <CssBaseline />
      <Toast />
      <Grid
        item
        xs={false}
        sm={5}
        md={6}
        lg={7}
        xl={8}
        className='login-image'
        style={{ backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}
      />
      <Grid item xs={12} sm={7} md={6} lg={5} xl={4} component={Paper} square className='login-container-form'>
        <Paper className='login-paper' elevation={5}>
          <img src={Beeftrader} alt='Beeftrader' className='login-logo' />
          <form className='login-form' noValidate>
            <FormHelperText variant='standard' error>
              {emailError}
            </FormHelperText>
            <TextField
              error={emailError !== ''}
              variant='outlined'
              margin='normal'
              fullWidth
              onChange={handleEmail}
              label={t('field.email')}
              autoComplete='email'
              autoFocus
            />

            <TextField
              error={passwordError !== ''}
              variant='outlined'
              margin='normal'
              fullWidth
              onChange={handlePassword}
              label={t('default.password')}
              type='password'
              autoComplete='current-password'
            />
            <FormHelperText variant='standard' error>
              {passwordError}
            </FormHelperText>
            <Grid container className='login-box-forgot-password'>
              <Link href='#' variant='body2'>
                {t('default.forgot_password')}
              </Link>
            </Grid>
            <Button type='submit' fullWidth variant='contained' color='primary' onClick={handleForm}>
              {t('button.login')}
            </Button>
          </form>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      attempSignIn,
      showToast
    },
    dispatch
  )

const mapStoreToProps = (store: ApplicationState) => ({
  isToastOpened: store.main.isToastOpened,
  toastMessage: store.main.toastMessage
})

export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(LoginScreen))
