import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from 'store/types'
import Input from 'components/widgets/Input'
import { SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { Props } from './types'
import * as Yup from 'yup'
import _ from 'lodash'
import { saveSlaughterhouse } from 'store/slaughterhouse/actions'
import Button from 'components/widgets/Button'
import Loading from 'components/widgets/Loading'
import Select from 'components/widgets/Select'
import { statusOptions } from 'data'
import AddressSelector from 'components/selectors/AddressSelector'
import { useTranslation } from 'react-i18next'

const SlaughterhouseForm: React.FC<Props> = ({ onChangeForm }) => {
  const { t } = useTranslation()
  const formRef: any = useRef(null)
  const dispatch = useDispatch()

  const selectedSlaughterhouse = useSelector((state: ApplicationState) => state.slaughterhouse.selectedSlaughterhouse)
  const loading = useSelector((state: ApplicationState) => state.slaughterhouse.slaughterhouseLoading)

  const buttonLabel = selectedSlaughterhouse ? 'save' : 'add'

  const handleSubmit: SubmitHandler = async data => {
    data.id = selectedSlaughterhouse?.id
    _.map(Object.entries(data), ([key, value]) => (data[key] = value === '' ? null : value))
    if (data.cityId) data.cityId = Number(data.cityId)
    if (data.slaughterday) data.slaughterday = Number(data.slaughterday)
    if (data.slaughteryr) data.slaughteryr = Number(data.slaughteryr)
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        description: Yup.string().required(() => t('field.required')),
        cityId: Yup.number().required(() => t('field.required'))
      })
      await schema.validate(data, { abortEarly: false })
      const slaughterhouse: any = data
      dispatch(saveSlaughterhouse({ slaughterhouse }))
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }
  return (
    <div className='container'>
      {loading ? (
        <Loading />
      ) : (
        <Form
          ref={formRef}
          initialData={selectedSlaughterhouse}
          onSubmit={handleSubmit}
          onChange={() => onChangeForm(true)}
        >
          <div className='row'>
            <div className='col-4'>
              <Input
                name='description'
                label={t('field.description')}
                onFocus={() => formRef.current?.setFieldError('description', '')}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <Select
                name='status'
                label={t('field.status')}
                defaultOptions={statusOptions}
                selectedValue={selectedSlaughterhouse?.status}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <AddressSelector selected={selectedSlaughterhouse?.address?.cityId} />
            </div>
          </div>

          {/* <Input name='latitude' label='latitude' onFocus={() => formRef.current?.setFieldError('latitude', '')} />
          <Input name='longitude' label='longitude' onFocus={() => formRef.current?.setFieldError('longitude', '')} />
          <Input
            name='slaughterday'
            label='slaughterday'
            onFocus={() => formRef.current?.setFieldError('slaughterday', '')}
          />
          <Input
            name='slaughteryr'
            label='slaughteryr'
            onFocus={() => formRef.current?.setFieldError('slaughteryr', '')}
          /> */}

          <div className='row'>
            <div className='col-4'>
              <Input
                name='class'
                label={t('field.class')}
                onFocus={() => formRef.current?.setFieldError('class', '')}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <Button label={buttonLabel} />
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}

export default SlaughterhouseForm
