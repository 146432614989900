import React from 'react'
import { Drawer, ListItem, ListItemIcon, ListItemText, Icon, List, Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Props, NavbarItem } from './types'
import './styles.scss'
import { useTranslation } from 'react-i18next'

const Sidebar: React.FC<Props> = ({ open }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const navMenu: NavbarItem[] = [
    // { id: 0, icon: 'home', label: t('default.home'), to: '/' },
    { id: 0, icon: 'image_search', label: t('default.monitoring'), to: '/monitoring' },
    { id: 1, icon: 'account_balance', label: t('feedlot.feedlot', { count: 0 }), to: '/feedlots' },
    { id: 2, icon: 'scatter_plot', label: t('global.weighings', { count: 0 }), to: '/weighings' },
    { id: 3, icon: 'person', label: t('customer.customer', { count: 0 }), to: '/customers' },
    { id: 4, icon: 'storefront', label: t('slaughterhouse.slaughterhouse', { count: 0 }), to: '/slaughterhouses' }
  ]
  const handleClick = (path: string) => history.push(path)

  return (
    <Drawer
      className={`drawer ${open ? 'drawer-opened' : 'drawer-closed'}`}
      variant='permanent'
      classes={{ paper: open ? 'drawer-opened' : 'drawer-closed' }}
    >
      <div className='drawer-toolbar' />
      <List>
        {navMenu.map(navItem => (
          <Tooltip key={navItem.id} title={navItem.label}>
            <ListItem
              button
              onClick={() => handleClick(navItem.to)}
              selected={history.location.pathname === navItem.to}
            >
              <ListItemIcon>
                <Icon color={history.location.pathname === navItem.to ? 'primary' : undefined}>{navItem.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={navItem.label} />
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </Drawer>
  )
}

export default Sidebar
