import { action } from 'typesafe-actions'
import { Country, AddressTypes } from './types'
import { AddressQueryParams } from 'services/address/types'

export const getAddress = (params?: AddressQueryParams) => action(AddressTypes.ACTION_GET_ADDRESS, { params })
export const setSelectedCity = (selectedCityId?: number) =>
  action(AddressTypes.REDUCER_FETCH_ADDRESS_DATA, { selectedCityId })
export const setSelectedCountry = (selectedCountryId?: number, selectedRegionId?: number, selectedCityId?: number) =>
  action(AddressTypes.ACTION_SET_SELECTED_COUNTRY, { selectedCountryId, selectedRegionId, selectedCityId })
export const setSelectedRegion = (selectedRegionId?: number) =>
  action(AddressTypes.ACTION_SET_SELECTED_REGION, { selectedRegionId })
export const addCountry = (countryId: Country) => action(AddressTypes.ACTION_ADD_COUNTRY, { countryId })
export const updateCountry = (countryId: Country) => action(AddressTypes.ACTION_UPDATE_COUNTRY, { countryId })
export const deleteCountry = (countryId: Country) => action(AddressTypes.ACTION_DELETE_COUNTRY, { countryId })
