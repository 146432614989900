import { takeLatest, all } from 'redux-saga/effects'
import { WeighingTypes } from './types'
import { workerGetProcessedWeighing, workerSetSelectedProcessedWeighing, workerDeleteGrossWeighings } from './sagas'

export function* watcherGetProcessedWeighing() {
  yield takeLatest(WeighingTypes.ACTION_GET_PROCESSED_WEIGHING, workerGetProcessedWeighing)
}
export function* watcherSetSelectedProcessedWeighing() {
  yield takeLatest(WeighingTypes.ACTION_SET_SELECTED_PROCESSED_WEIGHING, workerSetSelectedProcessedWeighing)
}
export function* watcherDeleteGrossWeighings() {
  yield takeLatest(WeighingTypes.ACTION_DELETE_GROSS_WEIGHINGS, workerDeleteGrossWeighings)
}

export default function* authRootSaga() {
  yield all([watcherGetProcessedWeighing(), watcherSetSelectedProcessedWeighing(), watcherDeleteGrossWeighings()])
}
