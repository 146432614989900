import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Props } from './types'
import { ApplicationState } from 'store/types'
import { Lot } from 'store/feedlot/types'

import { Option } from 'data/types'
import { getLot } from 'store/feedlot/actions'
import Select from 'components/widgets/Select'
import { LotQueryFields } from 'services/lot/types'
import { useTranslation } from 'react-i18next'

const LotSelector: React.FC<Props> = ({ selected, feedlotId, cleanError, label, handleSelect }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { lots, lotLoading } = useSelector((state: ApplicationState) => state.feedlot)

  const [options, setOptions] = useState<Option[]>([])

  const formatLots = (lots: Lot[]) => lots.map((e: Lot) => ({ label: e.description || '', value: e.id || -1 }))

  useEffect(() => {
    if (!lots?.length) {
      const fields: LotQueryFields = {
        id: true,
        code: true,
        description: true
      }
      dispatch(getLot({ args: { feedlotId }, fields }))
    } else setOptions(formatLots(lots))
  }, [lots, feedlotId, dispatch])

  return (
    <Select
      name='lot.id'
      label={t('lot.lot')}
      loading={lotLoading}
      defaultOptions={options}
      selectedValue={selected}
      handleSelect={handleSelect}
      onFocus={cleanError}
    />
  )
}
export default LotSelector
