import React, { useState, useEffect } from 'react'
import { Props } from './types'
import { ApplicationState } from 'store/types'
import { getUsers, setSelectedUser } from 'store/user/actions'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { getTitle, generalRender, getCellStyle, sortFunction } from './helper'
import MaterialTable from 'material-table'
import { Column } from 'components/widgets/Table/types'
import _ from 'lodash'
import UserPermissionsModal from 'components/modals/UserPermissionsModal'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const FeedlotUserList: React.FC<Props> = ({ feedlotId }) => {
  const dispatch: Dispatch = useDispatch()
  const { userLoading, users } = useSelector((state: ApplicationState) => state.user)
  const [isUserModalOpen, setUserModalStatus] = useState<boolean>(false)
  const [isNew, setIsNew] = useState<boolean>(true)

  useEffect(() => {
    dispatch(
      getUsers({
        args: { feedlotId },
        fields: {
          id: true,
          email: true,
          permissionSet: {
            feedlotId: true,
            permissions: {
              admin: true,
              allowAllLots: true,
              allowAllPens: true,
              allowEconomicInfo: true,
              allowEdit: true
            },
            lots: { id: true, description: true, pen: { id: true } },
            pens: { id: true, code: true }
          }
        }
      })
    )
  }, [dispatch, feedlotId])

  const editAction = (selectedUser: any) => {
    selectedUser.id ? setIsNew(false) : setIsNew(true)
    dispatch(setSelectedUser(selectedUser.id))
    setUserModalStatus(true)
  }

  const columnNames = ['admin', 'email', 'edit', 'view_economic', 'view_all_lots', 'view_alls']
  const columns: Column[] = []
  if (users.length) {
    _.map(columnNames, (column: string) => {
      columns.push({
        title: getTitle(column),
        field: column,
        render: (rowData: Record<string, any>) => generalRender(rowData, column),
        cellStyle: getCellStyle(column),
        customSort: column === 'admin' ? (data1: any, data2: any) => sortFunction(data1, data2) : undefined,
        defaultSort: column === 'admin' ? 'asc' : undefined // LEMBRAR: não funciona
      })
    })
  }

  const { t } = useTranslation()
  return (
    <div style={{ flex: 1 }}>
      <UserPermissionsModal
        isOpen={isUserModalOpen}
        handleClose={() => setUserModalStatus(false)}
        feedlotId={feedlotId}
        isNew={isNew}
      />
      <MaterialTable
        isLoading={userLoading}
        columns={columns}
        data={users}
        options={{
          header: !!users.length,
          toolbar: true,
          paging: !!users.length,
          toolbarButtonAlignment: 'left',
          searchFieldAlignment: 'left',
          showTitle: false,
          emptyRowsWhenPaging: false,
          addRowPosition: 'first',
          headerStyle: { fontSize: 16, fontWeight: 'bold' },
          pageSizeOptions: users.length < 5 ? [] : users.length < 10 ? [5, 10] : [5, 10, 20]
        }}
        actions={[
          { icon: 'add', tooltip: t('user.new'), onClick: (_: any, data: any) => editAction(data), isFreeAction: true },
          { icon: 'edit', tooltip: t('table.edit'), onClick: (_: any, data: any) => editAction(data) }
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: !userLoading ? t('user.empty') : '',
            addTooltip: t('user.new'),
            deleteTooltip: t('table.delete'),
            editTooltip: t('table.edit'),
            editRow: {
              saveTooltip: t('table.save'),
              cancelTooltip: t('table.cancel'),
              deleteText: t('table.delete_text')
            }
          },
          header: { actions: t('table.actions') },
          pagination: {
            firstTooltip: t('table.first_page'),
            previousTooltip: t('table.previous_page'),
            nextTooltip: t('table.next_page'),
            lastTooltip: t('table.last_page'),
            labelRowsSelect: t('table.rows')
          },
          toolbar: { searchTooltip: i18next.t('table.search'), searchPlaceholder: i18next.t('table.search') }
        }}
        components={{
          Container: (props: {
            children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
          }) => <div style={{ flex: 1 }}>{props.children}</div>
        }}
      />
    </div>
  )
}
export default withRouter(FeedlotUserList)
