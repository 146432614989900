import React from 'react'

import { Props } from './types'
import './styles.scss'

import { ExpansionPanel, AccordionSummary, AccordionDetails, Box, Accordion } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
const ExpandableCard: React.FC<Props> = ({
  tooltip,
  style,
  disabled,
  defaultExpanded,
  onChange,
  expandIcon,
  expanded,
  title,
  children,
  id
}) => {
  return (
    <Accordion
      className={'card'}
      TransitionProps={{ unmountOnExit: true }}
      disabled={disabled || false}
      defaultExpanded={defaultExpanded || false}
      onChange={onChange}
      expanded={expanded || false}
      title={tooltip}
    >
      <AccordionSummary expandIcon={expandIcon || <ExpandMore />} id={id} style={style}>
        <Box className={'title'}>{title}</Box>
      </AccordionSummary>
      <AccordionDetails className='card-content'>{children}</AccordionDetails>
    </Accordion>
  )
}
export default ExpandableCard
