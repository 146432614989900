import { Grid } from '@material-ui/core'
import React from 'react'

export const generalRender = (rowData: any, type: string) => {
  if (type === 'cash' || type === 'term') {
    if (!rowData?.currency?.symbol && !rowData?.weightUnity?.symbol) return <Grid>{rowData[type]}</Grid>
    if (!rowData[type]) return <Grid>{0}</Grid>
    return <Grid>{rowData?.currency?.symbol + ': ' + rowData[type] + ' /' + rowData?.weightUnity?.symbol}</Grid>
  } else {
    return <Grid>{rowData[type] || 0}</Grid>
  }
}
