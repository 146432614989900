import React from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Switch } from 'react-router-dom'

import PrivateRoute from '../PrivateRoute'
import { checkAuthentication } from 'store/auth/actions'
import AuthRoute from '../AuthRoute'

import Container from 'components/layout/Container'

const Auth = () => {
  const dispatch = useDispatch()
  dispatch(checkAuthentication())
  return (
    <BrowserRouter>
      <Switch>
        <AuthRoute exact path='/login' />
        <PrivateRoute component={<Container />} />
      </Switch>
    </BrowserRouter>
  )
}
export default Auth
