import React, { useRef, useState, useEffect } from 'react'
import { Option } from 'data/types'

import Select from 'components/widgets/Select'

import LotSelector from 'components/selectors/Lot'
import AnimalSelector from 'components/selectors/Animal'
import { Form } from '@unform/web'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from 'store/types'
import { useTranslation } from 'react-i18next'
import { Feedlot, FeedlotTypes } from 'store/feedlot/types'
import { AnimalTypes } from 'store/animal/types'
import { FeedlotQueryFields } from 'services/feedlot/types'
import { getFeedlot, setSelectedFeedlot, setSelectedLot } from 'store/feedlot/actions'
import Slider from 'components/widgets/slider'
import Button from 'components/widgets/Button'
import AnimalWeighingChart from 'components/charts/AnimalWeighingChart'
import { deleteGrossWeighings } from 'store/animal/weighing/actions'
import { setSelectedAnimal } from 'store/animal/actions'
import ConfirmationDialog from 'components/widgets/Dialog'

const AnimalWeighingForm = () => {
  const formRef: any = useRef(null)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const defaultMinDay = 1
  const defaultMaxDay = 150
  const defaultMinWeight = 0
  const defaultMaxWeight = 700

  const { feedlots, feedlotLoading } = useSelector((state: ApplicationState) => state.feedlot)
  const { selectedAnimal } = useSelector((state: ApplicationState) => state.animal)
  const [selectedMinDay, setSelectedMinDay] = useState<number>(1)
  const [selectedMaxDay, setSelectedMaxDay] = useState<number>(150)
  const [selectedMinWeight, setSelectedMinWeight] = useState<number>(0)
  const [selectedMaxWeight, setSelectedMaxWeight] = useState<number>(700)
  const [chartMinDay, setChartMinDay] = useState<number>(selectedMinDay)
  const [chartMaxDay, setChartMaxDay] = useState<number>(selectedMaxDay)
  const [chartMinWeight, setChartMinWeight] = useState<number>(selectedMinWeight)
  const [chartMaxWeight, setChartMaxWeight] = useState<number>(selectedMaxWeight)
  const [submitConfirmationOpen, setSubmitConfirmationOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<Option[]>([])
  const [selectedFeedlotId, setSelectedFeedlotId] = useState<number>()
  const [selectedLotId, setSelectedLotId] = useState<number>()
  const [selectedAnimalId, setSelectedAnimalId] = useState<number>()

  const formatFeedlots = (feedlots: Feedlot[]) =>
    feedlots.map((e: Feedlot) => ({ label: e.description || '', value: e.id || -1 }))

  const handleSubmit: any = () => {
    setSubmitConfirmationOpen(true)
  }

  const handleSelectedFeedlot = (id: number | undefined) => {
    if (id) {
      setSelectedFeedlotId(id)
      setSelectedLotId(undefined)
      dispatch(setSelectedFeedlot(id))
      dispatch({ type: FeedlotTypes.REDUCER_FETCH_FEEDLOT_DATA, payload: { lots: [] } })
      dispatch(setSelectedAnimal(undefined))
      setSelectedAnimalId(undefined)
    }
  }

  const handleSelectedLot = (id: number | undefined) => {
    if (id) {
      setSelectedLotId(id)
      setSelectedAnimalId(undefined)
      dispatch(setSelectedLot(id))
      dispatch({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { animals: [] } })
    }
  }

  const handleSelectedAnimal = (id: number | undefined) => {
    setSelectedAnimalId(id)
    if (id) dispatch(setSelectedAnimal(id))
  }

  useEffect(() => {
    if (!feedlots?.length) {
      const fields: FeedlotQueryFields = {
        id: true,
        description: true
      }
      dispatch(getFeedlot({ fields }))
    } else setOptions(formatFeedlots(feedlots))
  }, [feedlots, dispatch])

  const handleChangeMinDay = (event: { target: { value: any } }) => {
    setSelectedMinDay(event.target.value)
    setChartMinDay(event.target.value)
  }

  const handleChangeMaxDay = (event: { target: { value: any } }) => {
    setSelectedMaxDay(event.target.value)
    setChartMaxDay(event.target.value)
  }

  const handleChangeMinWeight = (event: { target: { value: any } }) => {
    setSelectedMinWeight(event.target.value)
    setChartMinWeight(event.target.value)
  }

  const handleChangeMaxWeight = (event: { target: { value: any } }) => {
    setSelectedMaxWeight(event.target.value)
    setChartMaxWeight(event.target.value)
  }

  const handleInputChangeMinWeight = (event: { target: { value: any } }) => {
    ;(document.getElementById('startWeightRange') as HTMLInputElement).value = event.target.value
    setSelectedMinWeight(event.target.value)
    setChartMinWeight(event.target.value)
  }

  const handleInputChangeMaxWeight = (event: { target: { value: any } }) => {
    ;(document.getElementById('endWeightRange') as HTMLInputElement).value = event.target.value
    setSelectedMaxWeight(event.target.value)
    setChartMaxWeight(event.target.value)
  }

  const handleInputChangeMinDay = (event: { target: { value: any } }) => {
    ;(document.getElementById('startDayRange') as HTMLInputElement).value = event.target.value
    setSelectedMinDay(event.target.value)
    setChartMinDay(event.target.value)
  }

  const handleInputChangeMaxDay = (event: { target: { value: any } }) => {
    ;(document.getElementById('endDayRange') as HTMLInputElement).value = event.target.value
    setSelectedMaxDay(event.target.value)
    setChartMaxDay(event.target.value)
  }

  const handleConfirmationSubmitClose = () => {
    setSubmitConfirmationOpen(false)
  }

  const handleConfirmationSubmit = () => {
    if (selectedAnimal?.id)
      dispatch(
        deleteGrossWeighings({
          animalId: selectedAnimal?.id,
          minDay: Number(selectedMinDay),
          maxDay: Number(selectedMaxDay),
          minWeight: Number(selectedMinWeight),
          maxWeight: Number(selectedMaxWeight)
        })
      )
    handleConfirmationSubmitClose()
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='offset-8'>
          <Form ref={formRef} initialData={undefined} onSubmit={handleSubmit}>
            <Select
              name='feedlot.id'
              label={t('feedlot.feedlot')}
              loading={feedlotLoading}
              selectedValue={selectedFeedlotId}
              defaultOptions={options}
              handleSelect={handleSelectedFeedlot}
            />
            <LotSelector
              selected={selectedLotId}
              feedlotId={selectedFeedlotId}
              label={t('lot.lot')}
              handleSelect={handleSelectedLot}
            ></LotSelector>

            <AnimalSelector
              selected={selectedAnimalId}
              lotId={selectedLotId}
              handleSelect={handleSelectedAnimal}
            ></AnimalSelector>

            <div className='row'>
              <Slider
                double={true}
                min_start={0}
                max_start={700}
                default_min={defaultMinWeight}
                default_max={defaultMaxWeight}
                min_end={defaultMinWeight}
                max_end={defaultMaxWeight}
                start_id={'startWeightRange'}
                end_id={'endWeightRange'}
                minHandleChange={event => handleChangeMinWeight(event)}
                maxHandleChange={event => handleChangeMaxWeight(event)}
              >
                <input type='number' value={selectedMinWeight} onChange={event => handleInputChangeMinWeight(event)} />{' '}
                {' <= '}
                <span className='info'>
                  <>{t('field.weight')}</>
                </span>{' '}
                {' <= '}
                <input
                  type='number'
                  value={selectedMaxWeight ? selectedMaxWeight : defaultMaxWeight}
                  onChange={event => handleInputChangeMaxWeight(event)}
                />
              </Slider>
            </div>
            <div className='row'>
              <Slider
                double={true}
                min_start={defaultMinDay}
                max_start={defaultMaxDay}
                default_min={defaultMinDay}
                default_max={defaultMaxDay}
                min_end={defaultMinDay}
                max_end={defaultMaxDay}
                start_id={'startDayRange'}
                end_id={'endDayRange'}
                minHandleChange={event => handleChangeMinDay(event)}
                maxHandleChange={event => handleChangeMaxDay(event)}
              >
                <input
                  type='number'
                  value={selectedMinDay ? selectedMinDay : defaultMinDay}
                  onChange={event => handleInputChangeMinDay(event)}
                />{' '}
                {' <= '}
                <span className='info'>{t('field.day')}</span> {' <= '}
                <input
                  type='number'
                  value={selectedMaxDay ? selectedMaxDay : defaultMaxDay}
                  onChange={event => handleInputChangeMaxDay(event)}
                />
              </Slider>
            </div>
            <div className='row'>
              <div className='col-6 mtop-4'>
                <Button label={t('button.delete')}></Button>
              </div>
            </div>
          </Form>
        </div>
        <div className='offset-4'>
          <AnimalWeighingChart
            minDay={chartMinDay}
            maxDay={chartMaxDay}
            maxWeight={chartMaxWeight}
            minWeight={chartMinWeight}
          ></AnimalWeighingChart>
        </div>
      </div>
      <ConfirmationDialog
        open={submitConfirmationOpen}
        title={t('notice.attention')}
        body={t('confirmation.delete_animal_weighing')}
        handleYes={handleConfirmationSubmit}
        handleClose={handleConfirmationSubmitClose}
      ></ConfirmationDialog>
    </div>
  )
}

export default AnimalWeighingForm
