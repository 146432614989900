import React from 'react'
import { useHistory } from 'react-router-dom'
import { Props } from './types'
import { Breadcrumbs, Link, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Loading from 'components/widgets/Loading'

const BreadCrumb: React.FC<Props> = ({ items }) => {
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <Breadcrumbs aria-label='breadcrumb'>
      {items.map(i => {
        if (i.loading) {
          return <Loading key={i.path} size={15} />
        } else if (i.active) {
          return (
            <Typography key={i.path} color='textPrimary'>
              {t(i.label)}
            </Typography>
          )
        } else {
          return (
            <Link key={i.path} style={{ cursor: 'pointer' }} color='inherit' onClick={() => history.push(i.path)}>
              {t(i.label)}
            </Link>
          )
        }
      })}
    </Breadcrumbs>
  )
}
export default BreadCrumb
