import { action } from 'typesafe-actions'
import { FeedlotTypes, EquipmentModel } from './types'
import { FeedlotQueryParams, FeedlotMutationArgs } from 'services/feedlot/types'
import { FeedlotMonitoringQueryParams } from 'services/feedlotMonitoring/types'
import { PenQueryParams, PenMutationArgs } from 'services/pen/types'
import { EquipmentQueryParams, EquipmentMutationArgs } from 'services/equipment/types'
import { LotQueryParams, LotMutationArgs } from 'services/lot/types'
import { EquipmentModelQueryParams } from 'services/equipmentModel/types'

export const getFeedlot = (params?: FeedlotQueryParams) => action(FeedlotTypes.ACTION_GET_FEEDLOT, { params })
export const getFeedlotMonitoring = (params?: FeedlotMonitoringQueryParams) =>
  action(FeedlotTypes.ACTION_GET_FEEDLOT_MONITORING, { params })
export const setSelectedFeedlot = (feedlotId?: number) =>
  action(FeedlotTypes.ACTION_SET_SELECTED_FEEDLOT, { feedlotId })
export const saveFeedlot = (args: FeedlotMutationArgs) => action(FeedlotTypes.ACTION_SAVE_FEEDLOT, { args })

export const getPen = (params?: PenQueryParams) => action(FeedlotTypes.ACTION_GET_PEN, { params })
export const setSelectedPen = (params?: PenQueryParams) => action(FeedlotTypes.ACTION_SET_SELECTED_PEN, { params })
export const savePen = (args: PenMutationArgs) => {
  return action(FeedlotTypes.ACTION_SAVE_PEN, { args })
}

export const getEquipment = (params?: EquipmentQueryParams) => action(FeedlotTypes.ACTION_GET_EQUIPMENT, { params })
export const setSelectedEquipment = (equipmentId?: number) =>
  action(FeedlotTypes.ACTION_SET_SELECTED_EQUIPMENT, { equipmentId })
export const saveEquipment = (args: EquipmentMutationArgs, equipmentModel: EquipmentModel | undefined) =>
  action(FeedlotTypes.ACTION_SAVE_EQUIPMENT, { args, equipmentModel })

export const getEquipmentModel = (params?: EquipmentModelQueryParams) =>
  action(FeedlotTypes.ACTION_GET_EQUIPMENT_MODEL, { params })
export const setSelectedEquipmentModel = (equipmentModelId?: number) =>
  action(FeedlotTypes.ACTION_SET_SELECTED_EQUIPMENT_MODEL, { equipmentModelId })

export const getLot = (params?: LotQueryParams) => action(FeedlotTypes.ACTION_GET_LOT, { params })
export const setSelectedLot = (lotId?: number) => action(FeedlotTypes.ACTION_SET_SELECTED_LOT, { lotId })
export const saveLot = (args: LotMutationArgs) => action(FeedlotTypes.ACTION_SAVE_LOT, { args })
