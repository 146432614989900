import { EconomicTypes, EconomicState } from './types'

export const priceList = [
  {
    id: 1,
    category: 'Macho inteiro nelore',
    value: 'mi_nel',
    cash: 0,
    term: 0,
    currency: {
      id: 1,
      code: '',
      symbol: '',
      description: ''
    },
    weightUnity: {
      id: 1,
      code: '',
      symbol: ''
    }
  },
  {
    id: 2,
    category: 'Macho castrado nelore',
    value: 'mc_nel',
    cash: 0,
    term: 0,
    currency: {
      id: 1,
      code: '',
      symbol: '',
      description: ''
    },
    weightUnity: {
      id: 1,
      code: '',
      symbol: ''
    }
  },
  {
    id: 3,
    category: 'Macho inteiro cruzado',
    value: 'mi_cruz',
    cash: 0,
    term: 0,
    currency: {
      id: 1,
      code: '',
      symbol: '',
      description: ''
    },
    weightUnity: {
      id: 1,
      code: '',
      symbol: ''
    }
  },
  {
    id: 4,
    category: 'Macho castrado cruzado',
    value: 'mc_cruz',
    cash: 0,
    term: 0,
    currency: {
      id: 1,
      code: '',
      symbol: '',
      description: ''
    },
    weightUnity: {
      id: 1,
      code: '',
      symbol: ''
    }
  },
  {
    id: 5,
    category: 'Novilha nelore',
    value: 'nv_nel',
    cash: 0,
    term: 0,
    currency: {
      id: 1,
      code: '',
      symbol: '',
      description: ''
    },
    weightUnity: {
      id: 1,
      code: '',
      symbol: ''
    }
  },
  {
    id: 6,
    category: 'Novilha Cruzada',
    value: 'nv_cruz',
    cash: 0,
    term: 0,
    currency: {
      id: 1,
      code: '',
      symbol: '',
      description: ''
    },
    weightUnity: {
      id: 1,
      code: '',
      symbol: ''
    }
  },
  {
    id: 7,
    category: 'Vaca nelore',
    value: 'vc_nel',
    cash: 0,
    term: 0,
    currency: {
      id: 1,
      code: '',
      symbol: '',
      description: ''
    },
    weightUnity: {
      id: 1,
      code: '',
      symbol: ''
    }
  }
]

const INITIAL_STATE: EconomicState = {
  priceLoading: false,
  currencyLoading: false,
  weightUnityLoading: false,
  pricesList: priceList
}

export default (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case EconomicTypes.REDUCER_CLEAR_ECONOMIC_DATA:
      return { ...INITIAL_STATE, checkingAuthentication: false }
    case EconomicTypes.REDUCER_FETCH_ECONOMIC_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
