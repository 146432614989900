import gql from 'graphql-tag'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import graphql from 'services'
import getFormattedQuery from 'helpers/getFormattedQuery'

import { ProcessedWeighingQueryParams, DeleteGrossWeighingsArgs } from './types'
import { ProcessedWeighing } from '../../../store/animal/weighing/types'

export const processedWeighingQuery = async (
  token: string,
  params: ProcessedWeighingQueryParams
): Promise<ProcessedWeighing[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      prediction: true,
      day: true,
      weight: true,
      lotId: true
    }
  const QUERY = gql`
      query QUERY($id: Int, $animalId: Int) {
        animal(id: $id, animalId: $animalId) ${getFormattedQuery(params.fields)}
      }
      `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(
        statusCode || UNEXPECTED_ERROR_CODE,
        'services/processedWeighing/processedWeighingQuery',
        errors
      )
    }
    return data.animal
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/processedWeighing/processedWeighingQuery', error)
  }
}

export const deleteGrossWeighings = async (token: string, params: DeleteGrossWeighingsArgs) => {
  const MUTATION = gql`
    mutation MUTATION($animalId: Float!, $maxDay: Float, $minDay: Float, $maxWeight: Float, $minWeight: Float) {
      deleteGrossWeighings(
        animalId: $animalId
        maxDay: $maxDay
        minDay: $minDay
        maxWeight: $maxWeight
        minWeight: $minWeight
      )
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(
        statusCode || UNEXPECTED_ERROR_CODE,
        'services/graphql/animal/weighing/deleteGrossWeighings',
        errors
      )
    }
    return data.deleteGrossWeighings
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/graphql/animal/weighing/deleteGrossWeighings', error)
  }
}
