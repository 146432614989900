import { Props } from './types'
import Loading from 'components/widgets/Loading'
import React, { useRef } from 'react'
import { Form } from '@unform/web'
import Input from 'components/widgets/Input'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'
import Button from 'components/widgets/Button'
import { SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import { CategoryPrice } from 'store/economic/types'
import { savePrice } from 'store/economic/actions'
import CurrencySelector from 'components/selectors/Currency'
import WeightUnitySelector from 'components/selectors/WeightUnity'
import { useTranslation } from 'react-i18next'

const PriceForm: React.FC<Props> = ({ slaughterhouseId, setModalStatus }) => {
  const { t } = useTranslation()
  const formRef: any = useRef(null)
  const dispatch = useDispatch()
  const { price, selectedPrice, priceLoading } = useSelector((state: ApplicationState) => state.economic)

  const buttonLabel = selectedPrice?.id ? 'save' : 'add'

  const clearError = (e: any) => formRef.current?.setFieldError(e.target?.id, '')

  const handleSubmit: SubmitHandler<CategoryPrice> = async data => {
    const priceAux: any = price ? price : {}

    priceAux[selectedPrice?.value + '_vista'] = Number(data?.cash)
    priceAux[selectedPrice?.value + '_prazo'] = Number(data?.term)
    priceAux.slaughterhouseId = slaughterhouseId

    if (selectedPrice) {
      data.id = selectedPrice?.id
      data.value = selectedPrice?.value
    }

    priceAux.slaughterhouseId = slaughterhouseId
    priceAux.currencyId = data.currency.id
    priceAux.weightUnityId = data.weightUnity.id

    delete priceAux.slaughterhouse
    delete priceAux.currency
    delete priceAux.weightUnity
    delete priceAux.__typename

    try {
      formRef.current.setErrors({})

      if (selectedPrice) dispatch(savePrice({ price: priceAux, selectedPrice: data }))
      setModalStatus()
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
        setModalStatus()
      }
    }
  }
  return (
    <div className='container'>
      {priceLoading ? (
        <Loading />
      ) : (
        <Form ref={formRef} initialData={selectedPrice} onSubmit={handleSubmit} id='price-container'>
          <div className='row'>
            <div className='col-2'>
              <Input name='category' label={t('field.category')} onFocus={clearError} disabled />
            </div>
            <div className='col-1'>
              <Input name='cash' label={t('field.cash')} onFocus={clearError} />
            </div>
            <div className='col-1'>
              <Input name='term' label={t('field.term')} onFocus={clearError} />
            </div>
            <div className='col-1'>
              <CurrencySelector
                selected={selectedPrice?.currency?.id}
                cleanError={() => formRef.current?.setFieldError('currency.id', '')}
              />
            </div>
            <div className='col-1'>
              <WeightUnitySelector
                selected={selectedPrice?.weightUnity?.id}
                cleanError={() => formRef.current?.setFieldError('weightUnity.id', '')}
              />
            </div>
            <div className='col-2 mtop-2'>
              <Button label={buttonLabel} />
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}

export default PriceForm
