import { Reducer } from 'redux'
import { MainStore, MainTypes } from './types'

const INITIAL_STATE: MainStore = {
  mainLoading: false,
  isMainSidebarOpened: false,
  isSecondarySidebarOpened: false,
  isToastOpened: false,
  toastMessage: '',
  defaultTheme: 'light',
  history: null
}

const reducer: Reducer<MainStore> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MainTypes.REDUCER_FETCH_MAIN_DATA:
      return { ...state, ...action.payload }
    case MainTypes.REDUCER_TOGGLE_MAIN_SIDEBAR:
      return { ...state, isMainSidebarOpened: !state.isMainSidebarOpened }
    case MainTypes.REDUCER_TOGGLE_SECONDARY_SIDEBAR:
      return { ...state, isSecondarySidebarOpened: !state.isSecondarySidebarOpened }
    case MainTypes.REDUCER_SET_THEME:
      return { ...state, defaultTheme: action.payload.theme }
    case MainTypes.REDUCER_TOGGLE_TOAST:
      return {
        ...state,
        isToastOpened: !!action.payload.message,
        toastMessage: !action.payload.message ? '' : action.payload.message
      }
    default:
      return state
  }
}
export default reducer
