import { all, takeLatest } from 'redux-saga/effects'
import { workerSetSelectedCountry, workerSetSelectedRegion, workerGetAddress } from './sagas'
import { AddressTypes } from './types'

export function* watcherGetAddress() {
  yield takeLatest(AddressTypes.ACTION_GET_ADDRESS, workerGetAddress)
}

export function* watcherSetSelectedCountry() {
  yield takeLatest(AddressTypes.ACTION_SET_SELECTED_COUNTRY, workerSetSelectedCountry)
}

export function* watcherSetSelectedRegion() {
  yield takeLatest(AddressTypes.ACTION_SET_SELECTED_REGION, workerSetSelectedRegion)
}

export default function* authRootSaga() {
  yield all([watcherGetAddress(), watcherSetSelectedRegion(), watcherSetSelectedCountry()])
}
