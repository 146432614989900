import gql from 'graphql-tag'
import { EquipmentModelQueryParams } from './types'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import graphql from 'services'
import { EquipmentModel } from 'store/feedlot/types'
import getFormattedQuery from 'helpers/getFormattedQuery'

export const equipmentModelQuery = async (
  token: string,
  params: EquipmentModelQueryParams
): Promise<EquipmentModel[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      description: true,
      model: true,
      equipmentTypeId: true,
      manufacturer: {
        id: true,
        name: true
      }
    }
  const QUERY = gql`
    query QUERY($id: Int) {
      equipmentModel(id: $id) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/equipmentModel/equipmentModelQuery', errors)
    }
    return data.equipmentModel
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/equipmentModel/equipmentModelQuery', error)
  }
}
