import React from 'react'
import { useTranslation } from 'react-i18next'

import { Props } from './types'
import './styles.scss'

import Modal from 'components/widgets/Modal'
import { Button } from '@material-ui/core'

const ConfirmationModal: React.FC<Props> = ({ open, handleCancel, text, handleConfirm, title }) => {
  const { t } = useTranslation()
  return (
    <Modal open={open} handleClose={handleCancel} title={title || t('notice.attention')}>
      <div className='content'>{text || ''}</div>
      <div className='footer'>
        <Button type='submit' variant='contained' color='secondary' onClick={handleCancel}>
          {t('button.cancel')}
        </Button>
        <Button type='submit' variant='contained' color='primary' onClick={handleConfirm}>
          {t('button.continue')}
        </Button>
      </div>
    </Modal>
  )
}
export default ConfirmationModal
