import { Animal } from '../types'

export type UnprocessedAnimal = {
  id: number
  lotId: number
  beeftraderProcessing: BeeftraderProcessing
  processingFailure: ProcessingFailure
  animals: Animal[]
}

export type BeeftraderProcessing = {
  id: number
  lotId: number
  processingDate: Date
  feedlotId: number
  unprocessedDays?: number
}

export type ProcessingFailure = {
  id: number
  type: string
  description: string
}

export type UnprocessedAnimalsState = {
  readonly unprocessedAnimals?: UnprocessedAnimal[]
  readonly unprocessedAnimalsLoading: boolean
  readonly selectedUnprocessedAnimals?: UnprocessedAnimal[]
}

export enum UnprocessedAnimalsTypes {
  REDUCER_FETCH_UNPROCESSED_ANIMALS_DATA = 'REDUCER_FETCH_UNPROCESSED_ANIMALS_DATA',
  REDUCER_CLEAR_UNPROCESSED_ANIMALS_DATA = 'REDUCER_CLEAR_UNPROCESSED_ANIMALS_DATA',
  ACTION_GET_UNPROCESSED_ANIMALS = 'ACTION_GET_UNPROCESSED_ANIMALS',
  ACTION_SET_SELECTED_UNPROCESSED_ANIMALS = 'ACTION_SET_UNPROCESSED_ANIMALS'
}
