import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from 'components/widgets/Button'
import { Props } from './types'
import { ApplicationState } from 'store/types'
import { saveFeedlot } from 'store/feedlot/actions'
import CustomerSelector from 'components/selectors/CustomerSelector'
import Switch from 'components/widgets/Switch'

import { Form } from '@unform/web'
import { SubmitHandler } from '@unform/core'
import Input from 'components/widgets/Input'
import * as Yup from 'yup'
import _ from 'lodash'
import { statusOptions } from 'data'
import Loading from 'components/widgets/Loading'
import Select from 'components/widgets/Select'
import AddressSelector from 'components/selectors/AddressSelector'
import { useTranslation } from 'react-i18next'

const FeedlotForm: React.FC<Props> = ({ onChangeForm }) => {
  const { t } = useTranslation()
  const formRef: any = useRef(null)
  const dispatch = useDispatch()

  const { selectedFeedlot, feedlotLoading } = useSelector((state: ApplicationState) => state.feedlot)

  const buttonLabel = selectedFeedlot ? 'save' : 'add'

  const handleSubmit: SubmitHandler = async data => {
    data.id = selectedFeedlot?.id
    _.map(Object.entries(data), ([key, value]) => (data[key] = value === '' ? null : value))
    if (data.number) data.number = Number(data.number)
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        customer: Yup.object().shape({
          id: Yup.number().required(() => t('field.required'))
        }),
        description: Yup.string()
          .nullable()
          .required(() => t('field.required'))
      })
      await schema.validate(data, { abortEarly: false })
      const feedlot: any = data
      const customerOwner: number | undefined = data.customer?.id
      delete feedlot.customer
      dispatch(saveFeedlot({ feedlot, customerOwner }))
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }

  return (
    <div className='container'>
      {feedlotLoading ? (
        <Loading />
      ) : (
        <Form ref={formRef} initialData={selectedFeedlot} onSubmit={handleSubmit} onChange={() => onChangeForm(true)}>
          <div className='row'>
            <div className='col-4'>
              <CustomerSelector
                cleanError={() => formRef.current?.setFieldError('customer.id', '')}
                selected={selectedFeedlot?.customer?.id}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <Input
                name='description'
                label={t('field.description')}
                onFocus={() => formRef.current?.setFieldError('description', '')}
              />
            </div>
            <div className='col-3'>
              <Select
                name='status'
                label={t('field.status')}
                defaultOptions={statusOptions}
                selectedValue={selectedFeedlot?.status}
              />
            </div>
            <div className='col-3'>
              <Switch
                name='isThirdPart'
                label={t('field.is_third_part')}
                handleChange={checked => {
                  return
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <AddressSelector selected={selectedFeedlot?.cityId} />
            </div>
          </div>
          <div className='row'>
            <div className='col-5'>
              <Input name='street' label={t('address.street')} />
            </div>
            <div className='col-2'>
              <Input name='number' label={t('address.number')} />
            </div>
            <div className='col-4'>
              <Input name='neighborhood' label={t('address.neighborhood')} />
            </div>
          </div>
          <div className='row'>
            <div className='col-6 col-offset-3 mtop-5'>
              <Button label={buttonLabel} />
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}
export default FeedlotForm
