import gql from 'graphql-tag'
import { UserQueryParams, UserCreateArgs, UserUpdateArgs, UserPermissionsMutationArgs } from './types'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import graphql from 'services'
import { User } from 'store/user/types'
import getFormattedQuery from 'helpers/getFormattedQuery'

export const userQuery = async (token: string, params: UserQueryParams): Promise<User[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      email: true,
      name: true,
      category: true,
      dateOfBirth: true,
      document: true,
      phone: true,
      status: true,
      customer: {
        id: true,
        email: true,
        name: true
      },
      permissionSet: {
        feedlotId: true,
        permissions: { admin: true, allowAllLots: true, allowAllPens: true, allowEconomicInfo: true, allowEdit: true },
        lots: { id: true, description: true, pen: { id: true } },
        pens: { id: true, code: true }
      }
    }
  const QUERY = gql`
    query QUERY($email: String, $feedlotId: Int, $id: Int, $customerId: Int) {
      user(email: $email, feedlotId: $feedlotId, id: $id, customerId: $customerId) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/user/userQuery', errors)
    }
    return data.user
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/user/userQuery', error)
  }
}

export const userCreate = async (token: string, params: UserCreateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($user: CreateUser!) {
      createUser(user: $user)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/user/userCreate', errors)
    }
    return data.createUser
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/user/userCreate', error)
  }
}

export const userUpdate = async (token: string, params: UserUpdateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($user: UpdateUser!) {
      updateUser(user: $user)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/user/userUpdate', errors)
    }
    return data.updateUser
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/user/userUpdate', error)
  }
}

export const userPermissionsCreate = async (token: string, params: UserPermissionsMutationArgs) => {
  const MUTATION = gql`
    mutation MUTATION($userPermissions: CreateUserPermissions!) {
      createUserPermissions(userPermissions: $userPermissions)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/user/userPermissionsCreate', errors)
    }
    return data.createUserPermissions
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/user/userCreatePermissions', error)
  }
}

export const userPermissionsUpdate = async (token: string, params: UserPermissionsMutationArgs) => {
  const MUTATION = gql`
    mutation MUTATION($userPermissions: UpdateUserPermissions!) {
      updateUserPermissions(userPermissions: $userPermissions)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/user/userPermissionsUpdate', errors)
    }
    return data.updateUserPermissions
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/user/userPermissionsUpdate', error)
  }
}
