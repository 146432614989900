import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import moment from 'moment'
import momentTz from 'moment-timezone'
import locales from './locales'
import { ptBR, enUS, es } from 'date-fns/esm/locale'

const LANG_KEY = 'techagr@user-language'
const TZ_KEY = 'techagr@user-timezone'
const SYSTEM_UNITY_KEY = 'techagr@system-unity'

export const IMPERIAL = 'imperial'
export const METRIC = 'matric'

export const UNITY_SYSTEMS = [IMPERIAL, METRIC]

/**
 * Retorna idioma do usuário.
 * @return {String} idioma
 */
export const getLanguage = () => {
  let lang = localStorage.getItem(LANG_KEY)
  // if (lang) return lang
  lang = navigator.language
  setLanguage(lang)
  return lang
}

/**
 * Retorna fuso horário do usuário.
 * @return {String} fuso horário
 */
export const getTimeZone = () => {
  let tz = localStorage.getItem(TZ_KEY)
  if (tz) return tz
  tz = moment.tz.guess(true)
  setTimeZone(tz)
  return tz
}

/**
 * Retorna data formatada de acordo com sistema de unidadeusuário.
 * @param {date} [param] Data a ser formatada (opcional: retorna data atul quando não fornecido)
 * @return {String} data
 */
export const getFormattedDate = (date?: string) =>
  date
    ? moment(date).format(getUnitySystem() === IMPERIAL ? 'MM/DD/YYYY' : 'DD/MM/YYYY')
    : moment(moment().format()).format(getUnitySystem() === IMPERIAL ? 'MM/DD/YYYY' : 'DD/MM/YYYY')

export const getDateFormat = () => (getUnitySystem() === IMPERIAL ? 'MM/dd/yyyy HH:mm:ss' : 'dd/MM/yyyy HH:mm:ss')

export const getLocale = () => {
  const suffix = getLanguage().substr(0, 2)
  return suffix === 'pt' ? ptBR : suffix === 'es' ? es : enUS
}

/**
 * Retorna data formatada de acordo com sistema de unidadeusuário.
 * @param {date} [param] Data a ser formatada (opcional: retorna data atul quando não fornecido)
 * @return {String} data
 */
export const getFormattedDateObject = (date?: string) =>
  date ? momentTz.tz(date, getTimeZone()).format() : momentTz.tz(moment().format(), getTimeZone()).format()

/**
 * Retorna sistema de unidade de medida do usuário
 * @return {String} sistema de unidade de medida
 */
export const getUnitySystem = () => {
  let su = localStorage.getItem(SYSTEM_UNITY_KEY)
  if (su) return su
  su = getUnitySystemByLanguage()
  setUnitySystem(su)
  return su
}

/**
 * Retorna sistema de unidade de medida padrão do idioma do usuário
 * @return {String} sistema de unidade de medida
 */
const getUnitySystemByLanguage = () => {
  const lang = getLanguage()
  const suffix = lang.substr(0, 2)
  return suffix === 'en' ? IMPERIAL : METRIC
}

/**
 * setLanguage
 * Salva em local storage idioma do usuário.
 * @param {String} lang idioma
 */
export const setLanguage = (lang: string) => localStorage.setItem(LANG_KEY, lang)

/**
 * setTimeZone
 * Salva em local storage idioma do usuário.
 * @param {String} tz fuso horário (formato: America/Sao Paulo)
 */
export const setTimeZone = (tz: string) => localStorage.setItem(TZ_KEY, tz)

/**
 * setUnitySystem
 * Salva em local storage sistema de unidade de medida do usuário.
 * @param {String} su sistema de unidade de medida
 */
const setUnitySystem = (su: string) => localStorage.setItem(SYSTEM_UNITY_KEY, su)

i18n.use(initReactI18next).init(
  {
    resources: { ...locales },
    lng: getLanguage(),
    keySeparator: ':',
    nsSeparator: '.',
    interpolation: { escapeValue: true }
  },
  () => ''
)

export default i18n
