import React, { useRef, useEffect } from 'react'
import ReactInputMask from 'react-input-mask'
import { Props } from './types'
import './styles.scss'

import { useField } from '@unform/core'
import { useTranslation } from 'react-i18next'

const InputMask: React.FC<Props> = ({ name, label, ...rest }) => {
  const { t } = useTranslation()
  const inputRef = useRef(null)
  const { fieldName, registerField, defaultValue, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue('')
      },
      clearValue(ref: any) {
        ref.setInputValue('')
      }
    })
  }, [fieldName, registerField])

  return (
    <div className={!error ? 'div' : 'div-error'}>
      {error && <span>{t(error)}</span>}
      <ReactInputMask className='input-mask' ref={inputRef} defaultValue={defaultValue} {...rest} />
      {label && <label htmlFor={fieldName}>{t(label || '')}</label>}
    </div>
  )
}
export default InputMask
