import moment from 'moment'

export const formatFormFields = (fields: any) => {
  for (const key in fields) {
    if (fields[key] !== null) {
      if (key === 'initialWeight') fields[key] = parseFloat(fields[key])
      if (key === 'age') fields[key] = Number(fields[key])
      if (key === 'eccHandling') fields[key] = Number(fields[key])
      if (key === 'active') fields[key] = fields[key] === 1 ? true : false
      if (key === 'endDate') fields[key] = fields[key] ? new Date(moment(fields[key]).format()) : null
    }
  }

  return fields
}
