export const colors = {
  primary: '#FF4400',
  primary80: '#FF8400',
  primaryDark: '#aa3a0d',
  main: '#FFF',
  secondary: '#D3D3D3',
  black: '#4D4D4D'
}
export const deviceWidth = { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 }
export const font = { fontFamily: 'Bahnschrift' }
