import gql from 'graphql-tag'
import {
  AnimalQueryParams,
  AnimalCreateArgs,
  AnimalUpdateArgs,
  BreedQueryParams,
  CoatQueryParams,
  GenderQueryParams,
  AnimalLotUpdateMutationArgs
} from './types'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import graphql from 'services'
import { Animal, Breed, Coat, Gender } from 'store/animal/types'
import getFormattedQuery from 'helpers/getFormattedQuery'

export const animalQuery = async (token: string, params: AnimalQueryParams): Promise<Animal[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      lotId: true,
      earring: true,
      age: true,
      tag: true,
      horn: true,
      initialWeight: true,
      finalWeight: true,
      active: true,
      endDate: true,
      note: true,
      sisbov: true,
      lastWeighingDate: true,
      ecc: true,
      delayedWeighingDays: true,
      gender: {
        id: true,
        code: true,
        description: true
      },
      coat: {
        id: true,
        code: true,
        description: true
      },
      breed: {
        id: true,
        code: true,
        description: true
      },
      additionalField: true,
      weighing: {
        id: true,
        day: true,
        weight: true,
        prediction: true
      },
      handling: {
        handlingId: true,
        animalId: true,
        ecc: true,
        entrySequence: true,
        observation: true
      }
    }
  const QUERY = gql`
    query QUERY($id: Int, $penId: Int, $lotId: Int, $active: Boolean,  $feedlotId: Int, $withDelayedWeighing: Boolean, $earring: [String!], $tag: [String!], $sisbov: [String!]) {
      animal(id: $id, penId: $penId, lotId: $lotId, active: $active, feedlotId: $feedlotId, withDelayedWeighing: $withDelayedWeighing, earring: $earring, tag: $tag, sisbov: $sisbov) ${getFormattedQuery(
        params.fields
      )}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/animal/animalQuery', errors)
    }
    return data.animal
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/animal/animalQuery', error)
  }
}

export const animalCreate = async (token: string, params: AnimalCreateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($animal: [CreateAnimalDto!]!) {
      createAnimal(animal: $animal)
    }
  `

  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/graphql/animal/animalCreate', errors)
    }
    return data.createAnimal
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/graphql/animal/animalCreate', error)
  }
}

export const animalUpdate = async (token: string, params: AnimalUpdateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($animal: [UpdateAnimalDto!]!, $lotId: Float) {
      updateAnimal(animal: $animal, lotId: $lotId)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/graphql/animal/animalUpdate', errors)
    }
    return data.updateAnimal
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/graphql/animal/animalUpdate', error)
  }
}

export const animalLotUpdate = async (token: string, params: AnimalLotUpdateMutationArgs) => {
  const MUTATION = gql`
    mutation MUTATION($lotId: Float, $newLotId: Float, $animalId: [Float!]!) {
      updateAnimalLot(lotId: $lotId, newLotId: $newLotId, animalId: $animalId)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/graphql/animal/animalUpdateLot', errors)
    }
    return data.updateAnimal
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/graphql/animal/animalUpdateLot', error)
  }
}

export const breedQuery = async (token: string, params: BreedQueryParams): Promise<Breed[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      code: true,
      description: true
    }
  const QUERY = gql`
    query QUERY($id: Int) {
      breed(id: $id) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/breed/breedQuery', errors)
    }
    return data.breed
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/breed/breedQuery', error)
  }
}

export const coatQuery = async (token: string, params: CoatQueryParams): Promise<Coat[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      code: true,
      description: true
    }
  const QUERY = gql`
    query QUERY($id: Int) {
      coat(id: $id) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/coat/coatQuery', errors)
    }
    return data.coat
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/coat/coatQuery', error)
  }
}

export const genderQuery = async (token: string, params: GenderQueryParams): Promise<Gender[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      code: true,
      description: true
    }
  const QUERY = gql`
    query QUERY($id: Int) {
      gender(id: $id) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/gender/genderQuery', errors)
    }
    return data.gender
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/gender/genderQuery', error)
  }
}

export const disableAnimalMutation = async (token: string, params: Array<number>): Promise<number> => {
  const MUTATION = gql`
    mutation MUTATION($data: [DisableAnimalDto!]!) {
      disableAnimals(data: $data)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { data: params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/graphql/animal/disableAnimal', errors)
    }
    return data.disableAnimals
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/graphql/animal/disableAnimal', error)
  }
}
