import React, { useState, useEffect } from 'react'
import { Props } from './types'
import './styles.scss'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import Loading from '../Loading'

const ItemList: React.FC<Props> = ({ label, checked, children, onOpen, loading, onChange, id, disabled }) => {
  const [selected, setSelected] = useState<boolean | undefined>(undefined)
  const [opened, setOpened] = useState<boolean>(false)

  useEffect(() => setSelected(checked || false), [checked])

  const handleExpand = () => {
    if (!opened && onOpen) onOpen()
    setOpened(!opened)
  }

  const handleChange = () => {
    onChange && onChange(!selected)
    setSelected(!selected)
  }

  return (
    <div className={`item-list-${selected ? 'checked' : 'unchecked'}`}>
      <div className='item'>
        <div className='fixed'>
          <input id={id} type='checkbox' checked={selected} onChange={handleChange} disabled={disabled} />
          <label htmlFor={id}>{label}</label>
        </div>
        {onOpen && (
          <button type='button' className='icon' onClick={handleExpand}>
            {opened ? <ExpandLess /> : <ExpandMore />}
          </button>
        )}
      </div>
      {opened && (
        <div className='list'>
          {loading && <Loading />}
          {children}
        </div>
      )}
    </div>
  )
}
export default ItemList
