import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Redirect, withRouter } from 'react-router-dom'

import { Props } from './types'

import './styles.scss'
import Loading from 'components/widgets/Loading'

class LoadingScreen extends React.PureComponent<Props> {
  render() {
    const { from } = this.props.location.state || { from: { pathname: '/feedlots' } }

    if (this.props.isAuthenticated) {
      return <Redirect to={from} />
    }
    return (
      <div className='loading-root'>
        <Loading />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

const mapStoreToProps = (store: any) => ({
  isAuthenticated: store.auth.isAuthenticated,
  isSecondarySidebarOpened: store.main.isSecondarySidebarOpened
})

export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(LoadingScreen))
