import React from 'react'
import { Grid } from '@material-ui/core'
import i18next from 'i18next'

export const generalRender = (rowData: any, type: string) => {
  switch (type) {
    default:
      return <Grid>{rowData[type] || i18next.t('table.not_registered')}</Grid>
  }
}
