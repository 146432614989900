import React from 'react'
import i18next from 'i18next'
import { UserStatus } from 'store/user/types'
import { FormGroup, FormControlLabel, Switch, Grid, Checkbox, TextField, Icon, Box } from '@material-ui/core'

// export const onRowSave = async (
//   user: User,
//   saveUser: (args: UserMutationArgs) => void,
//   feedlotId: number,
//   setErrors: (errors: any) => void,
//   oldUser?: User
// ) => {
//   if (user.permissionSet) {
//     const permissionSet: PermissionSet = user.permissionSet[0]
//     const userToSave: UserCreate | UserUpdate = { ...user, permissionSet }
//     if (user.email === oldUser?.email) {
//       delete userToSave.email
//       console.log(userToSave)
//     }
//     const isValid = validate(userToSave, setErrors)
//     if (isValid) saveUser({ feedlotId, user: userToSave })
//     else throw isValid
//   }
// }

// const validate = (obj: any, setErrors: (errors: any) => void) => {
//   const errors: any = { email: '' }
//   if (!obj.id) {
//     if (obj.email === '' || !obj.email) errors.email = i18next.t('field.required')
//     else if (!obj.email.includes('@') || !obj.email.includes('.co')) errors.email = i18next.t('error.invalid_email')
//     // LEMBRAR: fazer verificação do permissionSet
//   } else {
//     if (obj.email) {
//       if (obj.email === '') errors.email = i18next.t('field.required')
//       else if (!obj.email.includes('@') || !obj.email.includes('.co')) errors.email = i18next.t('error.invalid_email')
//     }
//   }
//   setErrors(errors)
//   if (errors.email === '') return true
//   return false
// }

const cleanError = (e: any, _errors: any, setErrors: (errors: any) => void) => {
  const errors: any = _errors
  if (errors[e.target.id] !== '') {
    errors[e.target.id] = ''
    setErrors(errors)
  }
}

export const editComponent = (props: any, errors: any, setErrors: (errors: any) => void) => {
  const field: any = props.columnDef.field
  switch (field) {
    case 'status':
      const active = props.value !== undefined ? (props.value === UserStatus.active ? true : false) : true
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                disabled={false}
                color={'primary'}
                checked={active}
                onChange={(e: any) => props.onChange(e.target.checked ? UserStatus.active : UserStatus.inactive)}
              />
            }
            label={() => {
              return <Box style={{ fontSize: '14px' }}>{active ? i18next.t('active') : i18next.t('inactive')}</Box>
            }}
          />
        </FormGroup>
      )
    case 'admin':
      const admin = getAdminPermission(props.rowData)
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                id='admin'
                color='primary'
                onChange={e => {
                  const data = { ...props.rowData }
                  const permissionSet = { ...data?.permissionSet[0] }
                  const permissions = { admin: e.target.checked }
                  permissionSet.permissions = { ...permissionSet?.permissions, ...permissions }
                  data.permissionSet[0] = permissionSet
                  props.onRowDataChange(data)
                }}
                value={admin}
                checked={admin}
              />
            }
            label={<div style={{ fontSize: '14px' }}>{i18next.t('permission.admin')}</div>}
          />
        </FormGroup>
      )
    default:
      return (
        <TextField
          id={field}
          InputLabelProps={{ style: { fontSize: '14px' } }}
          InputProps={{ style: { fontSize: '14px' } }}
          inputProps={{ maxLength: 100 }}
          value={props.value || ''}
          onChange={e => props.onChange(e.target.value)}
          variant='standard'
          fullWidth
          error={errors[field] ? (errors[field] !== '' ? true : false) : false}
          placeholder={errors[field] ? i18next.t(errors[field]) : undefined}
          onFocus={e => cleanError(e, errors, setErrors)}
        />
      )
  }
}

export const generalRender = (rowData: any, type: string) => {
  if (!rowData?.permissionSet) return <Grid>{i18next.t('table.not_registered')}</Grid>
  switch (type) {
    case 'admin':
      return rowData?.permissionSet[0]?.permissions.admin ? (
        <Grid className={'field admin'}>{'Admin'}</Grid>
      ) : (
        <Grid></Grid>
      )
    case 'edit':
      return rowData?.permissionSet[0]?.permissions.admin || rowData?.permissionSet[0]?.permissions.allowEdit ? (
        <Grid>
          <Icon>check</Icon>
        </Grid>
      ) : (
        <Grid>
          <Icon>close</Icon>
        </Grid>
      )
    case 'view_economic':
      return rowData?.permissionSet[0]?.permissions.admin ||
        rowData?.permissionSet[0]?.permissions.allowEconomicInfo ? (
        <Grid>
          <Icon>check</Icon>
        </Grid>
      ) : (
        <Grid>
          <Icon>close</Icon>
        </Grid>
      )
    case 'view_all_lots':
      return rowData?.permissionSet[0]?.permissions.admin || rowData?.permissionSet[0]?.permissions.allowAllLots ? (
        <Grid>
          <Icon>check</Icon>
        </Grid>
      ) : (
        <Grid>
          <Icon>close</Icon>
        </Grid>
      )
    case 'view_alls':
      return rowData?.permissionSet[0]?.permissions.admin || rowData?.permissionSet[0]?.permissions.allowAllPens ? (
        <Grid>
          <Icon>check</Icon>
        </Grid>
      ) : (
        <Grid>
          <Icon>close</Icon>
        </Grid>
      )
    default:
      return <Grid>{rowData[type] || i18next.t('table.not_registered')}</Grid>
  }
}

const getAdminPermission = (data: any) => {
  if (data.permissionSet && data.permissionSet[0] && data.permissionSet[0].permissions) {
    const { permissions } = data.permissionSet[0]
    return permissions.admin || false
  }
}

export const sortFunction = (data1: any, data2: any) => {
  const admin1 = getAdminPermission(data1)
  const admin2 = getAdminPermission(data2)
  return admin1 === admin2 ? 0 : admin1 ? -1 : 1
}

export const getTitle = (column: string) => {
  switch (column) {
    case 'admin':
      return ''
    default:
      return i18next.t('permission.' + column)
  }
}

export const getCellStyle = (column: string) => {
  switch (column) {
    case 'admin':
      return { width: 5 }
    default:
      return undefined
  }
}
