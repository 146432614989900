import { Props } from './types'
import Loading from 'components/widgets/Loading'
import React, { useRef, useState, useEffect } from 'react'
import { Form } from '@unform/web'
import Input from 'components/widgets/Input'
import DatePicker from 'components/widgets/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'
import Button from 'components/widgets/Button'
import * as Yup from 'yup'
import _ from 'lodash'
import { getLot, setSelectedLot } from 'store/feedlot/actions'
import { saveAnimal } from 'store/animal/actions'
import { statusOptions } from 'data'
import { formatFormFields } from './helper'
import Select from 'components/widgets/Select'
import BreedSelector from 'components/selectors/Breed'
import CoatSelector from 'components/selectors/Coat'
import GenderSelector from 'components/selectors/Gender'
import { useTranslation } from 'react-i18next'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import { Animal } from 'store/animal/types'

const AnimalForm: React.FC<Props> = ({ feedlotId, lotId, additionalInfoKeys, handleClose }) => {
  const { t } = useTranslation()
  const formRef: any = useRef(null)
  const dispatch = useDispatch()
  const { animals, selectedAnimal, animalLoading } = useSelector((state: ApplicationState) => state.animal)
  const { selectedPen } = useSelector((state: ApplicationState) => state.feedlot)
  const [isConfirmationModalDuplicatedTagOpen, setConfirmationModalDuplicatedTagStatus] = useState<boolean>(false)
  const [confirmationModalDuplicatedTagMessage, setConfirmationModalDuplicatedTagMessage] = useState('')
  const [selectedData, setSelectedData] = useState<Animal | undefined>()
  const [formData, setFormData] = useState<any>()

  useEffect(() => {
    dispatch(getLot({ args: { feedlotId } }))
  }, [lotId, feedlotId, dispatch])

  const buttonLabel = selectedAnimal?.id ? 'save' : 'add'

  const clearError = (e: any) => formRef.current?.setFieldError(e.target?.id, '')

  const verifiesDuplicatedTag = async (data: any) => {
    if (animals.find(a => a.tag === data.tag && a.id !== data.id) !== undefined) {
      setConfirmationModalDuplicatedTagMessage(t('confirmation.registered_animal_with_same_tag'))
      setConfirmationModalDuplicatedTagStatus(true)
    }
    saveAnimalData(data)
  }

  const saveData = async (data: any) => {
    if (lotId) dispatch(saveAnimal({ lotId, animal: [data] }))
    handleClose()
  }

  const saveDataAux = async () => {
    if (lotId) dispatch(saveAnimal({ lotId, animal: [formData] }))
    handleClose()
  }

  const saveAnimalData: any = async (data: any) => {
    let animal: any = data
    animal.id = selectedAnimal?.id
    animal.breedId = animal.breed?.id
    animal.coatId = animal.coat?.id
    animal.genderId = animal.gender?.id
    animal.eccHandling = animal.handling?.ecc

    if (!selectedAnimal) {
      animal.lotId = lotId
      animal.penId = selectedPen?.id
    }
    if (!selectedAnimal?.id) animal.feedlotId = feedlotId

    delete animal.handling

    const additionalField: Record<string, any> = {}
    _.map(additionalInfoKeys, key => {
      const label = key
      additionalField[key] = animal.additionalField[label.replace(/\s/g, '').replace('.', '')]
    })

    try {
      if (!lotId) return
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        earring: Yup.string().required(() => t('field.required')),
        breed: Yup.object().shape({
          id: Yup.number().required(() => t('field.required'))
        }),
        coat: Yup.object().shape({
          id: Yup.number().required(() => t('field.required'))
        }),
        gender: Yup.object().shape({
          id: Yup.number().required(() => t('field.required'))
        })
      })

      await schema.validate(animal, { abortEarly: false })
      animal = formatFormFields(animal)
      animal.additionalField = additionalField

      setFormData(animal)
      saveData(animal)
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }

  useEffect(() => {
    if (selectedAnimal) {
      for (const key in selectedAnimal?.additionalField) {
        selectedAnimal.additionalField[key.replace(/\s/g, '').replace('.', '')] = selectedAnimal?.additionalField[key]
        setSelectedData(selectedAnimal)
      }
    }
  }, [selectedAnimal])

  return (
    <div>
      <ConfirmationModal
        text={confirmationModalDuplicatedTagMessage}
        handleConfirm={() => saveDataAux()}
        handleCancel={() => setConfirmationModalDuplicatedTagStatus(false)}
        open={isConfirmationModalDuplicatedTagOpen}
        title='Attention'
      ></ConfirmationModal>
      <div className='container'>
        {animalLoading ? (
          <Loading />
        ) : (
          <Form ref={formRef} initialData={selectedData} onSubmit={verifiesDuplicatedTag}>
            <div className='row'>
              <div className='col-3'>
                <Input name='earring' label={t('field.earring')} onFocus={clearError} />
              </div>
              <div className='col-2'>
                <Select
                  name='active'
                  label={t('default.active')}
                  defaultOptions={statusOptions}
                  selectedValue={!selectedData || selectedData?.active === true ? 1 : 0}
                  onFocus={clearError}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Input name='tag' label={t('field.tag')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <BreedSelector
                  selected={selectedData?.breed?.id}
                  cleanError={() => formRef.current?.setFieldError('breed.id', '')}
                />
              </div>
              <div className='col-3'>
                <CoatSelector
                  selected={selectedData?.coat?.id}
                  cleanError={() => formRef.current?.setFieldError('coat.id', '')}
                />
              </div>
              <div className='col-3'>
                <GenderSelector
                  selected={selectedData?.gender?.id}
                  cleanError={() => formRef.current?.setFieldError('gender.id', '')}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Input name='initialWeight' label={t('field.initialWeight')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Input name='age' label={t('field.age')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Input name='horn' label={t('field.horn')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Input name='sisbov' label={t('field.sisbov')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Input name='handling.ecc' label={t('field.initialEcc')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <DatePicker name='endDate' label={t('field.endDate')} onFocus={clearError} />
              </div>
            </div>
            {additionalInfoKeys?.length ? (
              additionalInfoKeys?.map((key: string) => {
                return (
                  <div className='row'>
                    <div className='col-3'>
                      <Input
                        key={key}
                        name={`additionalField[${key.replace(/\s/g, '').replace('.', '')}]`}
                        onFocus={clearError}
                        label={key}
                        translation={false}
                      />
                    </div>
                  </div>
                )
              })
            ) : (
              <></>
            )}
            <div className='row'>
              <div className='col-4 col-offset-4 mtop-5'>
                <Button label={buttonLabel} />
              </div>
            </div>
          </Form>
        )}
      </div>
    </div>
  )
}

export default AnimalForm
