import gql from 'graphql-tag'
import { EquipmentQueryParams, EquipmentUpdateArgs, EquipmentCreateArgs } from './types'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import graphql from 'services'
import { Equipment } from 'store/feedlot/types'
import getFormattedQuery from 'helpers/getFormattedQuery'

export const equipmentQuery = async (token: string, params: EquipmentQueryParams): Promise<Equipment[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      serialNumber: true,
      description: true,
      active: true,
      penId: true,
      equipmentModel: {
        id: true,
        description: true,
        model: true,
        equipmentTypeId: true,
        manufacturer: {
          id: true,
          name: true
        }
      }
    }
  const QUERY = gql`
    query QUERY($id: Int, $penId: Int, $feedlotId: Int) {
      equipment(id: $id, penId: $penId, feedlotId: $feedlotId) ${getFormattedQuery(params.fields)}
    }
    `
  try {
    const response = await graphql.query(QUERY, { ...params.args }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/equipment/equipmentQuery', errors)
    }
    return data.equipment
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/equipment/equipmentQuery', error)
  }
}

export const equipmentCreate = async (token: string, params: EquipmentCreateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($equipment: CreateEquipment!) {
      createEquipment(equipment: $equipment)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/graphql/equipment/equipmentCreate', errors)
    }
    return data.createEquipment
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/graphql/equipment/equipmentCreate', error)
  }
}

export const equipmentUpdate = async (token: string, params: EquipmentUpdateArgs) => {
  const MUTATION = gql`
    mutation MUTATION($equipment: UpdateEquipment!) {
      updateEquipment(equipment: $equipment)
    }
  `
  try {
    const response = await graphql.mutate(MUTATION, { ...params }, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(statusCode || UNEXPECTED_ERROR_CODE, 'services/graphql/equipment/equipmentUpdate', errors)
    }
    return data.createEquipment
  } catch (error) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/graphql/equipment/equipmentUpdate', error)
  }
}
