import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Props } from './types'
import { ApplicationState } from 'store/types'
import { Breed } from 'store/animal/types'

import { Option } from 'data/types'
import { getBreed, setSelectedBreed } from 'store/animal/actions'
import { BreedQueryFields } from 'services/animal/types'
import Select from 'components/widgets/Select'
import { useTranslation } from 'react-i18next'

const BreedSelector: React.FC<Props> = ({ selected, cleanError }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { breeds, breedLoading } = useSelector((state: ApplicationState) => state.animal)

  const [options, setOptions] = useState<Option[]>([])

  const formatBreeds = (breeds: Breed[]) =>
    breeds.map((e: Breed) => ({ label: e.description + ' - ' + e.code || '', value: e.id || -1 }))

  const setSelected = (id: number) => {
    dispatch(setSelectedBreed(id))
  }

  useEffect(() => {
    if (!breeds?.length) {
      const fields: BreedQueryFields = {
        id: true,
        code: true,
        description: true
      }
      dispatch(getBreed({ fields }))
    } else setOptions(formatBreeds(breeds))
  }, [breeds, dispatch])

  return (
    <Select
      name='breed.id'
      label={t('field.breed')}
      loading={breedLoading}
      defaultOptions={options}
      selectedValue={selected}
      handleSelect={(id: number) => setSelected(id)}
      onFocus={cleanError}
    />
  )
}
export default BreedSelector
